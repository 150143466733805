import { ExternalTokenizer, InputStream } from '@lezer/lr';
import {
  CREATE,
  OR,
  REPLACE,
  QUERY,
  DISTRIBUTED,
  QueryText,
  USE,
  GRAPH,
  ACCUM,
  ADD,
  ALL,
  ALLOCATE,
  ALTER,
  ANY,
  ASC,
  BAG,
  BETWEEN,
  BOOL,
  BY,
  CALL,
  COALESCE,
  COMPRESS,
  COUNT,
  KAFKA,
  S3,
  DATETIME_ADD,
  DATETIME_SUB,
  DATETIME_DIFF,
  DATETIME_TO_EPOCH,
  DATETIME_FORMAT,
  DELETE,
  DESC,
  DROP,
  DATETIME,
  DOUBLE,
  EPOCH_TO_DATETIME,
  EDGE,
  ESCAPE,
  FALSE,
  FILE,
  FIXED_BINARY,
  FLOAT,
  FROM,
  GLOBAL,
  GROUP,
  HAVING,
  HEADER,
  INDEX,
  INPUT_LINE_FILTER,
  INSERT,
  INT,
  INTERPRET,
  INTO,
  ISEMPTY,
  JOB,
  LIKE,
  LIMIT,
  NOT,
  NOW,
  NULL,
  OPENCYPHER,
  ORDER,
  PRIMARY_ID,
  REDUCE,
  SAMPLE,
  SELECT,
  SELECTVERTEX,
  SET,
  STRING,
  SUM,
  TEMP_TABLE,
  TO,
  TO_CSV,
  TRIM,
  TRUE,
  TUPLE,
  UINT,
  UPDATE,
  VALUES,
  VERTEX,
  WHERE,
  AVG,
  ABORT,
  ACL,
  API,
  ATTRIBUTE,
  CONCAT,
  DATA,
  DATA_SOURCE,
  DEFINE,
  DIRECTED,
  EMPTY,
  EXPORT,
  FILENAME,
  GET,
  GRANT,
  IMPORT,
  INSTALL,
  JSON,
  LOADING,
  LOCAL,
  LS,
  MAX,
  MIN,
  MINUS,
  OVERWRITE,
  OWNER,
  PAIR,
  PASSWORD,
  PRIVILEGE,
  PUT,
  READ,
  REJECT_LINE_RULE,
  RESUME,
  REVOKE,
  ROLE,
  RUN,
  SCHEMA,
  SCHEMA_CHANGE,
  SECONDARY_ID,
  SECRET,
  SHOW,
  SPLIT,
  STATS,
  STATUS,
  STORE,
  SUBSTR,
  SYNTAX,
  TAG,
  TEMPLATE,
  TOKEN,
  TOKEN_LEN,
  TOKENBANK,
  UNDIRECTED,
  USER,
  JSONOBJECT,
  JSONARRAY,
  String as StringToken,
  Number,
  ParenL,
  ParenR,
  BraceL,
  BraceR,
  BracketL,
  BracketR,
  Dot,
  Identifier,
  BlockComment,
  LineComment,
  PRIMARY,
  KEY,
  DISCRIMINATOR,
  WITH,
  REVERSED_EDGE,
  FOR,
  LOAD,
  USING,
  TAGS,
  AND,
  NUMERIC,
  TYPEDEF,
} from './script.grammar.terms';
import {
  readWord,
  readLiteral,
  readNumber,
  isAlpha,
  isHexDigit,
  Ch,
  readTripleQuotedString,
  eol,
} from '@/pages/editor/GSQL/util';

const ignoreCaseKwMap: Record<string, number> = {
  ACCUM: ACCUM,
  ADD: ADD,
  ALL: ALL,
  ALLOCATE: ALLOCATE,
  ALTER: ALTER,
  ANY: ANY,
  ASC: ASC,
  BAG: BAG,
  BETWEEN: BETWEEN,
  BOOL: BOOL,
  BY: BY,
  CALL: CALL,
  COALESCE: COALESCE,
  COMPRESS: COMPRESS,
  COUNT: COUNT,
  CREATE: CREATE,
  KAFKA: KAFKA,
  S3: S3,
  DATETIME_ADD: DATETIME_ADD,
  DATETIME_SUB: DATETIME_SUB,
  DATETIME_DIFF: DATETIME_DIFF,
  DATETIME_TO_EPOCH: DATETIME_TO_EPOCH,
  DATETIME_FORMAT: DATETIME_FORMAT,
  DELETE: DELETE,
  DESC: DESC,
  DISTRIBUTED: DISTRIBUTED,
  DROP: DROP,
  DATETIME: DATETIME,
  DOUBLE: DOUBLE,
  EPOCH_TO_DATETIME: EPOCH_TO_DATETIME,
  EDGE: EDGE,
  ESCAPE: ESCAPE,
  FALSE: FALSE,
  FILE: FILE,
  FIXED_BINARY: FIXED_BINARY,
  FLOAT: FLOAT,
  FROM: FROM,
  GLOBAL: GLOBAL,
  GRAPH: GRAPH,
  GROUP: GROUP,
  HAVING: HAVING,
  HEADER: HEADER,
  INDEX: INDEX,
  INPUT_LINE_FILTER: INPUT_LINE_FILTER,
  INSERT: INSERT,
  INT: INT,
  INTERPRET: INTERPRET,
  INTO: INTO,
  ISEMPTY: ISEMPTY,
  JOB: JOB,
  LIKE: LIKE,
  LIMIT: LIMIT,
  NOT: NOT,
  NOW: NOW,
  NULL: NULL,
  OPENCYPHER: OPENCYPHER,
  OR: OR,
  ORDER: ORDER,
  PRIMARY_ID: PRIMARY_ID,
  PRIMARY: PRIMARY,
  DISCRIMINATOR: DISCRIMINATOR,
  KEY: KEY,
  QUERY: QUERY,
  REDUCE: REDUCE,
  REPLACE: REPLACE,
  SAMPLE: SAMPLE,
  SELECT: SELECT,
  SELECTVERTEX: SELECTVERTEX,
  SET: SET,
  STRING: STRING,
  SUM: SUM,
  TEMP_TABLE: TEMP_TABLE,
  TO: TO,
  TO_CSV: TO_CSV,
  TRIM: TRIM,
  TRUE: TRUE,
  AND: AND,
  TUPLE: TUPLE,
  UINT: UINT,
  UPDATE: UPDATE,
  VALUES: VALUES,
  VERTEX: VERTEX,
  WHERE: WHERE,
  AVG: AVG,
  ABORT: ABORT,
  ACL: ACL,
  API: API,
  ATTRIBUTE: ATTRIBUTE,
  CONCAT: CONCAT,
  DATA: DATA,
  DATA_SOURCE: DATA_SOURCE,
  DEFINE: DEFINE,
  DIRECTED: DIRECTED,
  EMPTY: EMPTY,
  EXPORT: EXPORT,
  FILENAME: FILENAME,
  GET: GET,
  GRANT: GRANT,
  IMPORT: IMPORT,
  INSTALL: INSTALL,
  JSON: JSON,
  LOADING: LOADING,
  LOCAL: LOCAL,
  LS: LS,
  MAX: MAX,
  MIN: MIN,
  MINUS: MINUS,
  OVERWRITE: OVERWRITE,
  OWNER: OWNER,
  PAIR: PAIR,
  PASSWORD: PASSWORD,
  PRIVILEGE: PRIVILEGE,
  PUT: PUT,
  READ: READ,
  REJECT_LINE_RULE: REJECT_LINE_RULE,
  RESUME: RESUME,
  REVOKE: REVOKE,
  ROLE: ROLE,
  RUN: RUN,
  SCHEMA: SCHEMA,
  SCHEMA_CHANGE: SCHEMA_CHANGE,
  SECONDARY_ID: SECONDARY_ID,
  SECRET: SECRET,
  SHOW: SHOW,
  SPLIT: SPLIT,
  STATS: STATS,
  STATUS: STATUS,
  STORE: STORE,
  SUBSTR: SUBSTR,
  SYNTAX: SYNTAX,
  TAG: TAG,
  TEMPLATE: TEMPLATE,
  TOKEN: TOKEN,
  TOKEN_LEN: TOKEN_LEN,
  TOKENBANK: TOKENBANK,
  UNDIRECTED: UNDIRECTED,
  USE: USE,
  USER: USER,
  UNINT: UINT,
  JSONOBJECT: JSONOBJECT,
  JSONARRAY: JSONARRAY,
  WITH: WITH,
  REVERSED_EDGE: REVERSED_EDGE,
  FOR: FOR,
  LOAD: LOAD,
  USING: USING,
  TAGS: TAGS,
  NUMERIC: NUMERIC,
  TYPEDEF: TYPEDEF,
};

export function keywords(name: string) {
  let found = ignoreCaseKwMap[name.toUpperCase()];
  if (found) return found;
  return -1;
}

export const queryTokens = new ExternalTokenizer((input: InputStream) => {
  // read to the end of the block
  let cntOfLeftBrace = 0;
  for (let i = 0; ; i++) {
    let next = input.next;
    if (next < 0) {
      if (i > 0) input.acceptToken(QueryText);
      break;
    }
    if (next === Ch.BraceL) {
      cntOfLeftBrace++;
    } else if (next === Ch.BraceR) {
      cntOfLeftBrace--;
      if (cntOfLeftBrace <= 0) {
        input.advance();
        input.acceptToken(QueryText);
        return;
      }
    }
    input.advance();
  }
});

export const tokens = new ExternalTokenizer((input: InputStream) => {
  const next = input.next;
  input.advance();
  if (next == Ch.DoubleQuote) {
    if (input.next == Ch.DoubleQuote && input.peek(1) == Ch.DoubleQuote) {
      readTripleQuotedString(input, Ch.DoubleQuote);
    } else {
      readLiteral(input, next, true);
    }
    input.acceptToken(StringToken);
  } else if (next == Ch.SingleQuote) {
    if (input.next == Ch.SingleQuote && input.peek(1) == Ch.SingleQuote) {
      readTripleQuotedString(input, Ch.SingleQuote);
    } else {
      readLiteral(input, next, true);
    }
    input.acceptToken(StringToken);
  } else if (next == Ch.ParenL) {
    input.acceptToken(ParenL);
  } else if (next == Ch.ParenR) {
    input.acceptToken(ParenR);
  } else if (next == Ch.BraceL) {
    input.acceptToken(BraceL);
  } else if (next == Ch.BraceR) {
    input.acceptToken(BraceR);
  } else if (next == Ch.BracketL) {
    input.acceptToken(BracketL);
  } else if (next == Ch.BracketR) {
    input.acceptToken(BracketR);
  } else if (
    (next == Ch._0 && (input.next == Ch.x || input.next == Ch.X)) ||
    ((next == Ch.x || next == Ch.X) && input.next == Ch.SingleQuote)
  ) {
    let quoted = input.next == Ch.SingleQuote;
    input.advance();
    while (isHexDigit(input.next)) input.advance();
    if (quoted && input.next == Ch.SingleQuote) input.advance();
    input.acceptToken(Number);
  } else if (next == Ch.Hash || (next == Ch.Slash && input.next == Ch.Slash)) {
    eol(input);
    input.acceptToken(LineComment);
  } else if (next == Ch.Slash && input.next == Ch.Star) {
    input.advance();
    for (let depth = 1; ; ) {
      let cur: number = input.next;
      if (input.next < 0) break;
      input.advance();
      if (cur == Ch.Star && (input as any).next == Ch.Slash) {
        depth--;
        input.advance();
        if (!depth) break;
      } else if (cur == Ch.Slash && input.next == Ch.Star) {
        depth++;
        input.advance();
      }
    }
    input.acceptToken(BlockComment);
  } else if (next == Ch.Dot && input.next >= Ch._0 && input.next <= Ch._9) {
    readNumber(input, true);
    input.acceptToken(Number);
  } else if (next == Ch.Dot) {
    input.acceptToken(Dot);
  } else if (next >= Ch._0 && next <= Ch._9) {
    readNumber(input, false);
    input.acceptToken(Number);
  } else if (isAlpha(next)) {
    const word = readWord(input, String.fromCharCode(next));
    // handle the case of "POST-ACCUM"
    if (word == 'POST' && input.next == Ch.Dash) {
      input.advance();
      readWord(input);
    }
    input.acceptToken(Identifier);
  }
});
