import { Select } from '@tigergraph/app-ui-lib/select';
import { Option } from 'baseui/select';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import GraphIcon from './graph.svg?react';
import { CheckIcon } from '@/pages/home/icons';
import { GLOBAL_GRAPH_NAME } from '@tigergraph/tools-models';

type Props = {
  graph: string;
  graphs: string[];
  isFetching: boolean;
  onGraphChanged: (graphName: string) => void;
};

export function GraphSelector({ graph, graphs, isFetching, onGraphChanged }: Props) {
  const [css] = useStyletron();

  const getLabel = ({ option, isValue }: { option?: Option; isValue?: boolean }) => {
    return (
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
        })}
      >
        {isValue && (
          <div
            className={css({
              minWidth: '16px',
            })}
          >
            <GraphIcon />
          </div>
        )}
        {option!.name}
        {!isValue && graph === option?.id && (
          <div>
            <CheckIcon />
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <Select
        isLoading={isFetching}
        getOptionLabel={getLabel}
        placeholder="Select a graph"
        getValueLabel={({ option }) => getLabel({ option, isValue: true })}
        options={graphs.map((graph) => ({
          name: graph === GLOBAL_GRAPH_NAME ? 'global' : graph,
          id: graph,
        }))}
        value={graph ? [{ id: graph }] : []}
        labelKey="name"
        onChange={(params) => {
          const id = params.value[0].id as string;
          onGraphChanged(id);
        }}
        clearable={false}
        searchable={false}
        overrides={{
          IconsContainer: {
            props: {
              // E2E test locator
              'aria-label': 'graph-select',
            },
          },
        }}
      />
    </>
  );
}
