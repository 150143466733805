import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import TutorialIcon from '@/assets/tutorial.svg?react';
import { TutorialGroup } from '@/components/tutorial/TutorialGroup.tsx';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { SubChapter, Tutorial } from '@/components/tutorial/type.ts';
import { ChevronRight } from 'baseui/icon';
import { TutorialSubChapters } from '@/components/tutorial/TutorialSubChapters.tsx';
import { SubChapterDetail } from '@/components/tutorial/SubChapterDetail.tsx';
import { Header } from '@/components/tutorial/style.tsx';
import { Close } from '@/components/tutorial/icons.tsx';
import { Tutorials } from '@/components/tutorial/mock.tsx';
import { Button } from '@tigergraph/app-ui-lib/button';

export interface TutorialPopoverProps {
  closeNavBar: boolean;
  isOpen: boolean;
  onClose: () => void;
}

export const TutorialPopover = forwardRef((props: TutorialPopoverProps, ref) => {
  const { isOpen, onClose, closeNavBar } = props;
  const [css, theme] = useStyletron();
  const [currentTutorial, setCurrentTutorial] = useState<Tutorial | null>(null);
  const [currentSubChapter, setCurrentSubChapter] = useState<SubChapter | null>(null);
  const [step, setStep] = useState<number>(0);

  useEffect(() => {
    if (isOpen && !currentTutorial) {
      const routerKey = window.location.pathname.split('/')[1];
      const tutorial = Tutorials.find((item) => item.routerKey === routerKey);
      if (tutorial) {
        setCurrentTutorial(tutorial);
        setStep(1);
      }
    }
  }, [isOpen, currentTutorial]);

  useImperativeHandle(
    ref,
    () => {
      return {
        clearState() {
          setStep(0);
          setCurrentTutorial(null);
          setCurrentSubChapter(null);
        },
        stepToSubChapter(tutorial?: Tutorial) {
          setStep(1);
          tutorial && setCurrentTutorial(tutorial);
        },
        stepToChapter(routerKey?: string, tutorialID?: string) {
          this.clearState();
          if (routerKey) {
            const tutorial = Tutorials.find((tutorial) => tutorial.routerKey === routerKey);
            if (tutorial) {
              setStep(1);
              setCurrentTutorial(tutorial);
              if (tutorialID) {
                const subTutorial = tutorial.sub_chapter.find((tutorial) => tutorial.TutorialID === tutorialID);
                if (subTutorial) {
                  setCurrentSubChapter(subTutorial);
                  setStep(2);
                }
              }
            }
          }
        },
      };
    },
    []
  );

  return (
    <>
      {isOpen && (
        <div
          className={css({
            zIndex: 1000,
            position: 'absolute',
            bottom: '32px',
            right: '16px',
            width: currentTutorial?.id === '0' && step !== 0 ? '931px' : '480px',
            height: currentTutorial?.id === '0' && step !== 0 ? '675px' : '600px',
            boxShadow: theme.colors['shadow.popup'],
            backgroundColor: theme.colors['modal.background'],
            borderRadius: '4px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: '12px',
            border: `1px solid ${theme.colors.divider}`,
          })}
        >
          <Header
            className={css({
              lineHeight: '32px',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              padding: '16px',
              borderBottom: `1px solid ${theme.colors.divider}`,
            })}
          >
            <TutorialIcon color={theme.colors['modal.icon']} />
            <span
              className={css({
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                flexGrow: 1,
              })}
            >
              <span
                onClick={() => {
                  setStep(0);
                }}
                className={css({
                  cursor: 'pointer',
                  color: currentTutorial && step !== 0 ? theme.colors['text.tertiary'] : theme.colors['text.primary'],
                })}
              >
                Tutorials
              </span>
              {currentTutorial && step !== 0 && <ChevronRight size={16} />}
              {currentTutorial && step !== 0 && <span>{currentTutorial.title}</span>}
            </span>
            <Button
              kind="text"
              shape="square"
              onClick={() => {
                setStep(0);
                setCurrentTutorial(null);
                setCurrentSubChapter(null);
                onClose();
              }}
            >
              <span
                className={css({
                  color: theme.colors['modal.icon'],
                })}
              >
                <Close />
              </span>
            </Button>
          </Header>
          <div
            className={css({
              padding: ' 0px 16px',
              flexGrow: 1,
            })}
          >
            {step === 0 && (
              <TutorialGroup
                onSelectTutorial={(tutorial) => {
                  setCurrentTutorial(tutorial);
                  setStep(1);
                }}
              />
            )}
            {step === 1 && currentTutorial && (
              <TutorialSubChapters
                onClose={onClose}
                tutorial={currentTutorial}
                onSelectSubChapter={(subChapter) => {
                  setCurrentSubChapter(subChapter);
                  setStep(2);
                }}
              />
            )}
            {step === 2 && currentSubChapter && <SubChapterDetail subChapter={currentSubChapter} />}
          </div>
          {step !== 0 && (
            <div
              className={css({
                borderTop: `1px solid ${theme.colors.divider}`,
                padding: '16px',
              })}
            >
              <Button
                kind="secondary"
                size="large"
                onClick={() => {
                  setStep((prev) => prev - 1);
                }}
              >
                Back
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
});
