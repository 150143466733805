import ParagraphLabel from '@/components/PagagraphLabel';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import EmptyIcon from './icon/empty.svg?react';
import EmptyDarkIcon from './icon/empty-dark.svg?react';
import { useTheme } from '@/contexts/themeContext';

export default function Empty() {
  const [css, theme] = useStyletron();
  const { themeType } = useTheme();
  return (
    <div
      className={css({
        backgroundColor: theme.colors['background.secondary'],
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '8px',
        padding: '32px 0',
      })}
    >
      <div
        className={css({
          width: '72px',
          height: '72px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        })}
      >
        {themeType === 'dark' ? <EmptyDarkIcon /> : <EmptyIcon />}
      </div>
      <ParagraphLabel
        $style={{
          color: theme.colors['text.tertiary'],
        }}
      >
        Click the + button to schedule resize
      </ParagraphLabel>
    </div>
  );
}
