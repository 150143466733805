import { Result } from '@/lib/type';
import { listSolution } from '@/pages/marketplace/solution/api';
import { SolutionInstance } from '@/pages/marketplace/solution/type';
import { StyledForm, StyledFormAction, StyledFormBody } from '@/pages/workgroup/form/setting/styled';
import { General, GeneralType } from '@/pages/workgroup/form/WorkSpaceSubForm';
import { useMutationUpdateWorkspace } from '@/pages/workgroup/hook';
import { UpdateWorkspaceRequest, WorkspaceT, WorkSpaceType } from '@/pages/workgroup/type';
import { getErrorMessage } from '@/utils/utils';
import { Button } from '@tigergraph/app-ui-lib/button';
import { StyledToast } from '@tigergraph/app-ui-lib/styledToasterContainer';
import { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';

export default function GeneralForm({
  workspace,
  onClose,
  workspaceTypes,
}: {
  workspace: WorkspaceT;
  onClose: () => void;
  workspaceTypes: WorkSpaceType[];
}) {
  const form = useForm<GeneralType>({
    defaultValues: {
      workspace_type_name: workspace.workspace_type.typeName,
      enable_ha: workspace.enable_ha,
    },
  });

  const { handleSubmit, watch } = form;

  const workspace_type_name = watch('workspace_type_name');
  const enable_ha = watch('enable_ha');
  const disabled =
    !workspace.is_rw ||
    (workspace.workspace_type.typeName === workspace_type_name && workspace.enable_ha === enable_ha);

  const updateWorkSpaceMutation = useMutationUpdateWorkspace();

  const onUpdate = (data: GeneralType) => {
    const { workspace_type_name, enable_ha } = data;
    const updateRequest: UpdateWorkspaceRequest = { workspace_type_name, enable_ha };

    const promise = updateWorkSpaceMutation.mutateAsync(
      {
        group_id: workspace.workgroup_id,
        space_id: workspace.workspace_id,
        org_id: workspace.org_id,
        data: updateRequest,
      },
      {
        onSuccess() {
          onClose();
        },
      }
    );

    toast.promise(
      promise,
      {
        loading: 'Updating workspace',
        success: (data) => 'Workspace Updating request accepted.',
        error: (err) => `${getErrorMessage(err)}`,
      },
      {}
    );
  };

  const { data } = useQuery<Result<SolutionInstance[]>, AxiosError>(['solutions', workspace.workgroup_id], async () => {
    return listSolution(workspace.workgroup_id);
  });

  let canEdit = true;
  if (data && data.Result) {
    for (const solution of data.Result) {
      if (solution.workspace_id === workspace.workspace_id && solution.solution_status === 'install_pending') {
        canEdit = false;
      }
    }
  }

  return (
    <StyledForm>
      <StyledFormBody>
        <General
          form={form}
          workspace={workspace}
          workspaceTypes={workspaceTypes}
          disableResize={!workspace.is_rw}
          disableHA={!workspace.is_rw}
        />
        {!canEdit && (
          <StyledToast
            kind="negative"
            hideBorder={true}
            title="The current workspace is in the process of installing a solution and cannot be resized at this time."
            closeable={false}
          />
        )}
      </StyledFormBody>
      <StyledFormAction>
        <Button type="button" kind="secondary" size="large" onClick={onClose}>
          Cancel
        </Button>
        <Button
          type="button"
          size="large"
          disabled={disabled || !canEdit}
          onClick={handleSubmit(async (data) => {
            onUpdate(data);
          })}
        >
          Save
        </Button>
      </StyledFormAction>
    </StyledForm>
  );
}
