import IconButton from '@/components/IconButton';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { StatefulPopover } from '@tigergraph/app-ui-lib/popover';
import { PLACEMENT, TRIGGER_TYPE } from 'baseui/popover';
import React from 'react';

export default function TooltipLabel({ label, tooltip }: { label: string; tooltip?: React.ReactNode }) {
  const [css, theme] = useStyletron();
  return (
    <div
      className={css({
        fontSize: '14px',
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
      })}
    >
      <span>{label}</span>
      {tooltip && (
        <StatefulPopover
          triggerType={TRIGGER_TYPE.hover}
          content={
            <div
              className={css({
                maxWidth: '500px',
              })}
            >
              {tooltip}
            </div>
          }
          placement={PLACEMENT.bottom}
        >
          <IconButton type="button" tabIndex={-1}>
            <AiOutlineQuestionCircle size={16} />
          </IconButton>
        </StatefulPopover>
      )}
    </div>
  );
}
