import { Result } from '@/lib/type';
import { addSchedule, deleteSchedule, updateSchedule, listSchedule, ScheduleAPI } from './api';
import { ScheduleData, Schedule } from './type';
import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { format, parse } from 'date-fns';

export function roundTo30Minutes(date: Date) {
  const coeff = 1000 * 60 * 30;
  return new Date(Math.round(date.getTime() / coeff) * coeff);
}

function toScheduleDataAPI(data: ScheduleData): ScheduleAPI {
  return {
    ...data,
    // @ts-expect-error
    repeat_on: data.repeat_on ? data.repeat_on.join(',') : '',
    // @ts-expect-error
    date: data.date ? format(data.date, 'yyyy-MM-dd') : '',
    time: data.time ? format(data.time, 'HH:mm') : '',
  };
}

function toScheduleData(data: ScheduleAPI): Schedule {
  return {
    ...data,
    // @ts-expect-error
    repeat_on: data.repeat_on?.split(',').map((day) => parseInt(day)),
    // @ts-expect-error
    date: data.date ? parse(data.date, 'yyyy-MM-dd', new Date()) : new Date(),
    time: data.time ? parse(data.time, 'HH:mm', new Date()) : roundTo30Minutes(new Date()),
  };
}

export function useMutationAddSchedule() {
  const queryClient = useQueryClient();

  return useMutation<Result<void>, AxiosError, { group_id: string; workspace_id: string; data: ScheduleData }>(
    ({ group_id, workspace_id, data }) => {
      return addSchedule(group_id, workspace_id, toScheduleDataAPI(data));
    },
    {
      onSuccess: async (_, { group_id, workspace_id }) => {
        queryClient.invalidateQueries(['schedule', group_id, workspace_id]);
      },
    }
  );
}

export function useMutationDeleteSchedule() {
  const queryClient = useQueryClient();

  return useMutation<Result<void>, AxiosError, { group_id: string; workspace_id: string; id: string }>(
    ({ group_id, workspace_id, id }) => {
      return deleteSchedule(group_id, workspace_id, id);
    },
    {
      onSuccess: async (_, { group_id, workspace_id }) => {
        queryClient.invalidateQueries(['schedule', group_id, workspace_id]);
      },
    }
  );
}

export function useMutationUpdateSchedule() {
  const queryClient = useQueryClient();

  return useMutation<
    Result<void>,
    AxiosError,
    { group_id: string; workspace_id: string; id: string; data: ScheduleData }
  >(
    ({ group_id, workspace_id, id, data }) => {
      return updateSchedule(group_id, workspace_id, id, toScheduleDataAPI(data));
    },
    {
      onSuccess: async (_, { group_id, workspace_id }) => {
        queryClient.invalidateQueries(['schedule', group_id, workspace_id]);
      },
    }
  );
}

export function useQueryListSchedule(group_id: string, space_id: string) {
  return useQuery<Result<Schedule[]>, AxiosError>(['schedule', group_id, space_id], async () => {
    const result = await listSchedule(group_id, space_id);
    if (result.Result) {
      const data = result.Result.map(toScheduleData);
      return { ...result, Result: data };
    }
    return {
      ...result,
      Result: [],
    };
  });
}
