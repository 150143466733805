// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  QueryText = 1,
  String = 2,
  BlockComment = 3,
  LineComment = 4,
  Number = 5,
  Bool = 6,
  ParenL = 7,
  ParenR = 8,
  BraceL = 9,
  BraceR = 10,
  BracketL = 11,
  BracketR = 12,
  Dot = 13,
  Identifier = 14,
  ACCUM = 15,
  ADD = 16,
  ALL = 17,
  ALLOCATE = 18,
  ALTER = 19,
  ANY = 20,
  ASC = 21,
  BAG = 22,
  BETWEEN = 23,
  BOOL = 24,
  BY = 25,
  CALL = 26,
  COALESCE = 27,
  COMPRESS = 28,
  COUNT = 29,
  CREATE = 30,
  KAFKA = 31,
  S3 = 32,
  DATETIME_ADD = 33,
  DATETIME_SUB = 34,
  DATETIME_DIFF = 35,
  DATETIME_TO_EPOCH = 36,
  DATETIME_FORMAT = 37,
  DELETE = 38,
  DESC = 39,
  DISTRIBUTED = 40,
  DROP = 41,
  DATETIME = 42,
  DOUBLE = 43,
  EPOCH_TO_DATETIME = 44,
  EDGE = 45,
  ESCAPE = 46,
  FALSE = 47,
  FILE = 48,
  FIXED_BINARY = 49,
  FLOAT = 50,
  FROM = 51,
  GLOBAL = 52,
  GRAPH = 53,
  GROUP = 54,
  HAVING = 55,
  HEADER = 56,
  INDEX = 57,
  INPUT_LINE_FILTER = 58,
  INSERT = 59,
  INT = 60,
  INTERPRET = 61,
  INTO = 62,
  ISEMPTY = 63,
  JOB = 64,
  LIKE = 65,
  LIMIT = 66,
  NOT = 67,
  NOW = 68,
  NULL = 69,
  OPENCYPHER = 70,
  OR = 71,
  ORDER = 72,
  PRIMARY_ID = 73,
  PRIMARY = 74,
  KEY = 75,
  DISCRIMINATOR = 76,
  QUERY = 77,
  REDUCE = 78,
  REPLACE = 79,
  SAMPLE = 80,
  SELECT = 81,
  SELECTVERTEX = 82,
  SET = 83,
  STRING = 84,
  SUM = 85,
  TEMP_TABLE = 86,
  TO = 87,
  TO_CSV = 88,
  WITH = 89,
  REVERSED_EDGE = 90,
  TRIM = 91,
  TRUE = 92,
  TUPLE = 93,
  UINT = 94,
  UPDATE = 95,
  VALUES = 96,
  VERTEX = 97,
  WHERE = 98,
  AVG = 99,
  ABORT = 100,
  ACL = 101,
  API = 102,
  ATTRIBUTE = 103,
  CONCAT = 104,
  DATA = 105,
  DATA_SOURCE = 106,
  DEFINE = 107,
  DIRECTED = 108,
  EMPTY = 109,
  EXPORT = 110,
  FILENAME = 111,
  GET = 112,
  GRANT = 113,
  IMPORT = 114,
  INSTALL = 115,
  JSON = 116,
  LOADING = 117,
  LOCAL = 118,
  LS = 119,
  MAX = 120,
  MIN = 121,
  MINUS = 122,
  OVERWRITE = 123,
  OWNER = 124,
  PAIR = 125,
  PASSWORD = 126,
  PRIVILEGE = 127,
  PUT = 128,
  READ = 129,
  REJECT_LINE_RULE = 130,
  RESUME = 131,
  REVOKE = 132,
  ROLE = 133,
  RUN = 134,
  SCHEMA = 135,
  SCHEMA_CHANGE = 136,
  SECONDARY_ID = 137,
  SECRET = 138,
  SHOW = 139,
  SPLIT = 140,
  STATS = 141,
  STATUS = 142,
  STORE = 143,
  SUBSTR = 144,
  SYNTAX = 145,
  TAG = 146,
  TEMPLATE = 147,
  TOKEN = 148,
  TOKEN_LEN = 149,
  TOKENBANK = 150,
  UNDIRECTED = 151,
  USE = 152,
  USER = 153,
  JSONOBJECT = 154,
  JSONARRAY = 155,
  LOAD = 156,
  USING = 157,
  FOR = 158,
  TAGS = 159,
  AND = 160,
  NUMERIC = 161,
  IN = 162,
  IS = 163,
  TYPEDEF = 164,
  GSQLScript = 165,
  QueryBlock = 166,
  CreateQueryModifiers = 167,
  UseGraphStmt = 168,
  RunQueryStmt = 169,
  RunOptions = 170,
  ParameterValueList = 171,
  ParameterConstant = 172,
  ParameterValueJSON = 176,
  ParameterValueJSONPair = 177,
  InstallQueryStmt = 178,
  InstallOptions = 179,
  LoadingJobBlock = 183,
  GraphOption = 184,
  LoadingJobBodyStmts = 185,
  DefineFilename = 186,
  DefineHeader = 188,
  LoadStmt = 189,
  VertexDestinationClause = 190,
  AttrExpr = 191,
  Constant = 192,
  FunctionCall = 193,
  WhereClause = 194,
  Condition = 195,
  Expression = 196,
  EdgeDestinationClause = 200,
  TempTableClause = 201,
  UsingClause = 202,
  DeleteVertexStmt = 203,
  DeleteEdgeStmt = 204
