import { ErrorDisplay } from '@/components/error';
import { LoadingIndicator } from '@/components/loading-indicator';
import { TableBuilder } from '@tigergraph/app-ui-lib/table';
import { TableBuilderColumn } from 'baseui/table-semantic';
import { WorkGroupT, WorkspaceT, isStatusActive } from '@/pages/workgroup/type';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { useState } from 'react';
import { useTheme } from '@/contexts/themeContext';
import { WorkspaceSelector } from '@/pages/editor/WorkspaceSelector';
import { useQuery } from 'react-query';
import { fetchGraphList } from '@/lib/instance_api';
import { ID_TOKEN_KEY } from '@/contexts/workspaceContext';
import { GraphSelector } from '@/pages/explore/explore/GraphSelector';
import { StatisticsType, useQueryGraphStatistics } from '@/pages/dashboard/hook';
import { Chart } from '@tigergraph/app-ui-lib/charts';
import NoGraph from '@/pages/dashboard/NoGraph';
import EmptyState from '@/pages/workgroup/EmptyState';

type Props = {
  group: WorkGroupT;
};

export default function QuickInsightLists({ group }: Props) {
  const [css] = useStyletron();

  const { workspaces } = group;
  // initial workspace with active workspace
  const [workspace, setWorkspace] = useState<WorkspaceT | undefined>(
    workspaces.filter((w) => isStatusActive(w.status))[0]
  );
  const [currentGraph, setCurrentGraph] = useState<string>('');

  const { data: graphs = [], isFetching: isFetchingGraph } = useQuery(
    ['graph_list', workspace?.nginx_host],
    async () => {
      if (!workspace?.nginx_host) {
        return;
      }
      return fetchGraphList(workspace?.nginx_host, workspace?.tg_version, sessionStorage.getItem(ID_TOKEN_KEY)!);
    },
    {
      enabled: workspace?.nginx_host !== undefined,
      onSuccess(data) {
        // setup default graph name
        if (data && data.length > 0) {
          // check if currentGraph is in the list
          if (!data.includes(currentGraph)) {
            const nonDefaultGraphs = data.filter((d) => d !== 'MyGraph');
            setCurrentGraph(nonDefaultGraphs.length > 0 ? nonDefaultGraphs[0] : data[0]);
          }
        }
      },
    }
  );

  let { data, isLoading, isError, error } = useQueryGraphStatistics(workspace?.nginx_host || '', currentGraph, {
    enabled: workspace?.nginx_host !== undefined && currentGraph !== '',
  });

  if (data && (data.vertexCounts.error || data.edgeCounts.error)) {
    isError = true;
    // @ts-ignore
    error = new Error(data.edge.error || data.vertex.error);
  }

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        height: '100%',
      })}
    >
      <div
        className={css({
          display: 'flex',
          justifyContent: 'flex-end',
        })}
      >
        <WorkspaceSelector
          isLocalSelector={true}
          workspace={workspace}
          onWorkspaceChange={setWorkspace}
          workgroupId={group.workgroup_id}
        />
        <div
          className={css({
            width: '200px',
          })}
        >
          <GraphSelector
            graphs={graphs}
            graph={currentGraph}
            onGraphChanged={(graphName) => {
              setCurrentGraph(graphName);
            }}
            isFetching={isFetchingGraph}
          />
        </div>
      </div>
      {(isLoading || isFetchingGraph) && <LoadingIndicator />}
      {isError && <ErrorDisplay error={error} />}
      {workspaces.length === 0 || !workspace ? (
        <div className={css({ flexBasis: 0, flexGrow: 1 })}>
          <EmptyState group={group} toPage="spaces/config" />
        </div>
      ) : (
        !isFetchingGraph && !currentGraph && workspace && <NoGraph space_id={workspace.workspace_id} />
      )}
      {!isLoading && !isError && data && <Dashboard data={data} />}
    </div>
  );
}

function Dashboard({ data }: { data: StatisticsType }) {
  const [css] = useStyletron();
  const { vertexCounts, edgeCounts } = data;
  const totalVertices = vertexCounts.results ? vertexCounts.results.reduce((acc, cur) => acc + cur.count, 0) : 0;
  const totalEdges = edgeCounts.results ? edgeCounts.results.reduce((acc, cur) => acc + cur.count, 0) : 0;

  return (
    <div
      className={css({
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '16px',
        paddingBottom: '16px',
      })}
    >
      <TotalWidget totalVertices={totalVertices} totalEdges={totalEdges} />
      <CountDistributeByType
        label="Node Count Distribution"
        counts={(vertexCounts.results || []).map((v) => ({
          type: v.v_type,
          count: v.count,
        }))}
      />
      <CountByType
        label="Node Type Count"
        counts={(vertexCounts.results || []).map((v) => ({
          type: v.v_type,
          count: v.count,
        }))}
      />
      <CountDistributeByType
        label="Edge Count Distribution"
        counts={(edgeCounts.results || []).map((v) => ({
          type: v.e_type,
          count: v.count,
        }))}
      />
      <CountByType
        label="Edge Count Count"
        counts={(edgeCounts.results || []).map((v) => ({
          type: v.e_type,
          count: v.count,
        }))}
      />
    </div>
  );
}

function TotalWidget({ totalEdges, totalVertices }: { totalVertices: number; totalEdges: number }) {
  const [css, theme] = useStyletron();
  return (
    <div
      className={css({
        padding: '16px 32px 0px',
        borderRadius: '2px',
        border: `1px solid ${theme.colors['border.tertiary']}`,
        display: 'flex',
        backgroundColor: theme.colors['background.primary'],
        gridColumn: 'span 2',
      })}
    >
      <TotalItem label="# of Nodes" count={totalVertices} />
      <TotalItem label="# of Edges" count={totalEdges} />
    </div>
  );
}

function TotalItem({ label, count }: { label: string; count: number }) {
  const [css, theme] = useStyletron();
  return (
    <div className={css({ display: 'flex', flexDirection: 'column', gap: '8px', flex: 1 })}>
      <span
        className={css({
          fontSize: '18px',
          color: theme.colors['text.secondary'],
        })}
      >
        {label}
      </span>
      <span
        className={css({
          fontSize: '90px',
          fontWeight: 400,
        })}
      >
        {count}
      </span>
    </div>
  );
}

function CountDistributeByType({ label, counts }: { label: string; counts: { type: string; count: number }[] }) {
  const { themeType } = useTheme();
  const [css, theme] = useStyletron();
  const sortedCounts = counts.sort((a, b) => b.count - a.count);

  const option = {
    title: {
      text: label,
      textStyle: {
        fontSize: 16,
      },
    },
    tooltip: {
      trigger: 'item',
    },
    series: [
      {
        data: sortedCounts.map((c) => ({
          value: c.count,
          name: c.type,
        })),
        type: 'pie',
      },
    ],
  };

  return (
    <div
      className={css({
        borderRadius: '2px',
        border: `1px solid ${theme.colors['border.tertiary']}`,
        display: 'flex',
        backgroundColor: theme.colors['background.primary'],
        height: '400px',
      })}
    >
      <Chart
        option={option}
        style={{ height: '100%', width: '100%', padding: '18px  12px' }}
        theme={themeType === 'dark' ? themeType : undefined}
      />
    </div>
  );
}

function CountByType({ label, counts }: { label: string; counts: { type: string; count: number }[] }) {
  const [css, theme] = useStyletron();
  const sortedCounts = counts.sort((a, b) => b.count - a.count);

  return (
    <div
      className={css({
        borderRadius: '2px',
        border: `1px solid ${theme.colors['border.tertiary']}`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        backgroundColor: theme.colors['background.primary'],
        height: '400px',
        padding: '16px',
      })}
    >
      <div
        className={css({
          fontWeight: 'bold',
          fontSize: '18px',
        })}
      >
        {label}
      </div>
      <TableBuilder data={sortedCounts} size="compact">
        <TableBuilderColumn header="Type" id="type">
          {(data) => data.type}
        </TableBuilderColumn>
        <TableBuilderColumn header="count" id="count">
          {(data) => data.count}
        </TableBuilderColumn>
      </TableBuilder>
    </div>
  );
}
