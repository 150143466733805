export const tutorials: { name: string; content: string }[] = [
  {
    name: '1. Create Schema',
    content: `USE GLOBAL

//create vertex types
CREATE VERTEX Account ( name STRING PRIMARY KEY, isBlocked BOOL)
CREATE VERTEX City ( name STRING PRIMARY KEY)
CREATE VERTEX Phone (nmuber STRING PRIMARY KEY, isBlocked BOOL)

//create edge types
CREATE DIRECTED EDGE transfer (FROM Account, TO Account, DISCRIMINATOR(date DATETIME), amount UINT) WITH REVERSE_EDGE="transfer_reverse"
CREATE UNDIRECTED EDGE hasPhone (FROM Account, TO Phone)
CREATE DIRECTED EDGE isLocatedIn (FROM Account, TO City)

//create graph; * means include all graph element types in the graph.
CREATE GRAPH financialGraph (*)
  `,
  },
  {
    name: '2. Load Data',
    content: `USE GRAPH financialGraph

DROP JOB load_s3_file
DROP DATA_SOURCE s3_data_source_0

#define a data source from s3
CREATE DATA_SOURCE s3_data_source_0 = "{\\"access.key\\":\\"none\\",\\"secret.key\\":\\"none\\",\\"file.reader.settings.fs.s3a.aws.credentials.provider\\": \\"org.apache.hadoop.fs.s3a.AnonymousAWSCredentialsProvider\\",\\"type\\":\\"s3\\"}" FOR GRAPH financialGraph


CREATE LOADING JOB load_s3_file  {

// define the s3 location of the source files; each file path is assigned a filename variable.
DEFINE FILENAME account="""$s3_data_source_0:{"file.uris":"s3a://tigergraph-example-data/ecosys/account.csv"}""";
DEFINE FILENAME phone="""$s3_data_source_0:{"file.uris":"s3a://tigergraph-example-data/ecosys/phone.csv"}""";
DEFINE FILENAME city="""$s3_data_source_0:{"file.uris":"s3a://tigergraph-example-data/ecosys/city.csv"}""";
DEFINE FILENAME hasPhone="""$s3_data_source_0:{"file.uris":"s3a://tigergraph-example-data/ecosys/hasPhone.csv"}""";
DEFINE FILENAME locatedIn="""$s3_data_source_0:{"file.uris":"s3a://tigergraph-example-data/ecosys/locate.csv"}""";
DEFINE FILENAME transferdata="""$s3_data_source_0:{"file.uris":"s3a://tigergraph-example-data/ecosys/transfer.csv"}""";

//define the mapping from the source file to the target graph element type. The mapping is specified by VALUES clause.
LOAD account TO VERTEX Account VALUES ($"name", gsql_to_bool(gsql_trim($"isBlocked"))) USING header="true", separator=",";
LOAD phone TO VERTEX Phone VALUES ($"number", gsql_to_bool(gsql_trim($"isBlocked"))) USING header="true", separator=",";
LOAD city TO VERTEX City VALUES ($"name") USING header="true", separator=",";
LOAD hasPhone TO Edge hasPhone VALUES ($"accnt", gsql_trim($"phone")) USING header="true", separator=",";
LOAD locatedIn TO Edge isLocatedIn VALUES ($"accnt", gsql_trim($"city")) USING header="true", separator=",";
LOAD transferdata TO Edge transfer VALUES ($"src", $"tgt", $"date", $"amount") USING header="true", separator=",";

}

run loading job load_s3_file
    `,
  },
  {
    name: '3. Query Data (q1a)',
    content: `#enter the graph
USE GRAPH financialGraph

CREATE OR REPLACE QUERY q1a () SYNTAX v3 {

  v = SELECT a
      FROM (a:Account);

  PRINT v;
}

#compile and install the query as a stored procedure
install query q1a

#run the query
run query q1a()
    `,
  },
  {
    name: '4. Query Data (q1b)',
    content: `#enter the graph
USE GRAPH financialGraph

CREATE OR REPLACE QUERY q1b () SYNTAX v3 {

  //select an expression list into a table T
  SELECT a.isBlocked, count(*) INTO T
  FROM (a:Account)
  GROUP BY a.isBlocked;

  //out put the table T in JSON format
  PRINT T;
}

#compile and install the query as a stored procedure
install query q1b

#run the query
run query q1b()
    `,
  },
  {
    name: '5. Query Data (q2a)',
    content: `USE GRAPH financialGraph

CREATE OR REPLACE QUERY q2a (string accntName) SYNTAX v3 {

  SumAccum<int> @totalTransfer = 0;

  v = SELECT b
      FROM (a:Account {name: accntName})-[e:transfer]->(b:Account)
      ACCUM  b.@totalTransfer += e.amount;

  PRINT v;

}

#compile and install the query as a stored procedure
install query q2a

#run the query
run query q2a("Scott")
    `,
  },
  {
    name: '6. Query Data (q2b)',
    content: `USE GRAPH financialGraph

CREATE OR REPLACE QUERY q2b () SYNTAX v3 {

  //think the FROM clause is a matched table with columns (a, e, b)
  //you can use SQL syntax to group by the source and target account, and sum the total transfer amount
  SELECT a, b, sum(e.amount)  INTO T
  FROM (a:Account)-[e:transfer]->(b:Account)
  GROUP BY a, b;

  //output the table in JSON format
  PRINT T;

}

#compile and install the query as a stored procedure
install query q2b

#run the query
run query q2b()
    `,
  },
  {
    name: '7. Query Data (q3a)',
    content: `USE GRAPH financialGraph

// create a query
CREATE OR REPLACE QUERY q3a (datetime low, datetime high, string accntName) SYNTAX v3 {

  // a path pattern in ascii art () -[]->()-[]->()
  R = SELECT b
      FROM (a:Account {name: accntName})-[e:transfer]->()-[e2:transfer]->(b:Account)
      WHERE e.date >= low AND e.date <= high and e.amount >500 and e2.amount>500;

      PRINT R;

  // below we use variable length path.
  // *1.. means 1 to more steps of the edge type "transfer"
  // select the reachable end point and bind it to vertex alias "b"
  R = SELECT b
      FROM (a:Account {name: accntName})-[:transfer*1..]->(b:Account);

      PRINT R;

}

install query q3a

run query q3a("2024-01-01", "2024-12-31", "Scott")
    `,
  },
  {
    name: '8. Query Data (q3b)',
    content: `USE GRAPH financialGraph

// create a query
CREATE OR REPLACE QUERY q3b (datetime low, datetime high, string accntName) SYNTAX v3 {

   // a path pattern in ascii art () -[]->()-[]->()
   // think the FROM clause is a matched table with columns (a, e, b, e2, c)
   // you can use SQL syntax to group by on the matched table
   // Below query find 2-hop reachable account c from a, and group by the path a, b, c
   // find out how much each hop's total transfer amount.
   SELECT a, b, c, sum(DISTINCT e.amount) AS hop_1_sum,  sum(DISTINCT e2.amount) AS hop_2_sum INTO T1
   FROM (a:Account)-[e:transfer]->(b)-[e2:transfer]->(c:Account)
   WHERE e.date >= low AND e.date <= high
   GROUP BY a, b, c;

   PRINT T1;

   // below we use variable length path.
   // *1.. means 1 to more steps of the edge type "transfer"
   // select the reachable end point and bind it to vertex alias "b"
   // note:
   // 1. the path has "shortest path" semantics. If you have a path that is longer than the shortest,
   // we only count the shortest. E.g., scott to scott shortest path length is 4. Any path greater than 4 will
   // not be matched.
   // 2. we can not put an alias to bind the edge in the the variable length part -[:transfer*1..]->, but
   // we can bind the end points (a) and (b) in the variable length path, and group by on them.
   SELECT a, b, count(*) AS path_cnt INTO T2
   FROM (a:Account {name: accntName})-[:transfer*1..]->(b:Account)
   GROUP BY a, b;

   PRINT T2;

}

install query q3b

run query q3b("2024-01-01", "2024-12-31", "Scott")
    `,
  },
];
