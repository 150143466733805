import { MdOutlineCloud } from 'react-icons/md';
import { Drawer, DrawerAction, DrawerBody, DrawerHeader } from '@/components/Drawer';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Button } from '@tigergraph/app-ui-lib/button';
import { SubmitHandler, useForm } from 'react-hook-form';
import shortUUID from 'short-uuid';
import { useMutation, useQueryClient } from 'react-query';
import { Result } from '@/lib/type';
import {
  CreateCloudProviderRequest as CreateProviderRequest,
  createProvider,
} from '@/pages/admin/settings/cloud_provider/api';
import { AxiosError } from 'axios';
import { useOrgContext } from '@/contexts/orgContext';
import { showToast } from '@/components/styledToasterContainer';
import { getErrorMessage } from '@/utils/utils';
import { useState } from 'react';
import Breadcrumbs from '@/components/Breadcrumbs';
import SelectVendor from '@/pages/admin/settings/cloud_provider/SelectVendor';
import CreateRole from '@/pages/admin/settings/cloud_provider/CreateRole';
import { IFormInput } from '@/pages/admin/settings/cloud_provider/types';
import { getSubnetId } from '@/pages/admin/settings/cloud_provider/util';
import { CloudPlatform } from '@/pages/admin/settings/cloud_provider/CloudProviderCategory';
import { KIND } from 'baseui/button';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

enum Steps {
  CONFIGURE_VENDOR = 0,
  CREATE_ROLE = 1,
}

export default function AddCloudProvider({ isOpen, onClose }: Props) {
  const [css, theme] = useStyletron();
  const queryClient = useQueryClient();

  const { currentOrg } = useOrgContext();

  const [stepIndex, setStepIndex] = useState(Steps.CONFIGURE_VENDOR);

  const createProviderMutation = useMutation<Result<void>, AxiosError, CreateProviderRequest>(
    async (data) => {
      const res = await createProvider(data);
      return res;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['cloud-providers']);
      },
    }
  );

  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    watch,
    setValue,
    trigger: validateForm,
  } = useForm<IFormInput>({
    defaultValues: {
      name: '',
      region: '',
      awsRoleArn: '',
      awsExternalId: shortUUID().generate(),
      vpcId: '',
      subnetId: '',
      securityGroupId: '',
      trustedAccountId: '',
      vpcOwnedByUser: false,
      secureConnection: false,
    },
  });

  const name = watch('name');

  const cloudPlatform = CloudPlatform.AWS;

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    createProviderMutation.mutate(
      {
        org_id: currentOrg.org_id,
        platform: cloudPlatform,
        region: data.region,
        name: data.name,
        role_arn: data.awsRoleArn,
        role_external_id: data.awsExternalId,
        vpc_owned_by_tg: !data.vpcOwnedByUser,
        vpc_id: data.vpcId,
        subnet_id: getSubnetId(data.subnetId),
        security_group_id: data.securityGroupId,
        type: '',
        options: {
          secure_connection: data.secureConnection,
          account_id: data.trustedAccountId,
        },
      },
      {
        onSuccess: () => {
          showToast({
            kind: 'positive',
            message: 'Cloud provider added successfully',
          });
          onClose();
          queryClient.invalidateQueries(['cloud-providers']);
        },
        onError: (error) => {
          showToast({
            kind: 'negative',
            message: getErrorMessage(error),
          });
        },
      }
    );
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="auto">
      <DrawerHeader hideLine>
        <div className={css({ display: 'flex', alignItems: 'center' })}>
          <MdOutlineCloud size={24} /> <span className={css({ marginLeft: '8px' })}>Add Cloud Provider</span>
        </div>
      </DrawerHeader>
      <Breadcrumbs names={['Configure Vendor', 'Create Role']} index={stepIndex} compact={true} />
      <DrawerBody>
        <form>
          {stepIndex === Steps.CONFIGURE_VENDOR && (
            <SelectVendor
              control={control}
              errors={errors}
              cloudPlatform={cloudPlatform}
              setValue={setValue}
              watch={watch}
            />
          )}
          {stepIndex === Steps.CREATE_ROLE && (
            <CreateRole
              control={control}
              errors={errors}
              watch={watch}
              setValue={setValue}
              validateForm={validateForm}
              cloudPlatform={cloudPlatform}
            />
          )}
        </form>
      </DrawerBody>
      <DrawerAction>
        <div className={css({ display: 'flex', gap: '16px' })}>
          {stepIndex === Steps.CONFIGURE_VENDOR ? (
            <Button kind={KIND.secondary} onClick={() => onClose()}>
              Cancel
            </Button>
          ) : (
            <Button kind={KIND.secondary} onClick={() => setStepIndex((prev) => prev - 1)}>
              Back
            </Button>
          )}
          {stepIndex === Steps.CREATE_ROLE ? (
            <Button onClick={handleSubmit(onSubmit)}>Add</Button>
          ) : (
            <Button disabled={!name} onClick={() => setStepIndex((prev) => prev + 1)}>
              Next
            </Button>
          )}
        </div>
      </DrawerAction>
    </Drawer>
  );
}
