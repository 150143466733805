import WorkspaceAddonsConfiguration from '@/pages/workgroup/form/addons/configuration';
import { StyledForm, StyledFormAction, StyledFormBody } from '@/pages/workgroup/form/setting/styled';
import { useMutationUpdateWorkspace } from '@/pages/workgroup/hook';
import { UpdateWorkspaceRequest, WorkspaceAddons, WorkspaceT } from '@/pages/workgroup/type';
import { getErrorMessage } from '@/utils/utils';
import { Button } from '@tigergraph/app-ui-lib/button';
import { useForm, UseFormReturn } from 'react-hook-form';
import toast from 'react-hot-toast';

type AddonType = {
  addons: Record<string, WorkspaceAddons>;
};

export default function AddonForm({
  workspace,
  configureContainer,
  onClose,
}: {
  configureContainer?: HTMLDivElement | null;
  workspace: WorkspaceT;
  onClose: () => void;
}) {
  const defaultValues = {
    addons: workspace.addons?.reduce((res, cur) => {
      res[cur.addons_id] = cur;
      return res;
    }, {} as Record<string, WorkspaceAddons>),
  };

  const form = useForm<AddonType>({
    defaultValues: defaultValues,
  });

  const { handleSubmit, watch } = form;
  const addons = watch('addons');
  let isDirty = false;

  for (let key in addons) {
    if (
      (!addons[key].enable && defaultValues.addons?.[key]?.enable) ||
      (addons[key].enable && !defaultValues.addons?.[key]?.enable)
    ) {
      isDirty = true;
      break;
    }
  }

  const updateWorkSpaceMutation = useMutationUpdateWorkspace();

  const onUpdate = (data: AddonType) => {
    const addons: WorkspaceAddons[] = Object.entries(data.addons || {})
      .filter(([_, val]) => val.enable !== undefined)
      .map(([id, { enable, enable_configuration }]) => ({
        addons_id: id,
        enable,
        enable_configuration: enable_configuration || {},
      }));

    const updateRequest: UpdateWorkspaceRequest = {
      addons,
    };
    const promise = updateWorkSpaceMutation.mutateAsync(
      {
        group_id: workspace.workgroup_id,
        space_id: workspace.workspace_id,
        org_id: workspace.org_id,
        data: updateRequest,
      },
      {
        onSuccess() {
          onClose();
        },
      }
    );

    toast.promise(
      promise,
      {
        loading: 'Updating Add-ons',
        success: (data) => 'Add-ons successfully updated.',
        error: (err) => `${getErrorMessage(err)}`,
      },
      {}
    );
  };

  return (
    <StyledForm>
      <StyledFormBody>
        <WorkspaceAddonsConfiguration
          form={form as unknown as UseFormReturn}
          workspace={workspace}
          configureContainer={configureContainer}
        />
      </StyledFormBody>
      <StyledFormAction>
        <Button type="button" kind="secondary" size="large" onClick={onClose}>
          Cancel
        </Button>
        <Button
          type="button"
          size="large"
          disabled={!isDirty}
          aria-label="addon-save"
          onClick={handleSubmit(async (data) => {
            onUpdate(data);
          })}
        >
          Save
        </Button>
      </StyledFormAction>
    </StyledForm>
  );
}
