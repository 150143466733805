import { ApplicationT } from '@/insights/cloud/type';
import { SolutionInstance } from '@/pages/marketplace/solution/type';
import { WorkspaceT } from '@/pages/workgroup/type';
import { AxiosError } from 'axios';
import { atom } from 'jotai';

export const workSpacesAtom = atom<WorkspaceT[]>([]);

export const workSpacesROUpdateAtom = atom<WorkspaceT[]>([]);

export const deletingSpaceIdsAtom = atom<Set<string>>(new Set<string>());

export const quickInsightsAtom = atom<ApplicationT[]>([]);

export const solutionsAtom = atom<SolutionInstance[]>([]);

export const createWorkspaceErrorAtom = atom<AxiosError | null>(null);
