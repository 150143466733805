import { styled } from '@tigergraph/app-ui-lib/Theme';

export const StyledForm = styled('form', ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

export const StyledFormBody = styled('div', ({ $theme }) => ({
  minHeight: 0,
  flexBasis: 0,
  flexGrow: 1,
  overflowY: 'auto',
}));

export const StyledFormAction = styled('div', ({ $theme }) => ({
  padding: '16px 0 16px',
  display: 'flex',
  gap: '16px',
  justifyContent: 'flex-end',
}));
