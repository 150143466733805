import { getGraphStatistics } from '@/pages/dashboard/api';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

export type StatisticsType = Awaited<ReturnType<typeof getGraphStatistics>>;

export function useQueryGraphStatistics(
  nginx_host: string,
  graph_name: string,
  options?: Omit<UseQueryOptions<StatisticsType, AxiosError>, 'queryKey' | 'queryFn'>
) {
  return useQuery<StatisticsType, AxiosError>(
    [nginx_host, 'statistics', graph_name],
    async () => {
      return getGraphStatistics(nginx_host, graph_name);
    },
    options
  );
}
