import { styled } from '@tigergraph/app-ui-lib/Theme';
import { StatefulPopover, StatefulPopoverProps } from 'baseui/popover';
import { expand } from 'inline-style-expand-shorthand';

export const SectionTitle = styled('div', ({ $theme }) => ({
  fontFamily: 'Roboto',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
}));

export const SectionContent = styled('div', ({ $theme }) => ({
  fontFamily: 'Urbanist',
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '32px',
}));
export function StyledMenu({ ...props }: StatefulPopoverProps) {
  return (
    <StatefulPopover
      {...props}
      overrides={{
        Body: {
          style: {
            marginTop: '2px',
            ...expand({ borderRadius: '5px' }),
          },
        },
        Inner: {
          style: {
            ...expand({ borderRadius: '5px' }),
          },
        },
      }}
    ></StatefulPopover>
  );
}
