import { AddonsMetaID } from '@/components/addons/consts';
import { atomWithReset } from 'jotai/utils';
import { useAtomValue, useSetAtom } from 'jotai';
import currency from 'currency.js';
import { useCallback } from 'react';
import { useWorkspaceContext } from '@/contexts/workspaceContext';
import { getAddonPrice } from '@/pages/workgroup/type';

export const createWorkspaceEnableAtom = atomWithReset({} as Record<string, boolean>);

export const useDisableAddonsCopilot = () => {
  const updateAddonsEnableMap = useSetAtom(createWorkspaceEnableAtom);
  const disableCopilot = useCallback(() => {
    updateAddonsEnableMap((m) => ({ ...m, [AddonsMetaID.COPILOT]: false }));
  }, [updateAddonsEnableMap]);

  return disableCopilot;
};

export const useAddonsIsEnabled = (addonsID: string) => {
  const enabledAddonsMap = useAtomValue(createWorkspaceEnableAtom);

  return enabledAddonsMap[addonsID];
};

export const useAddonsEnabledParams = (param: Record<string, string>) => {
  const enabledAddonsMap = useAtomValue(createWorkspaceEnableAtom);

  return {
    enable_copilot: enabledAddonsMap[AddonsMetaID.COPILOT] || false,
  };
};

export const useAddonsPrice = (workspaceType: string, platform: string, region: string) => {
  const enabledAddonsMap = useAtomValue(createWorkspaceEnableAtom);
  let addonsCurrency = currency(0);
  const { price } = useWorkspaceContext();

  const isInsightsEnable = useAddonsIsEnabled(AddonsMetaID.INSIGHTS);

  if (price) {
    if (enabledAddonsMap[AddonsMetaID.COPILOT]) {
      addonsCurrency = addonsCurrency.add(getAddonPrice(price, 'CoPilot', workspaceType, platform, region));
    }

    if (isInsightsEnable) {
      addonsCurrency = addonsCurrency.add(getAddonPrice(price, 'Insights', workspaceType, platform, region));
    }
  }

  return addonsCurrency;
};
