import { Return } from '@/lib/type';
import { checkQuery, QuerySyntax } from '@tigergraph/tools-models';
import { CommonRequestConfig } from '@tigergraph/tools-models/api/common';

export type CodeCheckRequest = {
  code: string;
  graph: string;
};
export type CodeCheckItem = {
  msg: string;
  errorcode: number;
  startLine?: number;
  startColumn?: number;
  endLine?: number;
  endColumn?: number;
  line?: number;
  charpositioninline?: number;
  startindex?: number;
  stopindex?: number;
};
export type CodeCheckResponse = Return<{
  errors: CodeCheckItem[];
  warnings: CodeCheckItem[];
}>;
export async function codeCheckReq(data: CodeCheckRequest, config: CommonRequestConfig) {
  const res = await checkQuery(data, config);
  return res.data as CodeCheckResponse;
}

export function normalizeErrorOrWarning(
  code: string,
  error: CodeCheckItem,
  syntax: QuerySyntax
): {
  startLine: number;
  startColumn: number;
  endLine: number;
  endColumn: number;
  message: string;
} {
  const lines = code.split('\n');
  const message = error.msg;
  let markStart: number | undefined;
  let markStop: number | undefined;

  // For cypher query, some errors are generated after the query has gone through the transformation stages
  // these types of errors will probably not have the correct line number, so we just mark the first line
  if (syntax === 'CYPHER' && error.errorcode !== 9999) {
    return {
      startLine: 0,
      startColumn: 0,
      endLine: 0,
      endColumn: 0,
      message: message,
    };
  }

  // Has startLine, startColumn, endLine, endColumn
  if ('startLine' in error && 'startColumn' in error && 'endLine' in error && 'endColumn' in error) {
    return {
      startLine: error.startLine!,
      startColumn: error.startColumn!,
      endLine: error.endLine!,
      endColumn: error.endColumn!,
      message: message,
    };
  }

  // Has start and stop index
  if ('startindex' in error && 'stopindex' in error) {
    markStart = error.startindex!;
    markStop = error.stopindex! + 1;
  }

  if (!markStart) {
    // If it's parsing error, mark until token ends
    if ('line' in error) {
      const line = error.line! - 1; // parsing error line number is always actual line number + 1
      const pos = error.charpositioninline!;
      let endPos = pos;

      if (line < lines.length) {
        for (; endPos < lines[line].length; endPos++) {
          if ([' ', '\t', '\n'].includes(lines[line].charAt(endPos))) {
            break;
          }
        }
      }

      return {
        startLine: line,
        startColumn: pos,
        endLine: line,
        endColumn: endPos,
        message: message,
      };
    } else {
      // Nothing there, mark the whole code
      return {
        startLine: 0,
        startColumn: 0,
        endLine: lines.length - 1,
        endColumn: lines[lines.length - 1].length,
        message: message,
      };
    }
  } else {
    // Contains both start position and end position
    const prefixStart = code.substring(0, markStart);
    const prefixStop = code.substring(0, markStop);
    return {
      startLine: prefixStart.split('\n').length - 1,
      startColumn: markStart - prefixStart.lastIndexOf('\n') - 1,
      endLine: prefixStop.split('\n').length - 1,
      endColumn: markStop! - prefixStop.lastIndexOf('\n') - 1,
      message: message,
    };
  }
}
