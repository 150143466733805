import { useAuth0 } from '@auth0/auth0-react';
import { useAsyncEffect } from 'ahooks';
import { useState } from 'react';

export type UseAdminDashboardPermissionReturnType = {
  readWorkspace?: boolean;
  updateWorkspace?: boolean;
  readOrg?: boolean;
  readOrgDetail?: boolean;
  updateOrg?: boolean;
};

export const useAdminDashboardPermission = () => {
  const { getIdTokenClaims, isAuthenticated } = useAuth0();
  const [permission, setPermission] = useState<UseAdminDashboardPermissionReturnType>();
  const [isInitialized, setIsInitialized] = useState(false);

  useAsyncEffect(async () => {
    if (!isAuthenticated) {
      setPermission(undefined);
      return;
    }

    const claims = await getIdTokenClaims();
    const v: string[] = claims?.['https://tgcloud.io/permissions'] || [];

    const newPermission: UseAdminDashboardPermissionReturnType = {};

    if (v.includes('read:resource')) {
      newPermission.readWorkspace = true;
    }

    if (v.includes('update:resource')) {
      newPermission.updateWorkspace = true;
    }

    if (v.includes('read:org')) {
      newPermission.readOrg = true;
    }

    if (newPermission.readOrg && v.includes('read:billing')) {
      newPermission.readOrgDetail = true;
    }

    if (v.includes('update:org')) {
      newPermission.updateOrg = true;
    }

    setPermission(newPermission);
    setIsInitialized(true);
  }, [getIdTokenClaims, isAuthenticated]);

  return {
    permission,
    isInitialized,
    canUseDashboard: permission ? permission.readOrg || permission.readWorkspace : false,
  };
};
