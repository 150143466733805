import { StatefulPopover } from '@tigergraph/app-ui-lib/popover';
import { expand } from 'inline-style-expand-shorthand';
import { PLACEMENT, TRIGGER_TYPE } from 'baseui/popover';
import { MdOutlineRefresh } from 'react-icons/md';
import { Button } from '@tigergraph/app-ui-lib/button';

export interface RefreshSchemaProps {
  refetchSchema?: () => void;
}

export default function RefreshSchema(props: RefreshSchemaProps) {
  const { refetchSchema } = props;

  return (
    <StatefulPopover placement={PLACEMENT.bottom} triggerType={TRIGGER_TYPE.hover} content={'Refresh Schema'}>
      <Button
        aria-label="refresh-schema"
        kind="secondary"
        size="compact"
        onClick={refetchSchema}
        overrides={{
          BaseButton: {
            style: {
              ...expand({
                padding: '2px 3px',
              }),
            },
          },
        }}
      >
        <MdOutlineRefresh size={16} />
      </Button>
    </StatefulPopover>
  );
}
