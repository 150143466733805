import { Button } from '@tigergraph/app-ui-lib/button';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Plus } from 'baseui/icon';
import EmptyIcon from './icon/empty.svg';
import EmptyDarkIcon from './icon/empty-dark.svg';
import { useTheme } from '@/contexts/themeContext';
import { TableBuilder } from '@tigergraph/app-ui-lib/table';
import { TableBuilderColumn } from 'baseui/table-semantic';
import { actionColumnOverrides } from '@/components/table';
import { Trash2Icon, Eye } from 'lucide-react';
import { ConfirmStatefulPopover } from '@/components/confirmPopover';
import {
  useMutationCreateAPIKey,
  useMutationDeleteAPIKey,
  useQueryListAPIKey,
} from '@/pages/admin/settings/apikey/hook';
import { LoadingIndicator } from '@/components/loading-indicator';
import { ErrorDisplay } from '@/components/error';
import toast from 'react-hot-toast';
import { getErrorMessage } from '@/utils/utils';
import { parseDate } from '@/lib/date';
import { format } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import { FormControl } from '@tigergraph/app-ui-lib/form-control';
import { Input } from '@tigergraph/app-ui-lib/input';
import { showToast, StyledToast } from '@tigergraph/app-ui-lib/styledToasterContainer';
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from '@tigergraph/app-ui-lib/modal';
import { useEffect, useState } from 'react';
import { CopyIcon } from 'lucide-react';
import useCopyClipboard from 'react-use-clipboard';
import { Select } from '@tigergraph/app-ui-lib/select';
import { TreeDiff, useMutationAssignUserRole, useQueryListUsers } from '@/pages/admin/user/hook';
import UserRoleForm from '@/pages/admin/user/user_role_form';
import { User } from '@/pages/admin/user/type';
import { APIKey } from '@/pages/admin/settings/apikey/type';
import { RoleName } from '@/pages/admin/user/user_list';

export default function APIKeyPage() {
  const [css] = useStyletron();

  const listAPIKey = useQueryListAPIKey();
  const listUser = useQueryListUsers(true);

  const mutationDelete = useMutationDeleteAPIKey();

  const [showDialog, setShowDialog] = useState(false);
  const [apiKey, setAPIKey] = useState<APIKey>();

  const onDelete = (key_name: string) => {
    const promise = mutationDelete.mutateAsync({
      key_name,
    });

    toast.promise(
      promise,
      {
        loading: 'Deleting API key',
        success: (data) => data.Message!,
        error: (err) => `${getErrorMessage(err)}`,
      },
      {}
    );
  };

  if (listAPIKey.isLoading || listUser.isLoading) {
    return <LoadingIndicator />;
  }

  if (listAPIKey.isError || listUser.isError) {
    return <ErrorDisplay label="Server error:" error={listAPIKey.error || listUser.error} />;
  }

  const onAdd = () => {
    setShowDialog(true);
  };

  const keys = listAPIKey.data?.Result || [];
  const users = listUser.data || [];
  return (
    <div>
      {keys.length > 0 ? (
        <>
          <div
            className={css({
              display: 'flex',
              justifyContent: 'flex-end',
            })}
          >
            <Button size="large" startEnhancer={<Plus size={20} />} onClick={onAdd}>
              Create API keys
            </Button>
          </div>
          <TableBuilder data={keys}>
            <TableBuilderColumn header="Description" id="description">
              {(row) => row.description}
            </TableBuilderColumn>
            <TableBuilderColumn header="API Key" id="key">
              {(row: APIKey) => row.redacted_key.slice(0, 5) + row.redacted_key.slice(-5)}
            </TableBuilderColumn>
            <TableBuilderColumn header="Key ID" id="key_id">
              {(row: APIKey) => row.key_name}
            </TableBuilderColumn>
            <TableBuilderColumn header="Role" id="Role">
              {(row: APIKey) => {
                const user = users.find((user) => user.userId === row.user_id);
                return <RoleName roles={user?.roles || []} />;
              }}
            </TableBuilderColumn>
            <TableBuilderColumn header="Created by" id="creator">
              {(row) => row.creator}
            </TableBuilderColumn>
            <TableBuilderColumn header="Expire date" id="expire">
              {(row: APIKey) => {
                if (!row.expires_at) {
                  return 'Never';
                }
                return format(parseDate(row.expires_at), 'yyyy-MM-dd HH:mm:ss');
              }}
            </TableBuilderColumn>
            <TableBuilderColumn overrides={actionColumnOverrides} header="Actions">
              {(row: APIKey) => (
                <div
                  className={css({
                    display: 'flex',
                    gap: '4px',
                    alignItems: 'center',
                  })}
                >
                  <Button
                    kind="text"
                    shape="square"
                    onClick={() => {
                      setAPIKey(row);
                      setShowDialog(true);
                    }}
                  >
                    <Eye size={16} />
                  </Button>
                  <ConfirmStatefulPopover
                    confirmLabel={`Are you sure you want to delete this key?`}
                    onConfirm={() => onDelete(row.key_name)}
                  >
                    <Button kind="text" shape="square">
                      <Trash2Icon size={16} />
                    </Button>
                  </ConfirmStatefulPopover>
                </div>
              )}
            </TableBuilderColumn>
          </TableBuilder>
        </>
      ) : (
        <EmptyState onClick={onAdd} />
      )}
      {showDialog ? (
        <APIKeyDialog
          isOpen={showDialog}
          onClose={() => {
            setShowDialog(false);
            setAPIKey(undefined);
          }}
          apiKey={apiKey}
          editUser={users.find((user) => user.userId === apiKey?.user_id)}
        />
      ) : null}
    </div>
  );
}

function EmptyState({ onClick }: { onClick: () => void }) {
  const [css, theme] = useStyletron();
  const { themeType } = useTheme();
  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
        paddingTop: '156px',
        alignItems: 'center',
      })}
    >
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          alignItems: 'center',
          justifyContent: 'center',
          color: theme.colors['text.secondary'],
        })}
      >
        <img src={themeType === 'dark' ? EmptyDarkIcon : EmptyIcon} />
        <div>{`Click “+ API keys” to configure cloud API keys.`}</div>
      </div>
      <Button size="large" startEnhancer={<Plus size={20} />} onClick={onClick}>
        Create API keys
      </Button>
    </div>
  );
}

const days = 24 * 60 * 60;
const options = [
  {
    id: 0,
    label: 'Never',
  },
  {
    id: 7 * days,
    label: '1 week',
  },
  {
    id: 14 * days,
    label: '2 weeks',
  },
  {
    id: 30 * days,
    label: '1 month',
  },
  {
    id: 6 * 30 * days,
    label: '6 months',
  },
  {
    id: 365 * days,
    label: '1 year',
  },
];

type FormValue = {
  description: string;
  life_time_in_seconds: number;
};

const placeHolderUser: User = {
  userId: 'randomID',
  email: 'placeholder@tigergraph.com',
  roles: [],
  name: '',
  created: '',
  userInfo: {
    id: 'randomID',
    email: 'placeholder@tigergraph.com',
    roles: [],
    firstName: '',
    lastName: '',
    created: '',
    username: '',
  },
};

function APIKeyDialog({
  isOpen,
  onClose,
  apiKey,
  editUser,
}: {
  isOpen: boolean;
  onClose: () => void;
  apiKey?: APIKey;
  editUser?: User;
}) {
  const mutationAdd = useMutationCreateAPIKey();

  const editMode = !!apiKey;
  console.log({
    apiKey,
    editMode,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValue>({
    defaultValues: {
      description: apiKey?.description || '',
      // default is never
      life_time_in_seconds: 0,
    },
  });

  const [css, theme] = useStyletron();
  const [hasKey, setHasKey] = useState(false);
  const [keyText, setKeyText] = useState('');

  const [apiKeyCopied, setAPIKeyCopied] = useCopyClipboard(keyText, {
    successDuration: 1000,
  });

  useEffect(() => {
    if (apiKeyCopied) {
      showToast({
        kind: 'positive',
        message: 'API key copied successfully.',
      });
    }
  }, [apiKeyCopied]);

  const modifyUserRoleMutation = useMutationAssignUserRole();
  const [diff, setDiff] = useState<TreeDiff>();

  const noRoleChanges =
    !diff ||
    (diff.orgRole === undefined && diff.groups.size === 0 && !diff.revokeBillingRole && !diff.assignBillingRole);

  const assignRole = async (userId: string, userEmail: string) => {
    if (noRoleChanges || !diff) {
      return;
    }

    const promise = modifyUserRoleMutation.mutateAsync({
      userId,
      diff,
      userEmail,
      apiUser: true,
    });
    return promise;
  };

  const onCreate = (data: FormValue) => {
    mutationAdd.mutate(data, {
      async onSuccess(data) {
        if (data && data.Result) {
          const { key_name, plaintext_key, user_id } = data.Result;
          const userEmail = `api_key_external_org/${key_name}`;

          try {
            await assignRole(user_id, userEmail);
          } catch (error) {
            showToast({
              kind: 'negative',
              message: `Fail to assign permission: ${getErrorMessage(error as Error)}.`,
            });
          }

          setHasKey(true);
          setKeyText(plaintext_key);
        } else {
          showToast({
            kind: 'negative',
            message: `Fail to get key from server.`,
          });
        }
      },
      onError(error) {
        showToast({
          kind: 'negative',
          message: `${getErrorMessage(error)}`,
        });
      },
    });
  };

  const onUpdateRole = async () => {
    if (!apiKey || !diff || noRoleChanges) {
      return;
    }

    const { key_name, user_id } = apiKey;
    const userEmail = `api_key_external_org/${key_name}`;

    modifyUserRoleMutation.mutate(
      { diff, userId: user_id, userEmail, apiUser: true },
      {
        onSuccess: async () => {
          showToast({
            kind: 'positive',
            message: 'Permission updated successfully.',
          });

          onClose();
        },
        onError: (error) => {
          showToast({
            kind: 'negative',
            message: getErrorMessage(error),
          });
        },
      }
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>{editMode ? 'Edit API key' : 'Create API key'}</ModalHeader>
      <ModalBody>
        <div
          className={css({
            marginBottom: '16px',
            fontSize: '14px',
          })}
        >{`${editMode ? 'Edit' : 'Create'} an API key to access your organization's resources`}</div>
        <form>
          {!hasKey ? (
            <>
              <FormControl label="API Key Description" error={errors?.description?.message}>
                <Controller
                  rules={{
                    required: 'required',
                  }}
                  control={control}
                  name="description"
                  render={({ field }) => (
                    <Input
                      placeholder="description"
                      {...field}
                      error={!!errors?.description}
                      disabled={hasKey || editMode}
                      autoComplete="off"
                    />
                  )}
                />
              </FormControl>
              <FormControl label="Organization Permissions">
                <div
                  className={css({
                    overflowY: 'auto',
                    maxHeight: '220px',
                    padding: '10px',
                    borderRadius: '4px',
                    backgroundColor: theme.colors['background.secondary'],
                  })}
                >
                  <UserRoleForm user={editUser || placeHolderUser} onFormChanged={setDiff} hideBilling={true} />
                </div>
              </FormControl>
              {!editMode ? (
                <FormControl label="Expire">
                  <Controller
                    rules={{
                      required: 'required',
                    }}
                    control={control}
                    name="life_time_in_seconds"
                    render={({ field: { value, onChange, ref, ...field } }) => {
                      return (
                        <Select
                          options={options}
                          value={[{ id: value }]}
                          onChange={(params) => {
                            onChange(params.value[0].id);
                          }}
                          clearable={false}
                          inputRef={ref}
                          {...field}
                          searchable={false}
                          disabled={hasKey || editMode}
                        />
                      );
                    }}
                  />
                </FormControl>
              ) : null}
            </>
          ) : (
            <>
              <FormControl label="API Key" error={errors?.description?.message}>
                <div
                  className={css({
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                  })}
                >
                  <Input readOnly={hasKey} autoComplete="off" value={keyText} disabled={true} />
                  <Button kind="text" type="button" onClick={setAPIKeyCopied}>
                    <CopyIcon size={18} />
                  </Button>
                </div>
              </FormControl>
              <StyledToast
                kind="info"
                hideBorder={true}
                title="Please save this now as you won't be able to view it again."
                closeable={false}
              />
            </>
          )}
        </form>
      </ModalBody>
      <ModalFooter>
        {!hasKey ? (
          <>
            <ModalButton type="button" onClick={onClose} kind="secondary">
              Cancel
            </ModalButton>
            <ModalButton
              type="button"
              isLoading={mutationAdd.isLoading || modifyUserRoleMutation.isLoading}
              disabled={mutationAdd.isLoading || modifyUserRoleMutation.isLoading || (editMode && noRoleChanges)}
              onClick={
                editMode
                  ? onUpdateRole
                  : handleSubmit((data) => {
                      onCreate(data);
                    })
              }
            >
              Create
            </ModalButton>
          </>
        ) : (
          <ModalButton type="button" onClick={onClose} kind="secondary">
            Close
          </ModalButton>
        )}
      </ModalFooter>
    </Modal>
  );
}
