import { useOrgContext } from '@/contexts/orgContext';
import { cn } from '@/lib/utils';
import { isGroupAdmin, isOrgAdmin, isSpaceAdmin, isSpaceUser } from '@/pages/admin/user/type';
import { Tag } from '@tigergraph/app-ui-lib/tag';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { useEffect, useState } from 'react';
import { useQueryGetGroups } from '@/pages/workgroup/hook';
import { StatefulTipsPopover } from '@/components/tipsPopover';
import { PLACEMENT } from 'baseui/popover';

type Permission = {
  name: string;
  tooltips?: string;
};

const getUserName = (email: string) => email.split('@')[0];

export default function UserInfo() {
  const { userInfo } = useOrgContext();
  const [css, theme] = useStyletron();
  const [expand, setExpand] = useState(false);
  const orgAdmin = isOrgAdmin(userInfo.roles);
  const [permissions, setPermissions] = useState<Permission[]>([]);

  const { data: groups } = useQueryGetGroups({
    enabled: !orgAdmin,
  });

  useEffect(() => {
    if (orgAdmin) {
      setPermissions([{ name: 'Organization Admin' }]);
      return;
    }

    const permissions: Permission[] = [];
    // workgroup admin
    const adminGroupList =
      groups?.Result?.filter((g) => isGroupAdmin(userInfo.roles, g.workgroup_id)).map((g) => g.name) || [];
    if (adminGroupList.length > 0) {
      permissions.push({
        name: 'Workgroup Admin',
        tooltips: adminGroupList.join(', '),
      });
    }

    const workspaceList = groups?.Result?.map((g) => g.workspaces).flat() || [];

    // workspace admin
    const adminWorkspaceList = workspaceList.filter((w) =>
      isSpaceAdmin(userInfo.roles, w.workgroup_id, w.workspace_id)
    );
    if (adminWorkspaceList.length > 0) {
      permissions.push({
        name: 'Workspace Admin',
        tooltips: adminWorkspaceList.map((w) => `${w.workgroup_name}/${w.name}`).join(', '),
      });
    }
    // workspace member
    const memberWorkspaceList = workspaceList.filter((w) =>
      isSpaceUser(userInfo.roles, w.workgroup_id, w.workspace_id)
    );
    if (memberWorkspaceList.length > 0) {
      permissions.push({
        name: 'Workspace Member',
        tooltips: memberWorkspaceList.map((w) => `${w.workgroup_name}/${w.name}`).join(', '),
      });
    }

    setPermissions(permissions);

    if (permissions.length <= 0) {
      setPermissions([{ name: 'Organization Member' }]);
    }
  }, [userInfo.roles, orgAdmin, groups]);

  return (
    <div className={cn('p-2 border-b', css({ borderColor: theme.colors['dropdown.border'] }))}>
      <h3 className={cn('text-sm leading-4', css({ color: theme.colors['dropdown.text'] }))}>
        {getUserName(userInfo.email)}
      </h3>
      <p className={cn('pb-1 text-xs leading-4', css({ color: theme.colors['dropdown.text.secondary'] }))}>
        {userInfo.email}
      </p>
      {permissions.length > 0 && (
        <div className="pb-1 space-y-1">
          {permissions.length > 0 &&
            (expand ? permissions : permissions.slice(0, 1)).map((t) => (
              <p key={t.name}>
                {t.tooltips ? (
                  <StatefulTipsPopover
                    triggerType="hover"
                    placement={PLACEMENT.right}
                    content={<p className="max-w-[400px]">{t.tooltips}</p>}
                  >
                    <span>
                      <Tag closeable={false}>{t.name}</Tag>
                    </span>
                  </StatefulTipsPopover>
                ) : (
                  <Tag closeable={false}>{t.name}</Tag>
                )}
                {!expand && permissions.length > 1 && (
                  <Tag
                    onClick={() => setExpand(true)}
                    closeable={false}
                    overrides={{
                      Root: {
                        style: {
                          marginLeft: '4px',
                          cursor: 'pointer',
                        },
                      },
                    }}
                  >
                    +{permissions.length - 1}
                  </Tag>
                )}
              </p>
            ))}
        </div>
      )}
    </div>
  );
}
