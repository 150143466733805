import { useIsOrgCanUseCopilot } from './useIsOrgCanUseCopilot';
import { axiosController } from '@/lib/network';
import { UseQueryOptions, useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { Result } from '@/lib/type';
import suggestAddonsDarkIcon from '@/assets/suggest-addons-dark.svg';

export type MetaAddonsType = {
  ID: string;
  Background: string;
  Avatar: string;
  AvatarDark?: string;
  Title: string;
  Description: string;
  Amount?: string;
  IsOfficial?: boolean;
  Categories?: string[];
  BannerImageList?: string[];
  Announcement?: string[];
  WorkspaceAvatar?: string;
  Subtitle?: string;
};

export type UseAddonsMetaReturnType = Result<{
  AddonsList: MetaAddonsType[];
  RequestAddons: MetaAddonsType[];
}>;

export const useAddonsMeta = <T = UseAddonsMetaReturnType['Result']>(
  opt?: UseQueryOptions<UseAddonsMetaReturnType['Result'], AxiosError, T, string[]>
) => {
  const isOrgCanUseCopilot = useIsOrgCanUseCopilot();
  return useQuery({
    ...opt,
    queryKey: ['addons', 'meta'],
    queryFn: async () => {
      const res = await axiosController.get<UseAddonsMetaReturnType>('/v2/addons/meta');
      const data = res.data.Result;

      // for suggest addons, its avatar has another avatar in dark mode
      const suggestAddons = data?.RequestAddons.find((i) => i.ID === 'Suggest Add-on');
      if (suggestAddons) {
        suggestAddons.AvatarDark = suggestAddonsDarkIcon;
      }

      return {
        AddonsList: data?.AddonsList || [],
        RequestAddons: data?.RequestAddons || [],
      };
    },
  });
};
