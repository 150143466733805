import { axiosController } from '@/lib/network';
import { CreditCard } from '@/pages/admin/bill/models';

export type CreditCardResult = {
  default_card: string | null;
  credit_cards: CreditCard[] | null;
};
export async function getCreditCards(): Promise<CreditCardResult> {
  const response = await axiosController.get('/v2/billing/credit-cards');
  return response.data.Result;
}

export async function setDefaultCard(cardId: string) {
  const response = await axiosController.post('/v2/billing/credit-cards/default/' + cardId);
  return response.data.Result;
}

export async function deleteCard(cardId: string) {
  const response = await axiosController.delete('/v2/billing/credit-cards/' + cardId);
  return response.data.Result;
}

export async function getSessionToken(): Promise<string> {
  const response = await axiosController.post('/v2/billing/session');
  return response.data.Result;
}
