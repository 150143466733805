import { useEffect, useMemo, useState } from 'react';
import { Drawer, DrawerAction, DrawerBody, DrawerHeader } from '@/components/Drawer';
import { showToast } from '@/components/styledToasterContainer';
import { UserInfo, UserRole } from '@/lib/models';
import { getGroupAdmins, getOrgAdmins, useMutationAssignGroupAdmin, useQueryListUsers } from '@/pages/admin/user/hook';
import { WorkGroupT } from '@/pages/workgroup/type';
import { getErrorMessage } from '@/utils/utils';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Button } from '@tigergraph/app-ui-lib/button';
import { FormControl } from '@tigergraph/app-ui-lib/form-control';
import { StatefulPopover } from '@tigergraph/app-ui-lib/popover';
import { Select } from '@tigergraph/app-ui-lib/select';

import { TRIGGER_TYPE } from 'baseui/popover';
import { Value } from 'baseui/select';

import { getDropdownUserList, filterOptions, diffGroupAdminsList, UserDropDownItem, GroupAdminItem } from './common';

import InfoIcon from '@/assets/info.svg?react';
import UserIcon from '@/assets/user.svg?react';
import { GroupIconXL } from '@/pages/home/icons';

export type Props = {
  isOpen: boolean;
  onClose: () => void;
  group: WorkGroupT;
};

export default function GroupAdminDetail({ isOpen, onClose, group }: Props) {
  const [css] = useStyletron();

  const { data: allUser = [], isFetching } = useQueryListUsers();
  const orgAdmins = getOrgAdmins(allUser).sort((a, b) => a.email.localeCompare(b.email));
  const adminsBefore = getGroupAdmins(allUser, group.workgroup_id);
  const [adminsAfter, setAdminsAfter] = useState<UserInfo[]>(adminsBefore);
  const [isReady, setIsReady] = useState(false);
  const [selectValue, setSelectValue] = useState<Value>([]);

  const assignGroupAdminMutation = useMutationAssignGroupAdmin();

  useEffect(() => {
    if (allUser.length > 0 && !isReady && !isFetching) {
      setAdminsAfter(adminsBefore);
      setIsReady(true);
    }
  }, [allUser, adminsAfter, adminsBefore, isReady, isFetching]);

  function addUser(user: UserInfo) {
    setAdminsAfter([user, ...adminsAfter]);
    setSelectValue([]);
  }

  function removeUser(user: UserInfo) {
    const index = adminsAfter.findIndex((item) => item.email === user.email);
    setAdminsAfter(adminsAfter.slice(0, index).concat(adminsAfter.slice(index + 1)));
  }

  const dropdownUserList = useMemo(() => {
    return getDropdownUserList(allUser, adminsAfter);
  }, [allUser, adminsAfter]);

  const { assignUserEmails, unAssignUserEmails } = diffGroupAdminsList(adminsBefore, adminsAfter);

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <DrawerHeader>
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            fontSize: '18px',
            fontWeight: '600',
          })}
        >
          <GroupIconXL /> {group.name}
        </div>
      </DrawerHeader>
      <DrawerBody
        $style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            fontSize: '14px',
            fontWeight: '600',
          })}
        >
          <UserIcon />
          <span>Add new admins to {group.name}</span>
          <StatefulPopover triggerType={TRIGGER_TYPE.hover} content="You can add administrators to your workgroup.">
            <Button size="compact" kind="text" shape="square">
              <InfoIcon />
            </Button>
          </StatefulPopover>
        </div>
        <div
          className={css({
            display: 'flex',
            alignItems: 'flex-start',
            gap: '20px',
          })}
        >
          <FormControl
            overrides={{
              ControlContainer: {
                style: {
                  marginBottom: '0px',
                },
              },
            }}
          >
            <Select
              isLoading={isFetching}
              disabled={isFetching}
              type="search"
              placeholder="Search user"
              options={dropdownUserList}
              value={selectValue}
              maxDropdownHeight={'400px'}
              labelKey="email"
              filterOptions={filterOptions}
              onChange={(params) => {
                setSelectValue(params.value);
                addUser({
                  ...params.value[0].userInfo,
                  roles: [
                    {
                      name: 'workgroup-admins',
                      displayName: `${group.name} Admin`,
                      path: `/${group.workgroup_id}/workgroup-admins`,
                    } as UserRole,
                  ],
                });
              }}
              overrides={{
                Dropdown: {
                  style: {
                    marginTop: '8px',
                  },
                },
                DropdownListItem: {
                  component: UserDropDownItem,
                },
              }}
            />
          </FormControl>
        </div>
        {!isFetching ? (
          <div
            className={css({
              display: 'flex',
              flexDirection: 'column',
              gap: '4px',
              overflowY: 'auto',
              flexBasis: 0,
              flexGrow: 1,
              minHeight: 0,
            })}
          >
            {adminsAfter.concat(orgAdmins).map((user) => (
              <GroupAdminItem key={user.email} user={user} onDelete={() => removeUser(user)} />
            ))}
          </div>
        ) : null}
      </DrawerBody>
      <DrawerAction>
        <Button type="button" onClick={onClose} kind="secondary" size="large">
          Cancel
        </Button>
        <Button
          type="button"
          size="large"
          disabled={assignUserEmails.length + unAssignUserEmails.length === 0}
          onClick={async () => {
            // todo(lin): we should manipulate cache here
            assignGroupAdminMutation.mutate(
              { groupID: group.workgroup_id, unAssignUserEmails, assignUserEmails },
              {
                onSuccess: async () => {
                  showToast({
                    kind: 'positive',
                    message: 'Saved successfully.',
                  });

                  onClose();
                },
                onError: (error) => {
                  showToast({
                    kind: 'negative',
                    message: getErrorMessage(error),
                  });
                },
              }
            );
          }}
          isLoading={assignGroupAdminMutation.isLoading}
        >
          Save
        </Button>
      </DrawerAction>
    </Drawer>
  );
}
