import Empty from '../../assets/empty-schema.svg';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { ShortcutsMenu } from '@tigergraph/app-ui-lib/shortcuts-menu';
import { buildShortcut } from '@/utils/schemaDesigner';
import { Button } from '@tigergraph/app-ui-lib/button';
import { Plus } from 'baseui/icon';
import { Label } from '@tigergraph/app-ui-lib/typography';
import { useAtom } from 'jotai';
import { isCreatingGraphAtom } from '@/components/graphSelect/GraphSelect';

export function EmptySchema({
  isLoading,
  enableSave,
  showCreateNewGraph,
}: {
  isLoading?: boolean;
  enableSave?: boolean;
  showCreateNewGraph?: boolean;
}) {
  const [css, theme] = useStyletron();
  const [_, setIsCreatingGraph] = useAtom(isCreatingGraphAtom);

  return (
    <div
      className={css({
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      })}
    >
      {isLoading ? (
        <img
          src={Empty}
          style={{
            display: 'inline-block',
            width: '596px',
          }}
        />
      ) : (
        <>
          {showCreateNewGraph ? (
            <>
              <div
                className={css({
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                  alignItems: 'center',
                })}
              >
                <Label
                  className={css({
                    color: theme.colors['text.secondary'],
                  })}
                >
                  You have not created a graph yet, click on the “Create New Graph” button to create a graph
                </Label>
                <Button
                  kind="secondary"
                  startEnhancer={<Plus />}
                  onClick={() => {
                    setIsCreatingGraph(true);
                  }}
                  overrides={{
                    BaseButton: {
                      style: {
                        pointerEvents: 'auto',
                      },
                    },
                  }}
                >
                  Create New Graph
                </Button>
              </div>
              <div
                className={css({
                  marginTop: '36px',
                  marginBottom: '36px',
                  height: '1px',
                  backgroundColor: theme.colors['border.tertiary'],
                  width: '100%',
                })}
              />
            </>
          ) : null}
          <div
            className={css({
              opacity: 0.5,
            })}
          >
            <ShortcutsMenu
              shortcuts={buildShortcut({ enableSave: !!enableSave })}
              showTitle={false}
              center
              columnWidth={280}
              column={2}
            />
          </div>
        </>
      )}
    </div>
  );
}
