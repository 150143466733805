import { ExternalTokenizer } from '@lezer/lr';
import {
  whitespace,
  LineComment,
  BlockComment,
  String as StringToken,
  Number,
  ParenR,
  BraceL,
  BraceR,
  BracketL,
  BracketR,
  Dot,
  Identifier,
  GRAPH,
  PRINT,
  LOG,
  RETURNS,
  EXCEPTION,
  SELECT,
  FROM,
  WHERE,
  SAMPLE,
  WHEN,
  ACCUM,
  POST_ACCUM,
  HAVING,
  ASC,
  DESC,
  LIMIT,
  DELETE,
  INSERT,
  UPDATE,
  IN,
  RANGE,
  TYPEDEF,
  TUPLE,
  OR,
  ANY,
  API,
  AS,
  BY,
  DISTINCT,
  INTO,
  ISEMPTY,
  LOADACCUM,
  PER,
  PINNED,
  TARGET,
  FOR,
  GROUP,
  IS,
  MAP,
  ORDER,
  SYNTAX,
  GSQL_UINT_MAX,
  GSQL_INT_MAX,
  GSQL_INT_MIN,
  TO_DATETIME,
  INT,
  UINT,
  FLOAT,
  DOUBLE,
  STRING,
  BOOL,
  VERTEX,
  EDGE,
  NOT,
  JSONOBJECT,
  JSONARRAY,
  SET,
  BAG,
  FILE,
  FILTER,
  DATETIME,
  SumAccum,
  MaxAccum,
  MinAccum,
  AvgAccum,
  OrAccum,
  AndAccum,
  BitwiseOrAccum,
  BitwiseAndAccum,
  ListAccum,
  SetAccum,
  BagAccum,
  MapAccum,
  HeapAccum,
  GroupByAccum,
  ArrayAccum,
  TRUE,
  FALSE,
  NULL,
  COUNT,
  MAX,
  MIN,
  AVG,
  SUM,
  ParenL,
  CURRENT_DATE,
  CURRENT_TIME,
  CURRENT_TIMESTAMP,
  IF,
  THEN,
  ELSE,
  WHILE,
  DO,
  FOREACH,
  END,
  CASE,
  CONTINUE,
  BREAK,
  TRY,
  PRIMARY_ID,
  VALUES,
  RAISE,
  RETURN,
  AND,
  LIKE,
  INTERSECT,
  UNION,
  MINUS,
  BETWEEN,
  ESCAPE,
} from './query.grammar.terms';
import { inString, readLiteral, readNumber, eol, Ch, isAlpha, isHexDigit, readWord } from '@/pages/editor/GSQL/util';

const Space = ' \t\r\n';

const ignoreCaseKwMap: Record<string, number> = {
  ESCAPE: ESCAPE,
  BETWEEN: BETWEEN,
  LIKE: LIKE,
  INTERSECT: INTERSECT,
  UNION: UNION,
  MINUS: MINUS,
  NOT: NOT,
  AND: AND,
  PRIMARY_ID: PRIMARY_ID,
  FILTER: FILTER,
  VALUES: VALUES,
  TRUE: TRUE,
  FALSE: FALSE,
  NULL: NULL,
  // keywords
  GRAPH: GRAPH,
  PRINT: PRINT,
  LOG: LOG,
  RETURNS: RETURNS,
  EXCEPTION: EXCEPTION,
  SELECT: SELECT,
  FROM: FROM,
  WHERE: WHERE,
  SAMPLE: SAMPLE,
  WHEN: WHEN,
  ACCUM: ACCUM,
  'POST-ACCUM': POST_ACCUM,
  HAVING: HAVING,
  ASC: ASC,
  DESC: DESC,
  LIMIT: LIMIT,
  DELETE: DELETE,
  INSERT: INSERT,
  UPDATE: UPDATE,
  IN: IN,
  RANGE: RANGE,
  TYPEDEF: TYPEDEF,
  TUPLE: TUPLE,
  OR: OR,
  ANY: ANY,
  API: API,
  AS: AS,
  BY: BY,
  DISTINCT: DISTINCT,
  INTO: INTO,
  ISEMPTY: ISEMPTY,
  LOADACCUM: LOADACCUM,
  PER: PER,
  PINNED: PINNED,
  POST_ACCUM: POST_ACCUM,
  TARGET: TARGET,
  FOR: FOR,
  GROUP: GROUP,
  IS: IS,
  MAP: MAP,
  ORDER: ORDER,
  SYNTAX: SYNTAX,
  GSQL_UINT_MAX: GSQL_UINT_MAX,
  GSQL_INT_MAX: GSQL_INT_MAX,
  GSQL_INT_MIN: GSQL_INT_MIN,
  TO_DATETIME: TO_DATETIME,
  // control keywords
  IF: IF,
  THEN: THEN,
  ELSE: ELSE,
  WHILE: WHILE,
  DO: DO,
  FOREACH: FOREACH,
  END: END,
  CASE: CASE,
  CONTINUE: CONTINUE,
  BREAK: BREAK,
  TRY: TRY,
  RAISE: RAISE,
  RETURN: RETURN,
  // types
  INT: INT,
  UINT: UINT,
  FLOAT: FLOAT,
  DOUBLE: DOUBLE,
  STRING: STRING,
  BOOL: BOOL,
  VERTEX: VERTEX,
  EDGE: EDGE,
  JSONOBJECT: JSONOBJECT,
  JSONARRAY: JSONARRAY,
  SET: SET,
  BAG: BAG,
  FILE: FILE,
  DATETIME: DATETIME,
  COUNT: COUNT,
  MAX: MAX,
  MIN: MIN,
  AVG: AVG,
  SUM: SUM,
  // date and time
  CURRENT_DATE: CURRENT_DATE,
  CURRENT_TIME: CURRENT_TIME,
  CURRENT_TIMESTAMP: CURRENT_TIMESTAMP,
};

const keywordMap: Record<string, number> = {
  SumAccum: SumAccum,
  MaxAccum: MaxAccum,
  MinAccum: MinAccum,
  AvgAccum: AvgAccum,
  OrAccum: OrAccum,
  AndAccum: AndAccum,
  BitwiseOrAccum: BitwiseOrAccum,
  BitwiseAndAccum: BitwiseAndAccum,
  ListAccum: ListAccum,
  SetAccum: SetAccum,
  BagAccum: BagAccum,
  MapAccum: MapAccum,
  HeapAccum: HeapAccum,
  GroupByAccum: GroupByAccum,
  ArrayAccum: ArrayAccum,
};

export function keywords(name: string) {
  let found = ignoreCaseKwMap[name.toUpperCase()];
  if (found) return found;

  found = keywordMap[name];
  if (found) return found;

  return -1;
}

export function tokensFor() {
  return new ExternalTokenizer((input) => {
    const next = input.next;
    input.advance();
    if (inString(next, Space)) {
      while (inString(input.next, Space)) input.advance();
      input.acceptToken(whitespace);
    } else if (next == Ch.DoubleQuote) {
      readLiteral(input, next, true);
      input.acceptToken(StringToken);
    } else if (next == Ch.Hash || (next == Ch.Slash && input.next == Ch.Slash)) {
      eol(input);
      input.acceptToken(LineComment);
    } else if (next == Ch.Slash && input.next == Ch.Star) {
      input.advance();
      for (let depth = 1; ; ) {
        let cur: number = input.next;
        if (input.next < 0) break;
        input.advance();
        if (cur == Ch.Star && (input as any).next == Ch.Slash) {
          depth--;
          input.advance();
          if (!depth) break;
        } else if (cur == Ch.Slash && input.next == Ch.Star) {
          depth++;
          input.advance();
        }
      }
      input.acceptToken(BlockComment);
    } else if ((next == Ch.e || next == Ch.E) && input.next == Ch.SingleQuote) {
      input.advance();
      readLiteral(input, Ch.SingleQuote, true);
      input.acceptToken(StringToken);
    } else if (next == Ch.ParenL) {
      input.acceptToken(ParenL);
    } else if (next == Ch.ParenR) {
      input.acceptToken(ParenR);
    } else if (next == Ch.BraceL) {
      input.acceptToken(BraceL);
    } else if (next == Ch.BraceR) {
      input.acceptToken(BraceR);
    } else if (next == Ch.BracketL) {
      input.acceptToken(BracketL);
    } else if (next == Ch.BracketR) {
      input.acceptToken(BracketR);
    } else if (
      (next == Ch._0 && (input.next == Ch.x || input.next == Ch.X)) ||
      ((next == Ch.x || next == Ch.X) && input.next == Ch.SingleQuote)
    ) {
      let quoted = input.next == Ch.SingleQuote;
      input.advance();
      while (isHexDigit(input.next)) input.advance();
      if (quoted && input.next == Ch.SingleQuote) input.advance();
      input.acceptToken(Number);
    } else if (next == Ch.Dot && input.next >= Ch._0 && input.next <= Ch._9) {
      readNumber(input, true);
      input.acceptToken(Number);
    } else if (next == Ch.Dot) {
      input.acceptToken(Dot);
    } else if (next >= Ch._0 && next <= Ch._9) {
      readNumber(input, false);
      input.acceptToken(Number);
    } else if (isAlpha(next) || next == Ch.Underscore) {
      const word = readWord(input, String.fromCharCode(next));
      // handle the case of "POST-ACCUM"
      if (word == 'POST' && input.next == Ch.Dash) {
        input.advance();
        readWord(input);
      }
      input.acceptToken(Identifier);
    }
  });
}

export const tokens = tokensFor();
