import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import FallbackIcon from './icon/fallback.svg';
import FallbackDarkIcon from './icon/fallback-dark.svg';
import { styled } from '@tigergraph/app-ui-lib/Theme';
import { RotateCw } from 'lucide-react';
// import { useRouteError } from 'react-router-dom';
import { Button } from '@tigergraph/app-ui-lib/button';

export const Label = styled('div', ({ $theme }) => ({
  color: $theme.colors['text.primary'],
}));

export default function Fallback() {
  // const error = useRouteError() as any;

  const [css, theme] = useStyletron();

  return (
    <div
      className={css({
        backgroundColor: theme.colors['background.primary'],
      })}
    >
      <div
        className={css({
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '24px',
          maxWidth: '498px',
          margin: '0 auto',
        })}
      >
        <img src={theme.name === 'dark' ? FallbackDarkIcon : FallbackIcon} alt="fallback" />
        <div
          className={css({
            textAlign: 'center',
          })}
        >
          <h1
            className={css({
              fontSize: '18px',
              fontWeight: 600,
              lineHeight: '32px',
              fontFamily: 'Urbanist',
              marginBottom: '12px',
            })}
          >
            Oops! Something went wrong
          </h1>
          <div
            className={css({
              color: theme.colors['text.secondary'],
              marginBottom: '4px',
              lineHeight: '16px',
              fontSize: '14px',
              textAlign: 'left',
            })}
          >
            An unexpected error has occurred. You can try reloading the webpage to see if that resolves the issue.
            {/* {'message' in error ? `Error detail: ${error.message}` : ''} */}
          </div>
        </div>
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          })}
        >
          <Button
            startEnhancer={<RotateCw size={16} />}
            kind="primary"
            size="large"
            onClick={() => {
              window.location.reload();
            }}
          >
            Reload
          </Button>
        </div>
      </div>
    </div>
  );
}
