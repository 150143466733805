import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { SVGProps } from 'react';

export const AccessBlocked = (props: SVGProps<SVGSVGElement>) => {
  const [, theme] = useStyletron();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
      <path
        fill={theme.colors['modal.icon']}
        fillRule="evenodd"
        d="M2.793 2.98 7.46.907c.34-.154.74-.154 1.08 0l4.667 2.073c.48.213.793.693.793 1.22v3.133c0 .438-.036.871-.104 1.298a4.651 4.651 0 0 0-3.23-1.298c-.874 0-1.693.241-2.393.66H8V2.127L3.333 4.2V8H8v.17A4.661 4.661 0 0 0 6 12c0 1.174.433 2.246 1.148 3.066C4.146 13.907 2 10.721 2 7.333V4.2c0-.527.313-1.007.793-1.22Z"
        clipRule="evenodd"
      />
      <g clipPath="url(#a)">
        <path
          fill={theme.colors['icon.danger']}
          stroke={theme.colors['icon.danger']}
          strokeWidth={0.5}
          d="M10.667 8.667a3.335 3.335 0 0 0 0 6.666A3.335 3.335 0 0 0 14 12a3.335 3.335 0 0 0-3.333-3.333Zm0 6A2.666 2.666 0 0 1 8 12c0-.617.21-1.183.563-1.633l3.737 3.736a2.634 2.634 0 0 1-1.633.564Zm2.103-1.034L9.034 9.897a2.634 2.634 0 0 1 1.633-.564A2.666 2.666 0 0 1 13.334 12c0 .617-.21 1.183-.564 1.633Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M6.667 8h8v8h-8z" />
        </clipPath>
      </defs>
    </svg>
  );
};
