import { ParagraphXSmall } from 'baseui/typography';
import { ChevronRight } from 'baseui/icon';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Tutorial } from '@/components/tutorial/type.ts';
import { Tutorials } from '@/components/tutorial/mock.tsx';
import { Header } from '@/components/tutorial/style.tsx';

export interface TutorialGroupProps {
  onSelectTutorial: (tutorial: Tutorial) => void;
}

export function TutorialGroup({ onSelectTutorial }: TutorialGroupProps) {
  const [css, theme] = useStyletron();

  const tutorials: Tutorial[] = Tutorials;

  return (
    <>
      <div>
        <Header>Select a tutorial below</Header>
        <ParagraphXSmall
          className={css({
            color: theme.colors.contentSecondary,
            lineHeight: '16px !important',
            marginBottom: '12px',
          })}
        >
          Discover a collection of tutorial videos designed to provide step-by-step guidance for you. These tutorials
          will help you quickly learn the features on TigerGraph Cloud.
        </ParagraphXSmall>
      </div>
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        })}
      >
        {tutorials.map((item) => {
          return (
            <div
              onClick={() => {
                onSelectTutorial(item);
              }}
              key={item.title}
              className={css({
                display: 'flex',
                cursor: 'pointer',
                alignItems: 'center',
                fontSize: '12px',
                fontWeight: 600,
                justifyContent: 'space-between',
                padding: '8px 16px',
                borderRadius: '2px',
                border: `1px solid ${theme.colors['border.tertiary']}`,
                backgroundColor: theme.colors['background.alternative'],
              })}
            >
              <div
                className={css({
                  display: 'flex',
                  gap: '10px',
                })}
              >
                {item.icon}
                {item.title}
              </div>
              <ChevronRight size={24} />
            </div>
          );
        })}
      </div>
    </>
  );
}
