import { Avatar, formatName } from '@/components/Avatar';
import { Drawer, DrawerAction, DrawerBody, DrawerHeader } from '@/components/Drawer';
import { showToast } from '@/components/styledToasterContainer';
import { TreeDiff, useMutationAssignUserRole } from '@/pages/admin/user/hook';
import { User } from '@/pages/admin/user/type';
import { getErrorMessage } from '@/utils/utils';
import { styled, useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Button } from '@tigergraph/app-ui-lib/button';
import { HeadingMenu, Label } from '@tigergraph/app-ui-lib/typography';
import { format } from 'date-fns';
import { UserXL } from '../../home/icons';
import { DrawerHeadContainer } from '@/lib/styled.tsx';
import { parseDate } from '@/lib/date';
import UserRoleForm from '@/pages/admin/user/user_role_form';
import { useState } from 'react';

export type Props = {
  isOpen: boolean;
  onClose: () => void;
  user: User;
};

export default function UserDetail({ isOpen, onClose, user }: Props) {
  const [css, theme] = useStyletron();

  const modifyUserRoleMutation = useMutationAssignUserRole();
  const [diff, setDiff] = useState<TreeDiff>();

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <DrawerHeader>
        <DrawerHeadContainer>
          <UserXL />
          User Detail
        </DrawerHeadContainer>
      </DrawerHeader>
      <DrawerBody
        $style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          className={css({
            display: 'flex',
            gap: '8px',
          })}
        >
          <Avatar size="large">{formatName(user.userInfo)}</Avatar>
          <div>
            <div>
              <HeadingMenu $style={{ fontWeight: '600' }}>{user.name}</HeadingMenu>
            </div>
            <Label $style={{ color: theme.colors['text.tertiary'], marginTop: '8px', marginBottom: '4px' }}>
              Email: <StyledLabel>{user.email}</StyledLabel>
            </Label>
            <Label $style={{ color: theme.colors['text.tertiary'] }}>
              Last login:{' '}
              <StyledLabel>
                {user.lastActive ? format(parseDate(user.lastActive), 'yyyy-MM-dd HH:mm:ss') : 'N/A'}
              </StyledLabel>
            </Label>
          </div>
        </div>
        <StyledTitle>Permissions</StyledTitle>
        <div
          className={css({
            overflowY: 'auto',
            flexBasis: 0,
            flexGrow: 1,
            minHeight: 0,
          })}
        >
          <UserRoleForm user={user} onFormChanged={setDiff} />
        </div>
      </DrawerBody>
      <DrawerAction>
        <Button type="button" onClick={onClose} kind="secondary" size="large">
          Cancel
        </Button>
        <Button
          size="large"
          disabled={
            !diff ||
            (diff.orgRole === undefined && diff.groups.size === 0 && !diff.revokeBillingRole && !diff.assignBillingRole)
          }
          type="button"
          onClick={async () => {
            if (!diff) {
              return;
            }
            modifyUserRoleMutation.mutate(
              { diff, userId: user.userId, userEmail: user.email },
              {
                onSuccess: async () => {
                  showToast({
                    kind: 'positive',
                    message: 'User updated successfully.',
                  });

                  onClose();
                },
                onError: (error) => {
                  showToast({
                    kind: 'negative',
                    message: getErrorMessage(error),
                  });
                },
              }
            );
          }}
          isLoading={modifyUserRoleMutation.isLoading}
        >
          Save
        </Button>
      </DrawerAction>
    </Drawer>
  );
}

export const StyledLabel = styled('span', ({ $theme }) => {
  return {
    color: $theme.colors['text.secondary'],
  };
});

export const StyledTitle = styled('div', {
  fontSize: '16px',
  fontWeight: '600',
  lineHeight: '24px',
  marginTop: '16px',
});
