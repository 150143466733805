import { FormControl } from '@/components/FormControl';
import { useOrgContext } from '@/contexts/orgContext';
import { cn } from '@/lib/utils';
import { calculateRoleForGroup, isOrgAdmin } from '@/pages/admin/user/type';
import { useQueryGetGroups } from '@/pages/workgroup/hook';
import { Button } from '@tigergraph/app-ui-lib/button';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@tigergraph/app-ui-lib/modal';
import { Radio } from '@tigergraph/app-ui-lib/radio';
import { Select } from '@tigergraph/app-ui-lib/select';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { ModalProps } from 'baseui/modal';
import { RadioGroup } from 'baseui/radio';
import { Option } from 'baseui/select';
import { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

type CreateWorkspaceFormType = {
  type: 'new' | 'attach';
  groupID: string;
};

export const CreateWorkspaceModal = (props: ModalProps & { solution?: string }) => {
  const [css, theme] = useStyletron();
  const navigate = useNavigate();
  const { reset, control, watch, handleSubmit, formState } = useForm<CreateWorkspaceFormType>({
    defaultValues: {
      type: 'new',
    },
  });
  const { userInfo } = useOrgContext();
  const filterGroupAdmin = useCallback(
    (r: ReturnType<typeof useQueryGetGroups>['data']) => {
      return {
        ...r,
        Error: r?.Error ?? false,
        Result:
          r?.Result?.filter((g) => calculateRoleForGroup(userInfo.roles, g.workgroup_id) === 'workgroup-admins') || [],
      };
    },
    [userInfo]
  );
  const { data: groups } = useQueryGetGroups({
    select: filterGroupAdmin,
  });
  const groupOptions: Option[] = groups?.Result?.map((g) => ({ id: g.workgroup_id, label: g.name })) || [];
  const type = watch('type');
  const canCreateWorkgroup = isOrgAdmin(userInfo.roles);

  useEffect(() => {
    if (!props.isOpen) {
      reset();
    }
  }, [props.isOpen, reset]);

  useEffect(() => {
    if (canCreateWorkgroup) {
      reset({
        type: 'new',
      });
    } else {
      reset({
        type: 'attach',
      });
    }
  }, [canCreateWorkgroup, reset]);

  const handleConfirm = (value: CreateWorkspaceFormType) => {
    let queryString = '';
    if (props.solution) {
      const searchParams = new URLSearchParams();
      searchParams.set('solution', props.solution);
      queryString = `?${searchParams.toString()}`;
    }
    switch (value.type) {
      case 'new':
        navigate(`/groups/new${queryString}`);
        break;
      case 'attach':
        navigate(`/groups/${value.groupID}/spaces/config${queryString}`);
        break;
    }
  };

  return (
    <Modal {...props}>
      <ModalHeader>Select Workgroup</ModalHeader>
      <ModalBody>
        <p className={cn('text-xs leading-4', css({ color: theme.colors['modal.text.secondary'] }))}>
          Please select a workgroup for your new workspace. A workgroup is a collection of databases and their
          associated workspaces, which you can manage together.
        </p>
        <div className="mt-4">
          <Controller
            control={control}
            name="type"
            render={({ field }) => (
              <RadioGroup {...field}>
                <Radio value="new" disabled={!canCreateWorkgroup}>
                  Create New Workgroup
                </Radio>
                <Radio value="attach">Attach to Existing Workgroup</Radio>
              </RadioGroup>
            )}
          />
        </div>
        {type === 'attach' && (
          <FormControl error={formState.errors.groupID?.message}>
            <Controller
              control={control}
              name="groupID"
              rules={{
                required: 'Please select workgroup',
              }}
              render={({ field: { value, onChange, ref, ...field } }) => (
                <Select
                  inputRef={ref}
                  options={groupOptions}
                  value={[{ id: value }]}
                  onChange={(e) => {
                    onChange(e.value?.[0]?.id);
                  }}
                  {...field}
                  clearable={false}
                />
              )}
            />
          </FormControl>
        )}
      </ModalBody>
      <ModalFooter>
        <div className="space-x-4">
          <Button kind="secondary" onClick={() => props.onClose?.({})}>
            Cancel
          </Button>
          <Button onClick={handleSubmit(handleConfirm)}>Confirm</Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
