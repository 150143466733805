import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import EmptyImageDark from '@/assets/empty-cp-dark.svg';
import EmptyImageLight from '@/assets/empty-cp-light.svg';
import { Button } from '@tigergraph/app-ui-lib/button';
import { MdAdd } from 'react-icons/md';
import { useTheme } from '@/contexts/themeContext';

export default function Empty({ onClickAdd }: { onClickAdd: () => void }) {
  const [css, theme] = useStyletron();
  const { themeType } = useTheme();

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        paddingTop: '156px',
        gap: '16px',
      })}
    >
      <img src={themeType === 'dark' ? EmptyImageDark : EmptyImageLight} alt="No cloud providers" />
      <p
        className={css({
          color: theme.colors['text.secondary'],
          ...theme.typography['ParagraphSmall'],
        })}
      >
        Click the &quot;+ Cloud Provider&quot; button to create one.
      </p>
      <Button size="large" onClick={onClickAdd}>
        <span
          className={css({
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          })}
        >
          <MdAdd size={20} />
          Cloud Provider
        </span>
      </Button>
    </div>
  );
}
