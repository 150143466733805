import { Result } from '@/lib/type';
import { createAPIKey, deleteAPIKey, listAPIKey } from '@/pages/admin/settings/apikey/api';
import { APIKey } from '@/pages/admin/settings/apikey/type';
import { AxiosError } from 'axios';
import { UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query';

export function useMutationCreateAPIKey() {
  const queryClient = useQueryClient();

  return useMutation<Result<APIKey>, AxiosError, { description: string; life_time_in_seconds: number }>(
    async ({ description, life_time_in_seconds }) => {
      const resp = await createAPIKey(description, life_time_in_seconds);
      return resp;
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(['api_keys']);
      },
    }
  );
}

export function useMutationDeleteAPIKey() {
  const queryClient = useQueryClient();

  return useMutation<Result<void>, AxiosError, { key_name: string }>(
    ({ key_name }) => {
      return deleteAPIKey(key_name);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(['api_keys']);
      },
    }
  );
}

export function useQueryListAPIKey(
  options?: Omit<UseQueryOptions<Result<APIKey[]>, AxiosError>, 'queryKey' | 'queryFn'>
) {
  return useQuery(
    ['api_keys'],
    () => {
      return listAPIKey();
    },
    options
  );
}
