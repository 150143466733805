import { AddonsMetaID } from '@/components/addons/consts';
import { DropdownMenuItem } from '@/components/ui/dropdown-menu';
import { useOrgContext } from '@/contexts/orgContext';
import { useAddonsMeta } from '@/hooks/useAddonsMeta';
import { EnabledAddonsType, useListEnabledAddons } from '@/hooks/useListEnabledAddons';
import { useConfig } from '@/lib/config';
import { WorkspaceT, isStatusActive } from '@/pages/workgroup/type';
import trackUtil, { AppToolType } from '@/utils/analytics-service';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { useCallback, useMemo } from 'react';
import { getToolsLink } from '@/hooks/useToolsLink';
import { Link } from 'react-router-dom';
import TigergraphIcon from './icon/Tigergraph.svg';
import { StatefulPopover } from '@tigergraph/app-ui-lib/popover';

interface AddonsMenuCommon {
  name: string;
  addonID: string;
  enabled?: boolean;
  alwaysEnabled?: boolean;
  icon: string;
}

interface AddonsMenuItemToolsLink extends AddonsMenuCommon {
  interationType: 'tools-link';
  path: string;
}

interface AddonsMenuItemLink extends AddonsMenuCommon {
  interationType: 'link';
  url: string;
}

type AddonsMenuItemType = AddonsMenuItemToolsLink | AddonsMenuItemLink;

const presetToolsLinkPathMap = new Map<string, string>([
  [AddonsMetaID.ADMIN_PORTAL, '/admin'],
  [AddonsMetaID.GRAPH_STUDIO, '/studio'],
  [AddonsMetaID.INSIGHTS, '/insights'],
  [AddonsMetaID.GRAPHQL, '/graphql'],
]);

export function useMenuItemAddons(workspace: WorkspaceT) {
  const { currentOrg } = useOrgContext();
  const { appConfig } = useConfig();
  const { data: addonsMeta } = useAddonsMeta();
  const { data: addonsEnabledMap } = useListEnabledAddons({
    select: useCallback((data?: EnabledAddonsType[]) => new Map(data?.map((item) => [item.AddonsID, item])), []),
  });

  const addonsMenuItems = useMemo<AddonsMenuItemType[]>(() => {
    const workspaceAddonsEnabledMap = new Map(workspace.addons?.map((a) => [a.addons_id, a.enable]));
    const result: AddonsMenuItemType[] =
      addonsMeta?.AddonsList.filter((m) => {
        if (m.ID === AddonsMetaID.COPILOT && !workspace.is_rw) {
          return false;
        }
        return addonsEnabledMap?.get(m.ID)?.Enabled;
      }).map((m) => {
        const common = {
          name: m.Title,
          addonID: m.ID,
          enabled: workspaceAddonsEnabledMap.get(m.ID) || false,
          icon: m.Avatar,
        };
        if (m.ID === AddonsMetaID.COPILOT) {
          return {
            ...common,
            interationType: 'link',
            url: `/explore?tab=copilot&workspace_id=$${workspace.workspace_id}`,
          };
        }
        return {
          ...common,
          interationType: 'tools-link',
          path: presetToolsLinkPathMap.get(m.ID) || '',
        };
      }) || [];
    result.unshift({
      interationType: 'tools-link',
      name: AppToolType.AdminPortal,
      addonID: AddonsMetaID.ADMIN_PORTAL,
      alwaysEnabled: true,
      path: '/admin',
      icon: TigergraphIcon,
    });
    return result;
  }, [addonsEnabledMap, addonsMeta, workspace.addons, workspace.workspace_id, workspace.is_rw]);

  const getLink = (menuItem: AddonsMenuItemToolsLink) => {
    return getToolsLink(appConfig, currentOrg, workspace, menuItem.path);
  };

  const handleMenuItemClick = (menuItem: AddonsMenuItemType) => {
    switch (menuItem.interationType) {
      case 'link':
        menuItem.name in AppToolType && trackUtil.trackEnterAppTools(menuItem.name as AppToolType);
        break;
    }
  };

  const enable = isStatusActive(workspace.status);

  const isMenuItemDisabled = (menuItem: AddonsMenuItemType) => {
    // check  if workspace is disabled
    if (!enable) {
      return true;
    }

    if (menuItem.alwaysEnabled) {
      return false;
    }

    return !menuItem.enabled;
  };

  return {
    addonsMenuItems,
    getLink,
    handleMenuItemClick,
    isMenuItemDisabled,
  };
}

export function ConnectionMenuItemAddons({ workspace }: { workspace: WorkspaceT }) {
  const [css, theme] = useStyletron();

  const { addonsMenuItems, getLink, handleMenuItemClick, isMenuItemDisabled } = useMenuItemAddons(workspace);

  return (
    <>
      {addonsMenuItems.map((menuItem) => {
        const content = (
          <>
            {menuItem.interationType === 'tools-link' && (
              <a
                target="_blank"
                href={getLink(menuItem)}
                rel="noreferrer noopener"
                className={css({
                  display: 'flex',
                  alignItems: 'center',
                  gap: '7px',
                })}
              >
                <img
                  src={menuItem.icon}
                  aria-hidden={true}
                  className={css({
                    width: '18px',
                  })}
                />
                {menuItem.name}
              </a>
            )}
            {menuItem.interationType === 'link' && (
              <Link
                to={menuItem.url}
                className={css({
                  display: 'flex',
                  alignItems: 'center',
                  gap: '7px',
                })}
              >
                <img
                  src={menuItem.icon}
                  aria-hidden={true}
                  className={css({
                    width: '18px',
                  })}
                />
                {menuItem.name}
              </Link>
            )}
          </>
        );
        if (isMenuItemDisabled(menuItem)) {
          return (
            <StatefulPopover
              key={menuItem.name}
              content={
                <>
                  {`You haven't enabled this add-on yet. You can enable it from the `}{' '}
                  <button
                    type="button"
                    className={css({
                      // color: theme.colors['text.link.inverse'],
                      // cursor: 'pointer',
                    })}
                    onClick={() => {
                      //
                    }}
                  >
                    Edit Workspace
                  </button>{' '}
                  page.
                </>
              }
              triggerType="hover"
              placement="top"
              overrides={{
                Body: {
                  style: {
                    width: '320px',
                  },
                },
              }}
            >
              <div className="opacity-50 relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground gap-2">
                <span
                  className={css({
                    display: 'flex',
                    alignItems: 'center',
                    gap: '7px',
                  })}
                >
                  <img
                    src={menuItem.icon}
                    aria-hidden={true}
                    className={css({
                      width: '18px',
                    })}
                  />
                  {menuItem.name}
                </span>
              </div>
            </StatefulPopover>
          );
        }
        return (
          <DropdownMenuItem key={menuItem.name} onClick={() => handleMenuItemClick(menuItem)}>
            {content}
          </DropdownMenuItem>
        );
      })}
    </>
  );
}
