import { EditorView } from '@codemirror/view';
import symbolFunction from '@/assets/symbol-function.svg';
import symbolVariable from '@/assets/symbol-variable.svg';
import symbolField from '@/assets/symbol-field.svg';
import symbolKeywordDark from '@/assets/symbol-keyword-dark.svg';
import symbolKeywordLight from '@/assets/symbol-keyword-light.svg';
import symbolKeywordSelected from '@/assets/symbol-keyword-selected.svg';
import symbolMultiwordsDark from '@/assets/symbol-multiwords-dark.svg';
import symbolMultiwordsLight from '@/assets/symbol-multiwords-light.svg';
import symbolMultiwordsSelected from '@/assets/symbol-multiwords-selected.svg';
import symbolType from '@/assets/symbol-type.svg';
import symbolVertex from '@/assets/symbol-vertex.svg';
import symbolEdge from '@/assets/symbol-edge.svg';
import symbolGraph from '@/assets/symbol-graph.svg';

export default function createTheme(themeType: 'dark' | 'light') {
  return EditorView.theme(
    {
      '.cm-tooltip.cm-tooltip-autocomplete': {
        backgroundColor: themeType === 'dark' ? '#1E1E1E' : '#F3F3F3',
        border: `1px solid ${themeType === 'dark' ? '#454545' : '#C8C8C8'}`,
        borderRadius: '3px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
      },
      '.cm-tooltip.cm-tooltip-autocomplete > ul': {
        padding: '0',
      },
      '.cm-tooltip.cm-tooltip-autocomplete > ul > li': {
        display: 'flex',
        alignItems: 'center',
        padding: '2px 1px',
        color: themeType === 'dark' ? '#D3D3D3' : '#000000',
      },
      '.cm-tooltip.cm-tooltip-autocomplete > ul > li[aria-selected]': {
        backgroundColor: themeType === 'dark' ? '#03395E' : '#0360C0',
        color: themeType === 'dark' ? '#FFFFFF' : '#FFFFFF',
      },
      '.cm-completionIcon': {
        display: 'flex',
        alignItems: 'center',
        width: '16px',
        height: '16px',
        overflow: 'hidden',
        paddingRight: '4px',
      },
      '.cm-completionMatchedText': {
        textDecoration: 'none',
        fontWeight: 'bold',
        color: themeType === 'dark' ? '#2AAAFF' : '#0066BF',
      },
      '.cm-tooltip.cm-tooltip-autocomplete > ul > li[aria-selected] .cm-completionMatchedText': {
        color: themeType === 'dark' ? '#2AAAFF' : '#BBE7FF',
      },
      '.cm-completionIcon-function::after, .cm-completionIcon-method::after': {
        content: `url("${symbolFunction}")`,
        height: '100%',
      },
      '.cm-completionIcon-variable::after': {
        content: `url("${symbolVariable}")`,
        height: '100%',
      },
      '.cm-completionIcon-field::after': {
        content: `url("${symbolField}")`,
        height: '100%',
      },
      '.cm-completionIcon-keyword::after': {
        content: `url("${themeType === 'dark' ? symbolKeywordDark : symbolKeywordLight}")`,
        height: '100%',
      },
      '.cm-tooltip.cm-tooltip-autocomplete > ul > li[aria-selected] .cm-completionIcon-keyword::after': {
        content: `url("${symbolKeywordSelected}")`,
        height: '100%',
      },
      '.cm-completionIcon-multiwords::after': {
        content: `url("${themeType === 'dark' ? symbolMultiwordsDark : symbolMultiwordsLight}")`,
        height: '100%',
      },
      '.cm-tooltip.cm-tooltip-autocomplete > ul > li[aria-selected] .cm-completionIcon-multiwords::after': {
        content: `url("${symbolMultiwordsSelected}")`,
        height: '100%',
      },
      '.cm-completionIcon-type::after': {
        content: `url("${symbolType}")`,
        height: '100%',
      },
      '.cm-completionIcon-vertex::after': {
        content: `url("${symbolVertex}")`,
        height: '100%',
      },
      '.cm-completionIcon-edge::after': {
        content: `url("${symbolEdge}")`,
        height: '100%',
      },
      '.cm-completionIcon-graph::after': {
        content: `url("${symbolGraph}")`,
        height: '100%',
      },
    },
    { dark: themeType === 'dark' }
  );
}
