import { styled, useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Button } from '@tigergraph/app-ui-lib/button';
import { Body2 } from '@tigergraph/app-ui-lib/typography';
import { useNavigate } from 'react-router-dom';
import { MdOutlineAddToQueue } from 'react-icons/md';
import { ConfirmStatefulPopover } from '@/components/confirmPopover';
import NoWorkspaceIcon from '@/assets/no-workspace.svg';
import NoActiveSpaceIcon from '@/assets/no-active-workspace.svg';
import NoActiveSpaceDarkIcon from '@/assets/no-active-workspace-dark.svg';
import NoWorkgroupIcon from '@/assets/no-workgroup.svg';
import NoPermissionIcon from '@/assets/no-permission.svg';
import NoWorkspaceDarkIcon from '@/assets/no-workspace-dark.svg';
import NoWorkgroupDarkIcon from '@/assets/no-workgroup-dark.svg';
import NoPermissionDarkIcon from '@/assets/no-permission-dark.svg';

import { useOrgContext } from '@/contexts/orgContext';
import { isGroupAdmin, isOrgAdmin } from '@/pages/admin/user/type';
import { WorkGroupT, canCreateRWWorkspace, WorkspaceQuotaError } from '@/pages/workgroup/type';
import { StatefulTipsPopover } from '@/components/tipsPopover';
import { TRIGGER_TYPE } from 'baseui/popover';
import { useCloudProviders } from '@/pages/workgroup/hook';
import { useTheme } from '@/contexts/themeContext';
import { useWorkspaceContext } from '@/contexts/workspaceContext';
import { isCPDisabled } from '@/pages/admin/settings/cloud_provider/util';
import { ReactNode } from 'react';

export default function EmptyState({
  toPage,
  useGroupIcon,
  onDelete,
  group,
  extraUI,
}: {
  // default action is to create new workgroup
  // in group page, override toPage to create new workspace
  toPage?: string;
  useGroupIcon?: boolean;
  // means if delete the current empty workgroup
  onDelete?: () => void;
  // the group id of the current empty workgroup
  group?: WorkGroupT;
  extraUI?: ReactNode;
}) {
  const navigate = useNavigate();
  const [css] = useStyletron();

  const { themeType } = useTheme();

  const { userInfo } = useOrgContext();
  const orgAdmin = isOrgAdmin(userInfo.roles);
  const groupAdmin = isGroupAdmin(userInfo.roles, group?.workgroup_id || '');

  const { orgQuota } = useWorkspaceContext();
  const { currentWorkspace, workspaces } = useWorkspaceContext();
  // current workspace is not active and we have workspaces
  const isNonActiveWorkspace = !currentWorkspace && workspaces.length > 0;

  const { cps, isCpLoading } = useCloudProviders();
  const cp = group && cps.find((cp) => cp.id === group.cloud_provider_id);
  const cpDisabled = cp && isCPDisabled(cp);

  // only org admin can create workgroup
  const canCreateGroup = orgAdmin;
  // only org admin and group admin can create workspace
  const canCreateSpace = orgAdmin || groupAdmin;

  const NoPermission = themeType === 'light' ? NoPermissionIcon : NoPermissionDarkIcon;
  const NoWorkgroup = themeType === 'light' ? NoWorkgroupIcon : NoWorkgroupDarkIcon;
  const NoSpace = themeType === 'light' ? NoWorkspaceIcon : NoWorkspaceDarkIcon;

  const groupIcon = canCreateGroup ? NoWorkgroup : NoPermission;
  let spaceIcon = canCreateSpace ? NoSpace : NoPermission;

  // if we have group_id, means we are in group detail page
  const isGroupDetail = !!group;
  let hideButton = false;

  // only show no active space icon in non group detail page
  // also need to hide the create button
  if (isNonActiveWorkspace && !isGroupDetail) {
    spaceIcon = themeType === 'light' ? NoActiveSpaceIcon : NoActiveSpaceDarkIcon;
    hideButton = true;
  }

  // 1. in group detail page
  // 2. in groups page and useGroupIcon is false
  // all others use space icon
  const showSpaceIcon = isGroupDetail || !useGroupIcon;

  const createButton = (enableCreate: boolean) => {
    return (
      <Button
        size="large"
        onClick={() => {
          if (enableCreate) {
            navigate(toPage || '/groups/new');
          }
        }}
        overrides={{
          BaseButton: {
            style: {
              opacity: enableCreate ? 1 : 0.5,
            },
          },
        }}
      >
        <MdOutlineAddToQueue size={20} />
        <span
          className={css({
            paddingLeft: '8px',
            fontSize: '14px',
            fontWeight: 600,
            whiteSpace: 'nowrap',
          })}
        >
          {isGroupDetail ? 'Create Workspace' : 'Get Started Now'}
        </span>
      </Button>
    );
  };

  if (!orgQuota) {
    return null;
  }

  const haveWorkspaceQuota = canCreateRWWorkspace(orgQuota);

  return (
    <Container>
      {showSpaceIcon ? <img src={spaceIcon} /> : <img src={groupIcon} />}
      <Body2>
        {isGroupDetail ? (
          canCreateSpace ? (
            'Get started by creating your first workspace in your workgroup.'
          ) : (
            <>
              You do not have permission to create a workspace.
              <br />
              Please request access from the workgroup admin or organization admin.
            </>
          )
        ) : canCreateGroup ? (
          isNonActiveWorkspace ? (
            'Please select a workspace from the dropdown in the top right corner.'
          ) : (
            'Get started by creating your first workgroup and workspace.'
          )
        ) : (
          <>
            You do not have permission to create workgroup.
            <br />
            Please request access from the organization admin.
          </>
        )}
      </Body2>
      {(canCreateGroup || canCreateSpace) && !hideButton ? (
        !haveWorkspaceQuota || cpDisabled ? (
          <StatefulTipsPopover
            content={
              !haveWorkspaceQuota ? <WorkspaceQuotaError orgQuota={orgQuota!} /> : 'Cloud provider is unavailable.'
            }
            triggerType={TRIGGER_TYPE.hover}
            autoFocus={false}
            focusLock={true}
            overrides={{
              Body: {
                style: {
                  maxWidth: '450px',
                },
              },
            }}
          >
            {createButton(false)}
          </StatefulTipsPopover>
        ) : (
          createButton(true)
        )
      ) : null}

      {isGroupDetail && onDelete && canCreateGroup ? (
        <>
          <Body2 $style={{ color: '#A5A5A8', display: 'flex', gap: '8px', alignItems: 'center' }}>
            <Line />
            or
            <Line />
          </Body2>
          <ConfirmStatefulPopover confirmLabel="Confirm to delete" onConfirm={onDelete}>
            <Button
              kind="destructive"
              size="large"
              overrides={{
                BaseButton: {
                  style: {
                    width: '178px',
                  },
                },
              }}
            >
              Delete Workgroup
            </Button>
          </ConfirmStatefulPopover>
        </>
      ) : null}
      {extraUI}
    </Container>
  );
}

const Container = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  ...$theme.typography.Body1,
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 auto',
  height: '100%',
  textAlign: 'center',
  gap: '16px',
  position: 'relative',
}));

const Line = styled('span', ({ $theme }) => ({
  backgroundColor: $theme.colors.divider,
  height: '1px',
  width: '123px',
}));
