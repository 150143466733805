import { CustomTheme } from '@tigergraph/app-ui-lib/Theme';
import { mergeOverrides } from 'baseui';
import { Overrides } from 'baseui/overrides';
import { TableOverrides } from 'baseui/table-semantic';

export const SchemaAttributeTable = (theme: CustomTheme): TableOverrides => ({
  Root: {
    style: {
      overflow: 'visible',
    },
  },
  Table: {
    style: {
      width: '100%',
    },
  },
  TableHeadCell: {
    style: {
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingLeft: '4px',
      paddingRight: '14px',
      minWidth: '70px',
      fontSize: '12px',
      fontWeight: 500,
      backgroundColor: 'transparent',
      ':first-child': {
        boxSizing: 'border-box',
        paddingLeft: '8px',
        paddingRight: '0px',
        minWidth: '20px !important',
      },
    },
  },
  TableBodyCell: {
    style: {
      paddingTop: '2px',
      paddingBottom: '2px',
      paddingLeft: '4px',
      paddingRight: '4px',
      backgroundColor: 'transparent',
      ':first-child': {
        paddingLeft: '8px',
        paddingRight: '0px',
      },
    },
  },
});

export const SchemaAttributeTableWithSelect = (theme: CustomTheme): TableOverrides =>
  mergeOverrides(SchemaAttributeTable(theme) as Overrides<any>, {
    TableHeadCell: {
      style: {
        ':first-child': {
          width: '24px',
        },
      },
    },
    TableBodyCell: {
      style: {
        ':first-child': {
          width: '24px',
        },
      },
    },
  });
