import { axiosController } from '@/lib/network';
import { Result } from '@/lib/type';

type TimeZoneWithWorkspaceAPI = {
  workspace_type_name: string;
  timezone: string;
  time: string;
};

export type ScheduleNoRepeatAPI = {
  repeat: 'NOREPEAT';
  date: string;
} & TimeZoneWithWorkspaceAPI;

export type ScheduleDailyAPI = {
  repeat: 'DAILY';
} & TimeZoneWithWorkspaceAPI;

export type ScheduleWeekdayAPI = {
  repeat: 'WEEKDAY';
} & TimeZoneWithWorkspaceAPI;

export type ScheduleWeeklyAPI = {
  repeat: 'WEEKLY';
  repeat_on: string;
} & TimeZoneWithWorkspaceAPI;

export type ScheduleDataAPI = ScheduleNoRepeatAPI | ScheduleDailyAPI | ScheduleWeekdayAPI | ScheduleWeeklyAPI;

export type ScheduleAPI = ScheduleDataAPI & {
  id: string;
};

export async function addSchedule(
  workgroup_id: string,
  workspace_id: string,
  data: ScheduleDataAPI
): Promise<Result<void>> {
  const response = await axiosController.post(
    `v2/workgroups/${workgroup_id}/workspaces/${workspace_id}/schedules`,
    data
  );
  return response.data;
}

export async function deleteSchedule(workgroup_id: string, workspace_id: string, id: string): Promise<Result<void>> {
  const response = await axiosController.delete(
    `v2/workgroups/${workgroup_id}/workspaces/${workspace_id}/schedules/${id}`
  );
  return response.data;
}

export async function updateSchedule(
  workgroup_id: string,
  workspace_id: string,
  id: string,
  data: ScheduleDataAPI
): Promise<Result<void>> {
  const response = await axiosController.put(
    `v2/workgroups/${workgroup_id}/workspaces/${workspace_id}/schedules/${id}`,
    data
  );
  return response.data;
}

export async function listSchedule(workgroup_id: string, workspace_id: string): Promise<Result<ScheduleAPI[]>> {
  const response = await axiosController.get(`v2/workgroups/${workgroup_id}/workspaces/${workspace_id}/schedules`);
  return response.data;
}
