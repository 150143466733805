import { EnabledAddonsType, useListEnabledAddons } from '@/hooks/useListEnabledAddons';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Skeleton } from 'baseui/skeleton';
import { MetaAddonsType, useAddonsMeta } from '@/hooks/useAddonsMeta';
import { FC, SVGProps, useEffect, useMemo, useState } from 'react';
import WorkspaceAddonsEmpty from './Empty';
import defaultAvatar from '@/assets/tg-avatar.png';
import WorkspaceAddonsEnableDrawer from './drawer';
import { useAddonsIsEnabled, useDisableAddonsCopilot } from '@/pages/workgroup/form/addons/store';
import { AddonsMetaID } from '@/components/addons/consts';
import { cn } from '@/lib/utils';
import { CheckButton } from '@tigergraph/app-ui-lib/checkbutton';

const EnabledIcon = (props: SVGProps<SVGSVGElement>) => {
  const [_, theme] = useStyletron();
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="Group 47045">
        <path
          id="Subtract"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 0H0L20 20V0ZM17.5575 3.50172C17.8346 3.19384 17.8096 2.71962 17.5017 2.44253C17.1938 2.16544 16.7196 2.19039 16.4425 2.49828L12.4714 6.91069L10.5303 4.96967C10.2374 4.67678 9.76256 4.67678 9.46967 4.96967C9.17678 5.26256 9.17678 5.73744 9.46967 6.03033L11.8206 8.38127C12.2055 8.76614 12.8344 8.7496 13.1985 8.34503L17.5575 3.50172Z"
          fill={theme.colors['border.selected']}
        />
      </g>
    </svg>
  );
};

const WorkspaceAddonsItem: FC<{
  addonsMeta?: MetaAddonsType;
  onClick?(): void;
}> = ({ onClick, addonsMeta }) => {
  const isEnabled = useAddonsIsEnabled(addonsMeta?.ID as string);
  const [css, theme] = useStyletron();

  return (
    <CheckButton
      className="flex items-center space-x-1 px-2 py-4"
      selected={isEnabled}
      onClick={() => onClick?.()}
      type="button"
    >
      <div className="flex items-center space-x-3">
        <img className="w-8 h-8 object-contain" src={addonsMeta?.WorkspaceAvatar || defaultAvatar} />
        <div className="flex-grow text-left">
          <h4
            className={cn(
              'font-[Roboto] truncate font-semibold leading-6',
              css({ color: theme.colors['text.primary'] })
            )}
          >
            {addonsMeta?.Title}
          </h4>
          <p className={cn('text-xs', css({ color: theme.colors['text.secondary'] }))}>{addonsMeta?.Subtitle}</p>
        </div>
      </div>
    </CheckButton>
  );
};

const sortEnabledAddons = (data: EnabledAddonsType[] | undefined) =>
  data?.sort((a, b) => {
    if (a.AddonsID === AddonsMetaID.COPILOT) {
      return -1;
    }
    if (b.AddonsID === AddonsMetaID.COPILOT) {
      return 1;
    }
    return 0;
  });

const WorkspaceAddonSelect: FC<{
  disableCopilot?: boolean;
  computePrice?: number;
  onChange?(params: Record<string, boolean | number | string>): void;
  workspaceType: string;
  platform: string;
  region: string;
}> = ({ disableCopilot, computePrice, onChange, workspaceType, platform, region }) => {
  const [css, theme] = useStyletron();
  const [selectedEnabledAddons, setSelectedEnabledAddons] = useState<EnabledAddonsType>();
  const [addonsParams, setAddonsParams] = useState<Record<string, string | number | boolean>>();
  const { data: enabledAddons, isLoading: loadingEnabledAddons } = useListEnabledAddons({
    select: sortEnabledAddons,
    refetchOnWindowFocus: true,
  });
  const { data: addonsMeta, isLoading: loadingAddonsMeta } = useAddonsMeta();
  const metaMap = useMemo(() => new Map(addonsMeta?.AddonsList.map((m) => [m.ID, m])), [addonsMeta]);
  const disableEnabledAddonsCopilot = useDisableAddonsCopilot();
  const selectedMetaAddons = metaMap.get(selectedEnabledAddons?.AddonsID as string);
  const isLoading = loadingEnabledAddons || loadingAddonsMeta;

  const handleAddonsEnableChange = (params: Record<string, string | number | boolean>) => {
    const newParams = {
      ...addonsParams,
      ...params,
    };

    setAddonsParams(newParams);
    setSelectedEnabledAddons(undefined);

    onChange?.(newParams);
  };

  const filteredEnabledAddons = (() => {
    const orgEnabledAddons = addonsMeta?.AddonsList.filter((i) => enabledAddons?.find((ea) => i.ID === ea.AddonsID));
    return disableCopilot ? orgEnabledAddons?.filter((i) => i.ID !== AddonsMetaID.COPILOT) : orgEnabledAddons;
  })();

  useEffect(() => {
    if (disableCopilot) {
      disableEnabledAddonsCopilot();
    }
  }, [disableCopilot, disableEnabledAddonsCopilot]);

  return (
    <div>
      <h1 className={css({ ...theme.typography.HeadingMedium })}>Add-Ons</h1>
      <p className={css({ ...theme.typography.Body2, color: theme.colors['text.secondary'], marginBottom: '8px' })}>
        Extend the capabilities of TigerGraph Cloud with add-ons tailored to your needs.
      </p>
      <div className="grid gap-3 grid-cols-[repeat(4,245px)] [&>*]:h-[80px]">
        {isLoading
          ? Array.from({ length: 4 }).map((_, i) => <Skeleton rows={0} width="100%" animation key={i} />)
          : filteredEnabledAddons?.map((d) => (
              <WorkspaceAddonsItem
                key={d.ID}
                addonsMeta={d}
                onClick={() => setSelectedEnabledAddons(enabledAddons?.find((ea) => ea.AddonsID === d.ID))}
              />
            ))}
        {!isLoading && enabledAddons?.length === 0 && <WorkspaceAddonsEmpty />}
      </div>
      <p className={cn('text-xs font-[Roboto] space-x-1 mt-4', css({ color: theme.colors['text.secondary'] }))}>
        <span>Can{"'"}t find the Add-on you want?</span>
        <a className={css({ color: theme.colors['text.link'] })} href="/marketplace/addons" target="_blank">
          Click to add.
        </a>{' '}
      </p>
      <WorkspaceAddonsEnableDrawer
        computePrice={computePrice}
        addonsMeta={selectedMetaAddons}
        enabledAddons={selectedEnabledAddons}
        onChange={handleAddonsEnableChange}
        onCancel={() => setSelectedEnabledAddons(undefined)}
        workspaceType={workspaceType}
        platform={platform}
        region={region}
      />
    </div>
  );
};

export default WorkspaceAddonSelect;
