import { format } from 'date-fns';

export const WeekDays = [
  {
    id: 0,
    label: 'Sunday',
  },
  {
    id: 1,
    label: 'Monday',
  },
  {
    id: 2,
    label: 'Tuesday',
  },
  {
    id: 3,
    label: 'Wednesday',
  },
  {
    id: 4,
    label: 'Thursday',
  },
  {
    id: 5,
    label: 'Friday',
  },
  {
    id: 6,
    label: 'Saturday',
  },
];

export function getWeekDayLabel(d: number) {
  for (let day of WeekDays) {
    if (day.id === d) {
      return day.label;
    }
  }
  return '';
}

type TimeZoneWithWorkspace = {
  workspace_type_name: string;
  timezone: string;
  time: Date;
};

export type ScheduleNoRepeat = {
  repeat: 'NOREPEAT';
  date: Date;
} & TimeZoneWithWorkspace;

export type ScheduleDaily = {
  repeat: 'DAILY';
} & TimeZoneWithWorkspace;

export type ScheduleWeekday = {
  repeat: 'WEEKDAY';
} & TimeZoneWithWorkspace;

export type ScheduleWeekly = {
  repeat: 'WEEKLY';
  repeat_on: number[];
} & TimeZoneWithWorkspace;

export type ScheduleData = ScheduleNoRepeat | ScheduleDaily | ScheduleWeekday | ScheduleWeekly;

export type ScheduleType = ScheduleData['repeat'];

export type Schedule = ScheduleData & {
  id: string;
};

export function scheduleTime(schedule: ScheduleData) {
  switch (schedule.repeat) {
    case 'NOREPEAT':
      return `${format(schedule.date, 'yyyy-MM-dd')} ${format(schedule.time, 'HH:mm')}`;
    case 'DAILY':
    case 'WEEKDAY':
    case 'WEEKLY':
      return `${format(schedule.time, 'HH:mm')}`;
  }
}

export function scheduleDescription(schedule: ScheduleData) {
  switch (schedule.repeat) {
    case 'NOREPEAT':
      return `set to ${schedule.workspace_type_name} at ${scheduleTime(schedule)}`;
    case 'DAILY':
      return `set to ${schedule.workspace_type_name} at ${scheduleTime(schedule)} every day`;
    case 'WEEKDAY':
      return `set to ${schedule.workspace_type_name} at ${scheduleTime(schedule)} every weekday (Monday to Friday)`;
    case 'WEEKLY':
      if (schedule.repeat_on.length === 0) {
        return '';
      }
      return `set to ${schedule.workspace_type_name} at ${scheduleTime(schedule)} every ${formatWeekly(
        schedule.repeat_on
      )}`;
  }
}

function formatWeekly(days: number[]) {
  const weekDay: string[] = [];
  for (let day of days) {
    weekDay.push(getWeekDayLabel(day));
  }

  if (weekDay.length === 0) {
    return '';
  }

  return weekDay.join(',');
}
