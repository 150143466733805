import { axiosController } from '@/lib/network';
import { Result } from '@/lib/type';
import { useQuery } from 'react-query';

export type UseListAlertRulesParams = {
  workgroupID?: string;
  workspaceID?: string;
};

export type UseListAlertReturnItem = {
  id: string;
  metric: string;
  threshold: number;
  recipientList: string[];
};

export const useListAlertRules = ({ workgroupID, workspaceID }: UseListAlertRulesParams) => {
  return useQuery({
    queryKey: ['list', 'alert', 'rules'],
    queryFn: async () => {
      const res = await axiosController.get<Result<UseListAlertReturnItem[]>>(
        `/v2/alert/${workgroupID}/${workspaceID}`
      );

      return res.data.Result;
    },
    enabled: Boolean(workgroupID && workspaceID),
  });
};
