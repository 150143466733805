import { ReactNode } from 'react';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@tigergraph/app-ui-lib/modal';
import { expand } from 'inline-style-expand-shorthand';
import ConfirmButtons from '@/components/ConfirmButtons';
import { ModalOverrides } from 'baseui/modal';

export interface ConfirmModalProps {
  header: ReactNode;
  body: ReactNode | string[] | string;
  open: boolean;
  confirmLabel?: string;
  cancelLabel?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmDisabled?: boolean;
  confirmLoading?: boolean;
  mountNode?: HTMLElement;
  overrides?: ModalOverrides;
}

export default function ConfirmModal(props: ConfirmModalProps) {
  const [css, theme] = useStyletron();
  const { header, body, open, mountNode, onConfirm, onCancel, overrides } = props;
  return (
    <Modal isOpen={open} onClose={onCancel ?? onConfirm} mountNode={mountNode} overrides={overrides}>
      <ModalHeader
        className={css({
          fontFamily: 'Urbanist',
          fontSize: '18px',
          fontWeight: 600,
          color: theme.colors['modal.text'],
          ...expand({
            padding: '0',
          }),
        })}
      >
        {header}
      </ModalHeader>
      <ModalBody>
        <div
          className={css({
            color: theme.colors['modal.text'],
            fontSize: '14px',
            lineHeight: '24px',
            fontWeight: 400,
            whiteSpace: 'pre-line',
          })}
        >
          {Array.isArray(body) ? body.map((b, i) => <div key={i}>{b}</div>) : body}
        </div>
      </ModalBody>
      <ModalFooter>
        <ConfirmButtons {...props} />
      </ModalFooter>
    </Modal>
  );
}
