import { UseMutateAsyncFunction, useMutation } from 'react-query';
import { axiosCluster } from '@/lib/network';
import { Result } from '@/lib/type';
import { DBGraphStyleJson, SchemaChange } from '@tigergraph/tools-models/topology';
import { AxiosError } from 'axios';
import { ID_TOKEN_KEY } from '@/contexts/workspaceContext';
import { changeSchema, GLOBAL_GRAPH_NAME } from '@tigergraph/tools-models';
import { WorkspaceT } from '@/pages/workgroup/type';
import { showToast } from '@/components/styledToasterContainer';
import { KIND } from 'baseui/toast';
import { getErrorMessage } from '@/utils/utils';

const schemaStyleGlobalChangeURL = '/api/graph-styles/global';
const schemaStyleLocalChangeURL = '/api/graph-styles/local';

export interface SchemaChangeMutation {
  saveSchemaAsync: UseMutateAsyncFunction<Result<void>[], AxiosError<unknown, any>, SchemaChange[], unknown>;
  saveSchemaLoading: boolean;
  saveSchemaStyleAsync: UseMutateAsyncFunction<Result<void>, AxiosError<unknown, any>, DBGraphStyleJson, unknown>;
  saveSchemaStyleLoading: boolean;
}

export interface SchemaChangeMutationProps {
  graphName: string;
  workspace?: WorkspaceT;
  onSchemaChangeSuccess?: (variables: SchemaChange[]) => void;
  onSchemaChangeError?: (error: AxiosError<unknown, any>, variables: SchemaChange[]) => void;
  onSchemaStyleChangeSuccess?: (variables: DBGraphStyleJson) => void;
  onSchemaStyleChangeError?: (error: AxiosError<unknown, any>, variables: DBGraphStyleJson) => void;
}

export const useSchemaChangeMutation = (props: SchemaChangeMutationProps): SchemaChangeMutation => {
  const {
    graphName,
    workspace,
    onSchemaChangeSuccess,
    onSchemaChangeError,
    onSchemaStyleChangeSuccess,
    onSchemaStyleChangeError,
  } = props;

  const { mutateAsync: saveSchemaAsync, isLoading: saveSchemaLoading } = useMutation<
    Result<void>[],
    AxiosError,
    SchemaChange[]
  >(
    ['saveSchema'],
    async (schemaChangeJobs) => {
      const result = [];
      for (const schemaChangeJob of schemaChangeJobs) {
        const response = await changeSchema(
          {
            // @ts-ignore
            graph: graphName === GLOBAL_GRAPH_NAME || schemaChangeJob.graphs !== undefined ? undefined : graphName,
            schemaChange: schemaChangeJob,
            reinstall: false,
          },
          {
            baseURL: `https://${workspace?.nginx_host}`,
            version: workspace?.tg_version,
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem(ID_TOKEN_KEY)}`,
            },
          }
        );
        result.push(response.data);
      }
      return result;
    },
    {
      onSuccess: (_, variables) => {
        showToast({
          kind: KIND.positive,
          message: 'Successfully saved the schema!',
        });
        onSchemaChangeSuccess?.(variables);
      },
      onError: (error, variables) => {
        showToast({
          kind: KIND.negative,
          message: 'Failed to save the schema. ' + getErrorMessage(error),
        });
        onSchemaChangeError?.(error, variables);
      },
    }
  );

  const { mutateAsync: saveSchemaStyleAsync, isLoading: saveSchemaStyleLoading } = useMutation<
    Result<void>,
    AxiosError,
    DBGraphStyleJson
  >(
    ['saveSchemaStyle'],
    async (data) => {
      const url =
        graphName === GLOBAL_GRAPH_NAME ? schemaStyleGlobalChangeURL : `${schemaStyleLocalChangeURL}/${graphName}`;
      const response = await axiosCluster.put(url, data);
      return response.data;
    },
    {
      onSuccess: (_, variables) => {
        showToast({
          kind: KIND.positive,
          message: 'Successfully saved the schema style!',
        });
        onSchemaStyleChangeSuccess?.(variables);
      },
      onError: (error, variables) => {
        showToast({
          kind: KIND.negative,
          message: 'Failed to save the schema style. ' + getErrorMessage(error),
        });
        onSchemaStyleChangeError?.(error, variables);
      },
    }
  );

  return {
    saveSchemaAsync,
    saveSchemaLoading,
    saveSchemaStyleAsync,
    saveSchemaStyleLoading,
  };
};
