import { CustomTheme, styled, useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { HeadingSmallBold } from '@tigergraph/app-ui-lib/typography';
import { mergeOverrides } from 'baseui';
import { Drawer as BaseDrawer, DrawerOverrides, DrawerProps } from 'baseui/drawer';
import { Overrides } from 'baseui/overrides';
import React from 'react';

const drawerOverrides: DrawerOverrides = {
  Backdrop: {
    style: ({ $theme }) => {
      const customTheme = $theme as CustomTheme;
      return {
        backgroundColor: customTheme.colors['background.blanket'],
      };
    },
  },
  DrawerBody: {
    style: {
      marginTop: '0',
      marginBottom: '0',
      marginLeft: '0',
      marginRight: '0',
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
    },
  },
  Close: {
    style: ({ $theme }) => {
      const customTheme = $theme as CustomTheme;
      return {
        fill: customTheme.colors['text.primary'],
        width: '28px',
        height: '28px',
        top: '16px',
        ':hover': {
          fill: customTheme.colors['text.primary'],
        },
      };
    },
  },
};

export function DrawerHeader({
  children,
  className,
  hideLine,
}: {
  children: React.ReactNode;
  className?: string;
  hideLine?: boolean;
}) {
  const [_, theme] = useStyletron();
  return (
    <HeadingSmallBold
      className={className ? className : ''}
      $style={{
        borderBottom: hideLine ? 'none' : `1px solid ${theme.colors.divider}`,
        paddingTop: '12px',
        paddingBottom: '12px',
        paddingLeft: '16px',
        paddingRight: '32px',
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
      }}
    >
      {children}
    </HeadingSmallBold>
  );
}

export const DrawerBody = styled('div', {
  width: '658px',
  paddingLeft: '16px',
  paddingRight: '16px',
  paddingTop: '16px',
  paddingBottom: '24px',
  minHeight: 0,
  flexBasis: 0,
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
});

export const DrawerAction = styled('div', {
  display: 'flex',
  gap: '16px',
  justifyContent: 'flex-end',
  paddingLeft: '16px',
  paddingRight: '16px',
  paddingBottom: '16px',
});

export function Drawer({ overrides, ...props }: DrawerProps) {
  return (
    <BaseDrawer
      size="auto"
      autoFocus={false}
      overrides={mergeOverrides(drawerOverrides as Overrides<unknown>, overrides as Overrides<unknown>)}
      {...props}
    />
  );
}
