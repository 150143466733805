import { useMemo } from 'react';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Table } from '@tigergraph/app-ui-lib/table';
import { Attribute } from '@tigergraph/tools-models/topology';
import { buildAttributeKeyTableRow } from '@/components/schemaDesigner/attributeEditor/buildAttributeEditorRow';
import TooltipLabel from '@/components/TooltipLabel';
import { SchemaAttributeTable } from '@/utils/schemaDesigner/styleObject';
import { mergeOverrides } from 'baseui';
import { Overrides } from 'baseui/overrides';
import { ValidateResult } from '@tigergraph/tools-models/utils';
import ErrorMessage from '@/components/ErrorMessage';

export interface AttributeKeyTableProps {
  attributes: Attribute[];
  isVertex: boolean;
  validateResult: ValidateResult;
  readOnly?: boolean;
  onChangeAttributes: (attributes: Attribute[]) => void;
}

export default function AttributeKeyTable(props: AttributeKeyTableProps) {
  const [css, theme] = useStyletron();
  const { attributes, isVertex, validateResult, readOnly, onChangeAttributes } = props;

  const tableHeader = useMemo(() => {
    const tableHeader = ['Attribute'];
    if (isVertex) {
      tableHeader.push('Index');
    } else {
      tableHeader.push('Discriminator');
    }

    return tableHeader;
  }, [isVertex]);

  const tableData = useMemo(
    () =>
      attributes.map((attribute, index) => {
        const row = buildAttributeKeyTableRow({
          css,
          theme,
          attribute,
          isPrimaryId: isVertex && index === 0,
          readOnly,
          isVertex,
          handleAttributeChange: (newAttribute) => {
            const newAttributes = [...attributes];
            newAttributes[index] = newAttribute;
            onChangeAttributes(newAttributes);
          },
        });

        return row;
      }),
    [attributes, css, isVertex, onChangeAttributes, readOnly, theme]
  );

  const isInvalidAttribute = useMemo(() => {
    return (
      validateResult &&
      !validateResult.success &&
      !validateResult.message?.includes('vertex name') &&
      !validateResult.message?.includes('edge name')
    );
  }, [validateResult]);

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        rowGap: '8px',
      })}
    >
      {isVertex ? (
        <TooltipLabel
          label="Indexes"
          tooltip="Indexes allow users to perform fast lookups on non-key columns or attributes without a full-fledged scan."
        />
      ) : (
        <TooltipLabel
          label="Discriminators"
          tooltip="The discriminator is to allow multiple instances of an edge type between two vertices."
        />
      )}
      <div
        className={css({
          minHeight: '180px',
        })}
      >
        <Table
          divider={'horizontal'}
          overrides={mergeOverrides(SchemaAttributeTable(theme) as Overrides<any>, {
            Root: {
              style: {
                maxHeight: '300px',
                overflow: 'auto',
              },
            },
            TableBodyRow: {
              style: ({ $rowIndex }) => {
                const match = validateResult?.message?.match(/"([^"]*)"/);
                if (match && match[1] !== undefined) {
                  const quotedWord = match[1];
                  if ($rowIndex === attributes.findIndex((attribute) => attribute.name === quotedWord)) {
                    return {
                      border: `2px solid ${theme.colors.negative}`,
                    };
                  }
                }
                return {};
              },
            },
          })}
          columns={tableHeader}
          data={tableData}
        />
      </div>
      {isInvalidAttribute && <ErrorMessage message={validateResult?.message ?? ''} />}
    </div>
  );
}
