import { Avatar, formatName } from '@/components/Avatar';
import { UserInfo } from '@/lib/models';
import {
  getGroupAdmins,
  getOrgAdmins,
  getSpaceAdmins,
  getSpaceUsers,
  useQueryListUsers,
} from '@/pages/admin/user/hook';
import { GroupIcon, SpaceIcon } from '@/pages/home/icons';
import { WorkGroupT, WorkspaceT } from '@/pages/workgroup/type';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Spinner } from '@tigergraph/app-ui-lib/spinner';
import { TableBuilder } from '@tigergraph/app-ui-lib/table';
import { TableBuilderColumn } from 'baseui/table-semantic';
import { useMemo, useState } from 'react';
import GroupAdminDetail from './GroupAdminDetail';
import { calculateRoleForGroup, calculateRoleForSpace } from '@/pages/admin/user/type';
import { useOrgContext } from '@/contexts/orgContext';
import SpaceUserManagement from '@/pages/workgroup/tab/iam/SpaceUserManagement';
import { Button } from '@tigergraph/app-ui-lib/button';

type TableData =
  | {
      type: 'group';
      group: WorkGroupT;
    }
  | {
      type: 'space';
      space: WorkspaceT;
    };

export default function Access({ group }: { group: WorkGroupT }) {
  const [css] = useStyletron();
  const { data: allUser = [], isLoading: isAllUserLoading } = useQueryListUsers();

  const [showGroupAdminDetail, setShowGroupAdminDetail] = useState(false);
  const [showSpaceAdminDetail, setShowSpaceAdminDetail] = useState(false);
  const [space, setSpace] = useState<WorkspaceT | undefined>(undefined);

  const data = useMemo(() => {
    const data: TableData[] = [
      {
        type: 'group',
        group: group,
      },
      ...group.workspaces.map((s) => ({
        type: 'space' as const,
        space: s,
      })),
    ];
    return data;
  }, [group]);

  const { userInfo } = useOrgContext();
  const isGroupAdmin = calculateRoleForGroup(userInfo.roles, group.workgroup_id) === 'workgroup-admins';

  return (
    <>
      <TableBuilder data={data}>
        <TableBuilderColumn
          header="Workgroup"
          id="workgroup"
          overrides={{
            TableBodyCell: {
              style: {
                paddingTop: '12px',
                paddingBottom: '12px',
              },
            },
          }}
        >
          {(row: TableData) => {
            if (row.type === 'group') {
              return (
                <Button
                  kind="link"
                  overrides={{
                    BaseButton: {
                      style: { fontWeight: 'normal', fontSize: '14px' },
                    },
                  }}
                  startEnhancer={<GroupIcon />}
                  disabled={!isGroupAdmin}
                  onClick={() => setShowGroupAdminDetail(true)}
                >
                  {group.name}
                </Button>
              );
            }
            return (
              <Button
                kind="link"
                onClick={() => {
                  setShowSpaceAdminDetail(true);
                  setSpace(row.space);
                }}
                startEnhancer={<SpaceIcon />}
                overrides={{
                  BaseButton: {
                    style: { marginLeft: '20px', fontWeight: 'normal', fontSize: '14px' },
                  },
                }}
                disabled={
                  calculateRoleForSpace(userInfo.roles, row.space.workgroup_id, row.space.workspace_id) !==
                  'workspace-admins'
                }
              >
                {row.space.name}
              </Button>
            );
          }}
        </TableBuilderColumn>
        <TableBuilderColumn header="Creator" id="creator">
          {(row: TableData) => {
            if (row.type === 'group') {
              return row.group.creator;
            }
            return row.space.creator;
          }}
        </TableBuilderColumn>
        <TableBuilderColumn
          header="User"
          id="user"
          overrides={{
            TableBodyCell: {
              style: {
                paddingTop: '8px',
                paddingBottom: '8px',
                verticalAlign: 'center',
              },
            },
          }}
        >
          {(row: TableData) => {
            if (isAllUserLoading) {
              return <Spinner $size={'16px'} $borderWidth={'2px'} />;
            }
            let users: UserInfo[] = [];
            if (row.type === 'group') {
              users = getGroupAdmins(allUser, group.workgroup_id).concat(getOrgAdmins(allUser));
            } else {
              users = getSpaceAdmins(allUser, group.workgroup_id, row.space.workspace_id).concat(
                getSpaceUsers(allUser, group.workgroup_id, row.space.workspace_id)
              );
            }
            return (
              <div className={css({ display: 'flex' })}>
                {users.slice(0, 15).map((user, index) => (
                  <div
                    key={user.id}
                    className={css({
                      padding: '2px',
                      borderRadius: '50%',
                      position: 'relative',
                      left: `-${index * 8}px`,

                      display: 'flex',
                      overflow: 'hidden',
                    })}
                  >
                    <Avatar size="compact">{formatName(user)}</Avatar>
                  </div>
                ))}
              </div>
            );
          }}
        </TableBuilderColumn>
      </TableBuilder>
      {showGroupAdminDetail ? (
        <GroupAdminDetail
          group={group}
          isOpen={showGroupAdminDetail}
          onClose={() => {
            setShowGroupAdminDetail(false);
            setSpace(undefined);
          }}
        />
      ) : null}
      {showSpaceAdminDetail && space ? (
        <SpaceUserManagement
          space={space}
          group={group}
          isOpen={showSpaceAdminDetail}
          onClose={() => setShowSpaceAdminDetail(false)}
        />
      ) : null}
    </>
  );
}
