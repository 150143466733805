import { FormEvent, useEffect, useMemo, useRef } from 'react';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Input } from '@tigergraph/app-ui-lib/input';
import { ValidateResult } from '@tigergraph/tools-models';
import { StatefulPopover } from '@tigergraph/app-ui-lib/popover';
import { ChromePicker } from 'react-color';
import { IconSelector } from '@/components/schemaDesigner/attributeEditor/IconSelector';
import ErrorMessage from '@/components/ErrorMessage';
import { Select } from '@tigergraph/app-ui-lib/select';
import { TRIGGER_TYPE } from 'baseui/popover';
import { expand } from 'inline-style-expand-shorthand';

export interface TypeNameEditorProps {
  typeName: string;
  color: string;
  isVertex: boolean;
  validateResult: ValidateResult | undefined;
  onSaveVertex?: (name: string, color: string, icon: string) => void;
  onSaveEdge?: (name: string, color: string, directed: boolean) => void;
  onDeleteVertexIcon?: (icon: string) => void;
  icon?: string;
  directed?: boolean;
  readOnly?: boolean;
  styleReadOnly?: boolean;
  warningMessage?: string[];
}

export function TypeNameEditor(props: TypeNameEditorProps) {
  const [css, theme] = useStyletron();
  const {
    typeName,
    color,
    isVertex,
    icon,
    directed,
    validateResult,
    onSaveVertex,
    onSaveEdge,
    onDeleteVertexIcon,
    readOnly,
    styleReadOnly,
    warningMessage,
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
  }, [typeName]);

  const isInvalidVertexName = useMemo(() => {
    return (
      validateResult &&
      !validateResult.success &&
      (validateResult.message?.includes('edge name') || validateResult.message?.includes('vertex name'))
    );
  }, [validateResult]);

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        rowGap: '8px',
      })}
    >
      {warningMessage && warningMessage.length > 0 && <ErrorMessage message={warningMessage} />}
      <div
        className={css({
          display: 'flex',
          height: '32px',
          gap: '4px',
        })}
      >
        <Input
          ref={inputRef}
          onChange={(e: FormEvent<HTMLInputElement>) => {
            const name = e.currentTarget.value;
            if (isVertex) {
              onSaveVertex?.(name, color, icon || '');
            } else {
              onSaveEdge?.(name, color, directed || false);
            }
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
          value={typeName}
          disabled={readOnly}
          overrides={{
            Root: {
              style: {
                width: '70%',
                flexGrow: 1,
              },
            },
          }}
          placeholder={isVertex ? 'Enter vertex name' : 'Enter edge name'}
          error={isInvalidVertexName}
        />
        {isVertex ? (
          <IconSelector
            value={icon || ''}
            readOnly={styleReadOnly}
            onSelect={(iconName: string) => {
              onSaveVertex?.(typeName, color, iconName);
            }}
            onDelete={onDeleteVertexIcon}
          />
        ) : (
          <Select
            value={[{ label: directed ? 'Directed' : 'Undirected', id: directed ? 'true' : 'false' }]}
            onChange={({ value }) => {
              onSaveEdge?.(typeName, color, value[0].id === 'true');
            }}
            options={[
              { id: 'true', label: 'Directed' },
              { id: 'false', label: 'Undirected' },
            ]}
            searchable={false}
            clearable={false}
            disabled={readOnly}
            overrides={{
              Root: {
                style: {
                  width: '126px',
                },
              },
            }}
          />
        )}
        <StatefulPopover
          overrides={{
            Body: {
              style: {
                backgroundColor: theme.colors['background.primary'],
                ...expand({
                  borderRadius: '2px',
                  border: `1px solid ${theme.colors.divider}`,
                }),
              },
            },
            Inner: {
              style: {
                backgroundColor: theme.colors['background.primary'],
              },
            },
            Arrow: {
              style: {
                backgroundColor: theme.colors['background.primary'],
              },
            },
          }}
          content={() => {
            return (
              <div
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
              >
                {/* @ts-ignore */}
                <ChromePicker
                  disableAlpha
                  color={color}
                  className={css({
                    boxShadow: 'none !important',
                    backgroundColor: `${theme.colors['background.primary']} !important`,
                  })}
                  onChangeComplete={(color: { hex: string }) => {
                    if (isVertex) {
                      onSaveVertex?.(typeName, color.hex, icon || '');
                    } else {
                      onSaveEdge?.(typeName, color.hex, directed || false);
                    }
                  }}
                />
              </div>
            );
          }}
        >
          <div>
            <StatefulPopover
              triggerType={TRIGGER_TYPE.hover}
              content={'Select color'}
              overrides={{
                Body: {
                  style: {
                    ...expand({
                      margin: '10px',
                    }),
                  },
                },
              }}
            >
              <button
                disabled={styleReadOnly}
                className={css({
                  width: '32px',
                  height: '32px',
                  backgroundColor: `${color}`,
                  borderRadius: '4px',
                })}
              />
            </StatefulPopover>
          </div>
        </StatefulPopover>
      </div>
      {isInvalidVertexName && <ErrorMessage message={validateResult?.message ?? ''} />}
    </div>
  );
}
