import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import NoGraphIcon from './icon/nograph.svg';
import NoGraphDarkIcon from './icon/nograph-dark.svg';
import { Link } from 'react-router-dom';

export default function NoGraph({ space_id }: { space_id: string }) {
  const [css, theme] = useStyletron();

  return (
    <div
      className={css({
        flexBasis: 0,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '24px',
        maxWidth: '498px',
        margin: '0 auto',
      })}
    >
      <img src={theme.name === 'dark' ? NoGraphDarkIcon : NoGraphIcon} alt="no graph available" />
      <div
        className={css({
          textAlign: 'center',
        })}
      >
        <div
          className={css({
            color: theme.colors['text.secondary'],
            marginBottom: '4px',
            lineHeight: '16px',
            fontSize: '14px',
            textWrap: 'balance',
          })}
        >
          You currently have no graphs. To get started, navigate to the <strong>Design Schema</strong> and create your
          first graph.
        </div>
      </div>
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        })}
      >
        <Link
          to={`/design-schema?workspace_id=${space_id}`}
          className={css({
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            borderRadius: '2px',
            padding: '2px 12px',
            backgroundColor: theme.colors['button.background.primary'],
            color: theme.colors['icon.inverse'],
            fontWeight: 500,
            lineHeight: '28px',
          })}
        >
          Go to Design Schema
        </Link>
      </div>
    </div>
  );
}
