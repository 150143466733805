import { tutorials } from '@/pages/editor/query/tutorials';
import { mapTypeToDefaultValue } from '@/utils/apiConnector';
import { ActiveFile, FilePermission, FileStore } from '@/utils/graphEditor/data';
import {
  GsqlQueryMeta,
  QuerySyntax,
  QueryMetaLogic,
  QueryParam,
  QueryParamVertexType,
  QueryParamListType,
  QueryMeta,
} from '@tigergraph/tools-models';
import { nanoid } from 'nanoid';

export const getPlaceholderFile = (): FileStore => {
  return {
    // hardcode id, so that we can customize styles through css selector li[data-nodeid='file-loading']
    id: 'file-loading',
    name: '',
    files: [],
    content: '',
    parent_id: '',
    is_folder: false,
    type: 'Placeholder',
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    permission: FilePermission.Edit,
  };
};

// temp file is created from a query
export const getTempFile = (name: string, content: string, query_name: string, graph_name: string): ActiveFile => {
  return {
    id: nanoid(),
    name,
    files: [],
    content,
    parent_id: '',
    is_folder: false,
    is_temp: true,
    type: 'UserFile',
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    permission: FilePermission.Edit,
    query_name,
    graphName: graph_name,
  };
};

export const getSharedFolder = (orgId: string): FileStore => {
  return {
    files: [],
    is_folder: true,
    id: `${orgId}-shared-folder`,
    name: 'Shared Folder',
    org_id: orgId,
    created_at: '0',
    updated_at: '0',
    permission: FilePermission.View,
    content: '',
    parent_id: '',
    type: 'Shared',
  };
};

export const getTutorialFolder = (): FileStore => {
  return {
    is_folder: true,
    id: 'tutorial-folder',
    name: 'Tutorials',
    permission: FilePermission.View,
    content: '',
    parent_id: '',
    type: 'Tutorial',
    created_at: '0',
    updated_at: '0',
    files: tutorials.map((tutorial, idx) => getTutorialFile(`tutorial-${idx}`, tutorial.name, tutorial.content)),
  };
};
const getTutorialFile = (id: string, name: string, content: string): FileStore => {
  return {
    id,
    name,
    is_folder: false,
    permission: FilePermission.View,
    content,
    parent_id: 'tutorial-folder',
    type: 'Tutorial',
    created_at: '0',
    updated_at: '0',
    files: [],
  };
};

export const GSQL_Folder_Name = 'Custom Queries';
export const getGSQLFolder = (workspaceId: string): FileStore => {
  return {
    files: [],
    is_folder: true,
    id: `${workspaceId}-${GSQL_Folder_Name}`,
    name: GSQL_Folder_Name,
    permission: FilePermission.Edit,
    content: '',
    parent_id: '',
    created_at: '0',
    updated_at: '0',
    type: 'Query',
  };
};

export const getQueryFolder = (workspaceId: string, name: string, parent_id: string): FileStore => {
  return {
    name,
    files: [getPlaceholderFile()],
    id: `${workspaceId}-${name}`,
    is_folder: true,
    content: '',
    parent_id,
    permission: FilePermission.Edit,
    type: 'Query',
    created_at: '0',
    updated_at: '0',
  };
};

export const getQueryFile = (
  workspaceId: string,
  graphName: string,
  query: GsqlQueryMeta,
  parent_id: string
): FileStore => {
  return {
    name: query.name,
    files: [],
    id: `${workspaceId}-${graphName}-${query.name}`,
    is_folder: false,
    content: query.code,
    parent_id: parent_id,
    permission: FilePermission.Edit,
    type: 'Query',
    created_at: '0',
    updated_at: '0',
    queryInfo: QueryMetaLogic.loadFromGSQL(graphName, query),
    originQueryInfo: query,
    graphName,
  };
};

export const replaceQueryName = (code: string, name: string, newName: string): string => {
  // The regex pattern matches "QUERY (arbitrary whitespace) name (arbitrary whitespace) ("
  const regex = `(QUERY\\s+)(${name})(\\s*\\()`;
  return code.replace(new RegExp(regex, 'gi'), `$1${newName}$3`);
};

// TODO add this to tools-model
export const normalizeLintResults = (
  code: string,
  error: any,
  syntax: QuerySyntax
): {
  startLine: number;
  startColumn: number;
  endLine: number;
  endColumn: number;
  message: string;
} => {
  const lines = code.split('\n');
  const message = error.msg;
  let markStart: number = 0;
  let markStop: number = 0;

  // For cypher query, some errors are generated after the query has gone through the transformation stages
  // these types of errors will probably not have the correct line number, so we just mark the first line
  if (syntax === 'CYPHER' && error.errorcode !== 9999) {
    return {
      startLine: 0,
      startColumn: 0,
      endLine: 0,
      endColumn: 0,
      message: message,
    };
  }

  // Has startLine, startColumn, endLine, endColumn
  if ('startLine' in error && 'startColumn' in error && 'endLine' in error && 'endColumn' in error) {
    return {
      startLine: error.startLine - 1,
      startColumn: error.startColumn,
      endLine: error.endLine - 1,
      endColumn: error.endColumn,
      message: message,
    };
  }

  // Has start and stop index
  if ('startindex' in error && 'stopindex' in error) {
    markStart = error.startindex;
    markStop = error.stopindex + 1;
  }

  if (!markStart) {
    // If it's parsing error, mark until token ends
    if ('line' in error) {
      const line = error.line - 1; // parsing error line number is always actual line number + 1
      const pos = error.charpositioninline;
      let endPos = pos;

      if (line < lines.length) {
        for (; endPos < lines[line].length; endPos++) {
          if ([' ', '\t', '\n'].includes(lines[line].charAt(endPos))) {
            break;
          }
        }
      }

      return {
        startLine: line,
        startColumn: pos,
        endLine: line,
        endColumn: endPos,
        message: message,
      };
    } else {
      // Nothing there, mark the whole code
      return {
        startLine: 0,
        startColumn: 0,
        endLine: lines.length - 1,
        endColumn: lines[lines.length - 1].length,
        message: message,
      };
    }
  } else {
    // Contains both start position and end position
    const prefixStart = code.substring(0, markStart);
    const prefixStop = code.substring(0, markStop);
    return {
      startLine: prefixStart.split('\n').length - 1,
      startColumn: markStart - prefixStart.lastIndexOf('\n') - 1,
      endLine: prefixStop.split('\n').length - 1,
      endColumn: markStop - prefixStop.lastIndexOf('\n') - 1,
      message: message,
    };
  }
};

export const insertOrReplaceToQuery = (query: string): string => {
  const createQueryRegex = /^(CREATE\s+)(OR\s+REPLACE\s+)?(DISTRIBUTED\s+)?(QUERY\s+\w+)/i;
  const match = query.match(createQueryRegex);

  if (match && !match[2]) {
    return query.replace(/^(CREATE\s+)/i, '$1OR REPLACE ');
  }

  return query;
};

export const appendUseGraph = (query: string, graphName: string): string => {
  const useGraph = `USE GRAPH ${graphName}\n`;
  return useGraph + query;
};

export interface HTTPConfig {
  url: string;
  headers: Record<string, string>;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  data?: object;
}
export function genCurlText(requestConfig: HTTPConfig) {
  const { url, headers, data, method = 'GET' } = requestConfig;
  let curlCommand = `curl -X ${method} '${url}' \\\n`;

  if (headers) {
    const length = Object.keys(headers).length;
    for (const [index, [key, value]] of Object.entries(headers).entries()) {
      if (index === length - 1) {
        curlCommand += ` -H '${key}: ${value}'`;
      } else {
        curlCommand += ` -H '${key}: ${value}' \\\n`;
      }
    }
  }

  if (data) {
    const dataString = JSON.stringify(data);
    curlCommand += `\\\n --data-raw '${dataString}'`;
  }

  return curlCommand;
}

export function genJSText(requestConfig: HTTPConfig) {
  const { url, headers, data, method = 'GET' } = requestConfig;
  let jsCommand = `fetch('${url}', {\n  method: '${method}'`;

  if (headers) {
    const headersString = JSON.stringify(headers, null, 4);
    jsCommand += `,\n  headers: ${headersString}`;
  }

  if (data) {
    const dataString = JSON.stringify(data, null, 2);
    jsCommand += `,\n  body: ${dataString}`;
  }

  jsCommand += '\n});';

  return jsCommand;
}

export function genPyText(requestConfig: HTTPConfig) {
  const { url, headers, data, method = 'GET' } = requestConfig;

  let pythonCode = 'import requests\n\n';
  pythonCode += `url = '${url}'\n`;
  pythonCode += 'headers = {\n';
  for (const key in headers) {
    pythonCode += `    '${key}': '${headers[key]}',\n`;
  }
  pythonCode += '}\n';

  if (data) {
    pythonCode += `data = ${JSON.stringify(data)}\n`;
  }

  switch (method) {
    case 'GET':
      pythonCode += 'response = requests.get(url, headers=headers)\n';
      break;
    case 'POST':
      pythonCode += 'response = requests.post(url, headers=headers, json=data)\n';
      break;
    case 'PUT':
      pythonCode += 'response = requests.put(url, headers=headers, json=data)\n';
      break;
    case 'DELETE':
      pythonCode += 'response = requests.delete(url, headers=headers)\n';
      break;
    case 'PATCH':
      pythonCode += 'response = requests.patch(url, headers=headers, json=data)\n';
      break;
  }

  pythonCode += 'print(response.text)\n';
  return pythonCode;
}

export function getQueryDefalutPayloadByParams(params: QueryParam[]) {
  const json: { [key: string]: any } = {};

  params.forEach((param) => {
    if (param.paramType.type === 'VERTEX') {
      json[param.paramName] = {
        id: '',
        type: (<QueryParamVertexType>param.paramType).vertexType || '',
      };
      return;
    }

    if (param.paramType.type === 'LIST') {
      json[param.paramName] = [];
      return;
    }

    switch (param.paramType.type) {
      case 'INT':
      case 'UINT':
      case 'INT32':
      case 'UINT32':
      case 'INT64':
      case 'UINT64':
      case 'FLOAT':
      case 'DOUBLE':
        json[param.paramName] =
          typeof param.paramDefaultValue !== 'undefined'
            ? +param.paramDefaultValue
            : mapTypeToDefaultValue(param.paramType.type);
        break;
      default:
        json[param.paramName] = param.paramDefaultValue ?? mapTypeToDefaultValue(param.paramType.type);
        break;
    }
  });

  return JSON.stringify(json, null, 2);
}

export function getURLSearchParamsForQuery(graphName: string, queryParams: QueryParam[], payload: Record<string, any>) {
  let urlParams = new URLSearchParams();
  urlParams.set('graph', graphName);

  queryParams.forEach((queryParam) => {
    const paramName = queryParam.paramName;
    if (paramName in payload) {
      switch (queryParam.paramType.type) {
        case 'VERTEX': {
          /**
           * When run query in interpreted mode, "[0]" should be suffix of the parameter name
           * and type. Vertex type should be added.
           * For example:
           *   localhost:14240/gsqlserver/interpreted_query?a[0]=A&a[0].type=Person
           */
          urlParams.set(`${paramName}[0]`, payload[paramName].id);
          urlParams.set(paramName + '[0].type', payload[paramName].type);
          break;
        }
        case 'LIST': {
          const elementType = (<QueryParamListType>queryParam.paramType).elementType;
          const paramValue = payload[paramName] as any[];

          paramValue.forEach((param, index) => {
            if (elementType.type === 'VERTEX') {
              // If it is vertex without type, will push paramName[index] and paramName[index].type
              urlParams.set(paramName + `[${index}]`, param.id);
              urlParams.set(paramName + `[${index}].type`, param.type);
            } else {
              urlParams.append(paramName, param);
            }
          });
          break;
        }
        default:
          urlParams.set(paramName, payload[paramName]);
          break;
      }
    }
  });

  return urlParams.toString();
}

export function getInterpretQueryCode(query: QueryMeta): string {
  const pattern = new RegExp(/\bCREATE\b([\s\S]*?)\bQUERY\b[\s\S]*?\(/i);
  const isGSQL = !query.syntax || query.syntax.includes('GSQL');

  return query.originalCode.replace(pattern, isGSQL ? 'INTERPRET QUERY (' : 'INTERPRET OPENCYPHER QUERY (');
}
