import { ApiType, cmOptions } from '@/pages/workgroup/tab/restPP/type.ts';
import ReactCodeMirror from '@uiw/react-codemirror';
import { StreamLanguage } from '@codemirror/language';
import { json } from '@codemirror/legacy-modes/mode/javascript';
import { Tab, Tabs } from 'baseui/tabs-motion';
import { FileTabsStyleObject, FileTabStyleObject } from '@/pages/editor/file/styleObject';
import { xcodeDark, xcodeLight } from '@uiw/codemirror-theme-xcode';
import { Heading } from '@/pages/workgroup/tab/restPP/Util.tsx';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { showToast } from '@/components/styledToasterContainer';
import { useTheme } from '@/contexts/themeContext';
import { Button } from '@tigergraph/app-ui-lib/button';
import { useEffect, useState } from 'react';
import useCopyClipboard from 'react-use-clipboard';
import QueryParamPopover from '@/pages/workgroup/tab/restPP/QueryParamPopover';
import { MdContentCopy } from 'react-icons/md';
import { QueryParam } from '@tigergraph/tools-models';
import { getQueryDefalutPayloadByParams } from '@/pages/editor/query/util';

const editorBgLight = '#fff';
const editorBgDark = '#282c34';

export interface ApiSettingProps {
  apiType: ApiType;
  curlCommand?: string;
  javascriptCommand?: string;
  pythonCommand?: string;
  onQueryCodeChange?: (value: string) => void;
  queryParams: QueryParam[];
}

export function ApiSetting({
  apiType,
  curlCommand,
  pythonCommand,
  javascriptCommand,
  onQueryCodeChange,
  queryParams,
}: ApiSettingProps) {
  const [css, theme] = useStyletron();
  const [activeKey, setActiveKey] = useState('cURL');
  const [curlCopied, setCurlCopied] = useCopyClipboard(curlCommand || '', {
    successDuration: 1000,
  });
  const [javascriptCopied, setJavascriptCopied] = useCopyClipboard(javascriptCommand || '', {
    successDuration: 1000,
  });
  const [pythonCopied, setPythonCopied] = useCopyClipboard(pythonCommand || '', {
    successDuration: 1000,
  });
  const [queryCode, setQueryCode] = useState<string>('');
  const [hasQueryCodeChanged, setHasQueryCodeChanged] = useState<boolean>(false);

  useEffect(() => {
    if (curlCopied || javascriptCopied || pythonCopied) {
      showToast({
        kind: 'positive',
        message: 'Code snippet copied successfully.',
      });
    }
  }, [curlCopied, javascriptCopied, pythonCopied]);

  useEffect(() => {
    if (!hasQueryCodeChanged) {
      setQueryCode(getQueryDefalutPayloadByParams(queryParams));
      onQueryCodeChange && onQueryCodeChange(getQueryDefalutPayloadByParams(queryParams));
    }
  }, [hasQueryCodeChanged, onQueryCodeChange, queryParams]);

  const handleCopy = () => {
    switch (activeKey) {
      case 'cURL':
        setCurlCopied();
        break;
      case 'JavaScript':
        setJavascriptCopied();
        break;
      case 'Python':
        setPythonCopied();
        break;
    }
  };

  const handleQueryCodeChange = (value: string) => {
    setHasQueryCodeChanged(true);
    if (queryCode === value) {
      return;
    }
    setQueryCode(value);
    onQueryCodeChange && onQueryCodeChange(value);
  };

  const { themeType } = useTheme();

  return (
    <>
      <div>
        <div
          className={css({
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginBottom: '8px',
          })}
        >
          <Heading
            $style={{
              marginBottom: '0px',
              marginRight: '8px',
            }}
          >
            {apiType !== ApiType.GET ? 'JSON Payload' : 'Query String'}
          </Heading>
          {queryParams.length > 0 && <QueryParamPopover queryParams={queryParams} />}
        </div>
        <ReactCodeMirror
          value={queryCode}
          onChange={(value) => handleQueryCodeChange(value)}
          basicSetup={cmOptions}
          width={'100%'}
          height={'120px'}
          extensions={[StreamLanguage.define(json)]}
          theme={themeType}
        />
      </div>
      <div
        className={css({
          position: 'relative',
        })}
      >
        <Button
          kind="text"
          shape="square"
          onClick={handleCopy}
          overrides={{
            BaseButton: {
              style: {
                position: 'absolute',
                right: '0',
                top: '32px',
              },
            },
          }}
        >
          <MdContentCopy size={20} />
        </Button>
        <Heading
          $style={{
            marginBottom: '-10px',
          }}
        >
          Code Snippet
        </Heading>
        <Tabs
          renderAll
          activeKey={activeKey}
          onChange={({ activeKey }) => {
            setActiveKey(activeKey as string);
          }}
          overrides={{
            ...FileTabsStyleObject(theme, themeType === 'light' ? editorBgLight : editorBgDark),
            TabList: {
              style: {
                height: '57px',
                paddingTop: '20px',
                paddingBottom: '0px',
                marginBottom: '0px',
                width: 'calc(100% - 180px)',
                overflowX: 'scroll',
              },
            },
          }}
        >
          <Tab
            key={'cURL'}
            overrides={FileTabStyleObject(theme, themeType === 'light' ? editorBgLight : editorBgDark)}
            title={'cURL'}
          >
            <div
              className={css({
                width: '100%',
                height: '100%',
              })}
            >
              <ReactCodeMirror
                editable={false}
                basicSetup={cmOptions}
                theme={themeType === 'light' ? xcodeLight : xcodeDark}
                width={'100%'}
                height={'200px'}
                value={curlCommand}
              />
            </div>
          </Tab>
          <Tab
            key={'JavaScript'}
            overrides={FileTabStyleObject(theme, themeType === 'light' ? editorBgLight : editorBgDark)}
            title={'JavaScript'}
          >
            <div
              className={css({
                width: '100%',
                height: '100%',
              })}
            >
              <ReactCodeMirror
                value={javascriptCommand}
                editable={false}
                basicSetup={cmOptions}
                theme={themeType === 'light' ? xcodeLight : xcodeDark}
                width={'100%'}
                height={'200px'}
              />
            </div>
          </Tab>
          <Tab
            key={'Python'}
            overrides={FileTabStyleObject(theme, themeType === 'light' ? editorBgLight : editorBgDark)}
            title={'Python'}
          >
            <div
              className={css({
                width: '100%',
                height: '100%',
              })}
            >
              <ReactCodeMirror
                value={pythonCommand}
                editable={false}
                basicSetup={cmOptions}
                theme={themeType === 'light' ? xcodeLight : xcodeDark}
                width={'100%'}
                height={'200px'}
              />
            </div>
          </Tab>
        </Tabs>
      </div>
    </>
  );
}
