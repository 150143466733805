import { axiosCluster } from '@/lib/network';
import { Return } from '@/lib/type';

export async function getGraphStatistics(nginx_host: string, graph_name: string) {
  const vertexStatistics = {
    function: 'stat_vertex_number',
    type: '*',
  };
  const edgeStatistics = {
    function: 'stat_edge_number',
    type: '*',
  };

  const [vResponse, eResponse] = await Promise.all([
    axiosCluster.post<Return<{ count: number; v_type: string }[]>>(
      `https://${nginx_host}/api/restpp/builtins/${graph_name}`,
      vertexStatistics
    ),
    axiosCluster.post<Return<{ count: number; e_type: string }[]>>(
      `https://${nginx_host}/api/restpp/builtins/${graph_name}`,
      edgeStatistics
    ),
  ]);

  return {
    vertexCounts: vResponse.data,
    edgeCounts: eResponse.data,
  };
}
