import { WorkGroupT } from '@/pages/workgroup/type';
import { Button } from '@tigergraph/app-ui-lib/button';
import { Checkbox } from '@tigergraph/app-ui-lib/checkbox';
import { styled, useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { HeadingMenuSelected } from '@tigergraph/app-ui-lib/typography';
import { STYLE_TYPE } from 'baseui/checkbox';
import { Plus } from 'baseui/icon';
import { TableBuilder } from '@tigergraph/app-ui-lib/table';
import { TableBuilderColumn } from 'baseui/table-semantic';
import { actionColumnOverrides } from '@/components/table';
import { Trash2Icon, Edit2Icon } from 'lucide-react';
import { ConfirmStatefulPopover } from '@/components/confirmPopover';
import { Drawer, DrawerAction, DrawerBody, DrawerHeader } from '@/components/Drawer';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormControl } from '@/components/FormControl';
import { Input } from '@tigergraph/app-ui-lib/input';
import MonitorIcon from './icon/monitor.svg?react';
import CancelIcon from './icon/cancel.svg?react';
import CheckCircleIcon from './icon/check_circle.svg?react';
import EmptyIcon from './icon/empty.svg?react';
import SecuritySettingIcon from './icon/security-setting.svg?react';
import {
  useMutationAddAllowIP,
  useMutationConfigAllowIP,
  useMutationDeleteAllowIP,
  useMutationUpdateAllowIP,
  useQueryListAllowIP,
  useQueryGroupAccess,
} from '@/pages/workgroup/tab/networkAccess/hook';
import toast from 'react-hot-toast';
import { getErrorMessage } from '@/utils/utils';
import { showToast } from '@tigergraph/app-ui-lib/styledToasterContainer';
import { LoadingIndicator } from '@/components/loading-indicator';
import { AllowIP, FormData } from './type';
import { Spinner } from '@tigergraph/app-ui-lib/spinner';

export default function NetworkAccess({ group }: { group: WorkGroupT }) {
  const [css, theme] = useStyletron();
  const [showDrawer, setShowDrawer] = useState(false);
  const [allowIP, setAllowIP] = useState<AllowIP | undefined>(undefined);

  const [enable, setEnable] = useState(group.enable_allow_list);

  const { data, isLoading } = useQueryListAllowIP(group.workgroup_id, {
    onError(err) {
      showToast({
        kind: 'negative',
        message: `${getErrorMessage(err)}`,
      });
    },
  });
  const queryMyIP = useQueryGroupAccess(group.workgroup_id);

  const mutationDelete = useMutationDeleteAllowIP();

  const onDelete = (id: string) => {
    const promise = mutationDelete.mutateAsync({
      group_id: group.workgroup_id,
      id,
    });

    toast.promise(
      promise,
      {
        loading: 'Deleting IP',
        success: (data) => data.Message!,
        error: (err) => `${getErrorMessage(err)}`,
      },
      {}
    );
  };

  const mutationConfigAllowIP = useMutationConfigAllowIP();
  const toggle = () => {
    setEnable((enable) => !enable);

    const promise = mutationConfigAllowIP.mutateAsync({
      group_id: group.workgroup_id,
      enable: !enable,
    });

    toast.promise(
      promise,
      {
        loading: 'Updating configuration',
        success: (data) => data.Message!,
        error: (err) => `${getErrorMessage(err)}`,
      },
      {}
    );
  };

  return (
    <div>
      <HeadingMenuSelected
        className={css({
          display: 'flex',
          alignItems: 'center',
        })}
      >
        IP Allowlist
        <Checkbox
          disabled={mutationConfigAllowIP.isLoading}
          checkmarkType={STYLE_TYPE.toggle_round}
          checked={enable}
          onChange={toggle}
        />
      </HeadingMenuSelected>
      <div
        className={css({
          color: theme.colors['text.secondary'],
        })}
      >
        The IP allowlist controls which networks can be used to access your data. By limiting allowed networks, you can
        protect your resources from data exfiltration and insider threats.
      </div>
      {enable ? (
        <>
          <div
            className={css({
              paddingTop: '8px',
              paddingBottom: '16px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            })}
          >
            <div
              className={css({
                display: 'flex',
                padding: '8px 16px',
                backgroundColor: theme.colors['background.secondary'],
                gap: '16px',
                borderRadius: '4px',
                alignItems: 'center',
              })}
            >
              <StyleItem>
                <MonitorIcon color={theme.colors['icon.primary']} />
                <span
                  className={css({
                    color: theme.colors['text.secondary'],
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  })}
                >
                  <span>Your IP:</span>
                  <span
                    className={css({
                      color: theme.colors['text.primary'],
                    })}
                  >
                    {queryMyIP.isLoading ? (
                      <Spinner $size={'12px'} $borderWidth={'2px'} />
                    ) : (
                      `${queryMyIP.data?.Result?.current_ip}`
                    )}
                  </span>
                </span>
              </StyleItem>
              {queryMyIP.status === 'success' ? (
                <>
                  <div
                    className={css({
                      width: '1px',
                      height: '13px',
                      backgroundColor: theme.colors['border.tertiary'],
                    })}
                  />
                  <StyleItem>
                    {queryMyIP.data.Result?.is_allowed ? (
                      <>
                        <CheckCircleIcon color={theme.colors['icon.success']} />
                        <span>Your IP is included in the IP allowlist.</span>
                      </>
                    ) : (
                      <>
                        <CancelIcon color={theme.colors['icon.danger']} />
                        <span>Your IP is excluded in the IP allowlist.</span>
                      </>
                    )}
                  </StyleItem>
                </>
              ) : null}
            </div>
            <Button size="large" startEnhancer={<Plus size={20} />} onClick={() => setShowDrawer(true)}>
              Add IP
            </Button>
          </div>
          <TableBuilder
            isLoading={isLoading}
            loadingMessage={<LoadingIndicator />}
            data={data?.Result || []}
            emptyMessage={<EmptyState onClick={() => setShowDrawer(true)} />}
          >
            <TableBuilderColumn header="IP Range" id="ip">
              {(row) => row.cidr}
            </TableBuilderColumn>
            <TableBuilderColumn header="Note" id="note">
              {(row) => row.note}
            </TableBuilderColumn>
            <TableBuilderColumn overrides={actionColumnOverrides} header="Actions">
              {(row) => (
                <div
                  className={css({
                    display: 'flex',
                    gap: '4px',
                    alignItems: 'center',
                  })}
                >
                  <Button
                    kind="text"
                    shape="square"
                    onClick={() => {
                      setShowDrawer(true);
                      setAllowIP(row);
                    }}
                  >
                    <Edit2Icon size={16} />
                  </Button>
                  <ConfirmStatefulPopover
                    confirmLabel={`Are you sure you want to delete this IP range?`}
                    onConfirm={() => onDelete(row.id)}
                  >
                    <Button kind="text" shape="square">
                      <Trash2Icon size={16} />
                    </Button>
                  </ConfirmStatefulPopover>
                </div>
              )}
            </TableBuilderColumn>
          </TableBuilder>
        </>
      ) : null}
      <IPDrawer
        isOpen={showDrawer}
        onClose={() => {
          setShowDrawer(false);
          setAllowIP(undefined);
        }}
        editIP={allowIP}
        myIP={queryMyIP.data?.Result?.current_ip}
        key={allowIP?.id || 'new'}
        group={group}
      />
    </div>
  );
}

const StyleItem = styled('span', ({ $theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}));

function EmptyState({ onClick }: { onClick: () => void }) {
  const [css, theme] = useStyletron();
  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
        padding: '64px',
        alignItems: 'center',
      })}
    >
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          alignItems: 'center',
          justifyContent: 'center',
          color: theme.colors['text.tertiary'],
        })}
      >
        <EmptyIcon />
        <div>{`Click the '+ Add IP' button to create one.`}</div>
      </div>
      <Button size="large" startEnhancer={<Plus size={20} />} onClick={onClick}>
        Add IP
      </Button>
    </div>
  );
}

function IPDrawer({
  isOpen,
  onClose,
  group,
  editIP,
  myIP,
}: {
  isOpen: boolean;
  onClose: () => void;
  group: WorkGroupT;
  editIP?: AllowIP;
  myIP?: string;
}) {
  const mutationAdd = useMutationAddAllowIP();
  const mutationEdit = useMutationUpdateAllowIP();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      cidr: editIP?.cidr || '',
      note: editIP?.note || '',
    },
  });

  const [css] = useStyletron();

  const onCreate = (data: FormData) => {
    mutationAdd.mutate(
      {
        group_id: group.workgroup_id,
        data,
      },
      {
        onSuccess() {
          onClose();
          showToast({
            kind: 'positive',
            message: `Successfully added allowed IP.`,
          });
          setValue('cidr', '');
          setValue('note', '');
        },
        onError(error) {
          showToast({
            kind: 'negative',
            message: `${getErrorMessage(error)}`,
          });
        },
      }
    );
  };

  const onUpdate = (data: FormData) => {
    mutationEdit.mutate(
      {
        group_id: group.workgroup_id,
        id: editIP?.id!,
        data,
      },
      {
        onSuccess() {
          onClose();
          showToast({
            kind: 'positive',
            message: `Successfully updated allowed IP.`,
          });
        },
        onError(error) {
          showToast({
            kind: 'negative',
            message: `${getErrorMessage(error)}`,
          });
        },
      }
    );
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <DrawerHeader>
        <SecuritySettingIcon />
        IP Allowlist
      </DrawerHeader>
      <DrawerBody>
        <form
          className={css({
            position: 'relative',
          })}
        >
          {myIP ? (
            <Button
              kind="secondary"
              size="compact"
              type="button"
              startEnhancer={<Plus size={18} />}
              onClick={() => {
                setValue('cidr', `${myIP}/32`);
              }}
              overrides={{
                BaseButton: {
                  style: {
                    position: 'absolute',
                    right: 0,
                    top: '1px',
                  },
                },
              }}
            >
              Add My current IP address
            </Button>
          ) : null}

          <FormControl label="IP Range" error={errors?.cidr?.message}>
            <Controller
              rules={{
                required: 'required',
              }}
              control={control}
              name="cidr"
              render={({ field }) => (
                <Input
                  placeholder="Enter IP range e.g: 192.168.2.0/24"
                  {...field}
                  error={!!errors?.cidr}
                  autoComplete="off"
                />
              )}
            />
          </FormControl>
          <FormControl label="Note" error={errors?.note?.message}>
            <Controller
              rules={{
                required: 'required',
              }}
              control={control}
              name="note"
              render={({ field }) => (
                <Input
                  placeholder="Please enter a note for your rule"
                  {...field}
                  error={!!errors?.note}
                  autoComplete="off"
                />
              )}
            />
          </FormControl>
        </form>
      </DrawerBody>
      <DrawerAction>
        <Button type="button" onClick={onClose} kind="secondary" size="large">
          Cancel
        </Button>
        <Button
          type="button"
          isLoading={mutationAdd.isLoading || mutationEdit.isLoading}
          disabled={mutationAdd.isLoading || mutationEdit.isLoading}
          size="large"
          onClick={handleSubmit((data) => {
            if (!editIP) {
              onCreate(data);
            } else {
              onUpdate(data);
            }
          })}
        >
          Save
        </Button>
      </DrawerAction>
    </Drawer>
  );
}
