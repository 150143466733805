import CloudProviders from '@/pages/admin/settings/cloud_provider/CloudProvider';
import APIKey from '@/pages/admin/settings/apikey';
import { Tabs, Tab, pageTabOverrides } from '@/components/Tab';
import OrgSettings from '@/pages/admin/settings/org_settings';
import { useSearchParams } from 'react-router-dom';
import { useFeatureFlagEnabled } from '@/hooks/useFeatureFlag';

export default function Settings() {
  const [searchParams, setSearchParams] = useSearchParams();
  const enableAPIKey = useFeatureFlagEnabled('api-key');
  const enableCloudProvider = useFeatureFlagEnabled('private-cloud-provider');

  return (
    <Tabs
      activeKey={searchParams.get('tab') || 'org_settings'}
      onChange={({ activeKey }) => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set('tab', `${activeKey}`);
        setSearchParams(newSearchParams, { replace: true });
      }}
      activateOnFocus
      overrides={{
        TabList: {
          style: {
            paddingTop: '16px',
          },
        },
      }}
    >
      <Tab title="Organization Setting" key="org_settings">
        <OrgSettings />
      </Tab>
      {enableCloudProvider && (
        <Tab title="Cloud Providers" key="cloud_providers" overrides={pageTabOverrides}>
          <CloudProviders />
        </Tab>
      )}
      {enableAPIKey && (
        <Tab title="API Keys" key="api_key">
          <APIKey />
        </Tab>
      )}
    </Tabs>
  );
}
