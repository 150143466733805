import { RoleType, UserInfo } from '@/lib/models';
import { axiosController, axiosOrgService } from '@/lib/network';
import { Result } from '@/lib/type';
import { DBRoles, DBUser, UserData, UserRoles } from '@/pages/admin/user/type';
import { getRoles, getUserList, grantRoles, revokeRoles } from '@tigergraph/tools-models';
import { CommonRequestConfig } from '@tigergraph/tools-models/api/common';

export async function fetchUsers(apiUser?: boolean): Promise<UserInfo[]> {
  const response = await axiosOrgService.get(`/users${apiUser ? '?path=api_key_external_org' : ''}`);
  return response.data;
}

export async function getUserDetail(userId: string): Promise<UserInfo> {
  const response = await axiosOrgService.get(`/users/${userId}`);
  return response.data;
}

export async function inviteUser(users: UserData[]): Promise<void> {
  const response = await axiosOrgService.post('/users/batch_invite?is_new_cloud=true', { users });
  return response.data;
}

export async function deleteUser(userId: string): Promise<void> {
  const response = await axiosOrgService.delete(`/users/${userId}`);
  return response.data;
}

export async function resendInvitation(orgId: string, email: string): Promise<void> {
  const response = await axiosOrgService.post(`/orgs/${orgId}/resend_invitation?is_new_cloud=true`, { email });
  return response.data;
}

export async function modifyOrgUserRole(userId: string, role: RoleType): Promise<void> {
  const response = await axiosOrgService.put(`/users/${userId}/roles`, {
    roles: [role],
  });
  return response.data;
}

export async function removeOrgUserRole(userId: string, role: RoleType): Promise<void> {
  const response = await axiosOrgService.delete(`/users/${userId}/roles`, {
    data: {
      roles: [role],
    },
  });
  return response.data;
}

export async function assignGroupAdmin(groupID: string, userEmails: string[]): Promise<void> {
  const response = await axiosController.post(`/v2/workgroups/${groupID}/admins/add`, {
    userEmails,
  });
  return response.data;
}

export async function unAssignGroupAdmin(groupID: string, userEmails: string[]): Promise<void> {
  const response = await axiosController.post(`/v2/workgroups/${groupID}/admins/remove`, {
    userEmails,
  });
  return response.data;
}

export async function assignWorkspaceAdmin(groupID: string, spaceID: string, userEmails: string[]): Promise<void> {
  const response = await axiosController.post(`/v2/workgroups/${groupID}/workspaces/${spaceID}/admins/add`, {
    userEmails,
  });
  return response.data;
}

export async function assignWorkspaceUser(groupID: string, spaceID: string, userEmails: string[]): Promise<void> {
  const response = await axiosController.post(`/v2/workgroups/${groupID}/workspaces/${spaceID}/users/add`, {
    userEmails,
  });
  return response.data;
}

export async function unAssignWorkspaceRole(groupID: string, spaceID: string, userEmails: string[]): Promise<void> {
  const response = await axiosController.post(`/v2/workgroups/${groupID}/workspaces/${spaceID}/roles/remove`, {
    userEmails,
  });
  return response.data;
}

export type SpaceWithGroup = string;

export async function assignUserRole(
  userEmail: string,
  workgroupsToAssignAdmin: string[],
  workspacesToAssignAdmin: SpaceWithGroup[],
  workspacesToAssignUser: SpaceWithGroup[],
  billingRoleToAssign?: RoleType
): Promise<void> {
  const response = await axiosController.post('/v2/roles/assign', {
    userEmail,
    workgroupsToAssignAdmin,
    workspacesToAssignAdmin,
    workspacesToAssignUser,
    billingRoleToAssign: billingRoleToAssign || '',
  });
  return response.data;
}

export async function unAssignUserRole(
  userEmail: string,
  workgroupsToRevokeAdmin: string[],
  workspacesToRevokeRoles: SpaceWithGroup[],
  billingRoleToRevoke: boolean
): Promise<void> {
  const response = await axiosController.post('/v2/roles/revoke', {
    userEmail,
    workgroupsToRevokeAdmin,
    workspacesToRevokeRoles,
    billingRoleToRevoke: billingRoleToRevoke,
  });
  return response.data;
}

export async function fetchDBUsers(groupID: string, spaceID: string): Promise<DBUser[]> {
  const response = await axiosController.get<Result<DBUser[]>>(
    `/v2/workgroups/${groupID}/workspaces/${spaceID}/dbuser`
  );
  return response.data.Result || [];
}

export async function createDBUser(
  groupID: string,
  spaceID: string,
  username: string,
  password: string
): Promise<void> {
  const response = await axiosController.post(`/v2/workgroups/${groupID}/workspaces/${spaceID}/dbuser`, {
    username,
    password,
  });
  return response.data;
}

export async function updateDBUser(
  groupID: string,
  spaceID: string,
  username: string,
  password: string
): Promise<void> {
  const response = await axiosController.put(`/v2/workgroups/${groupID}/workspaces/${spaceID}/dbuser`, {
    username,
    password,
  });
  return response.data;
}

export async function deleteDBUser(groupID: string, spaceID: string, username: string): Promise<void> {
  const response = await axiosController.delete(`/v2/workgroups/${groupID}/workspaces/${spaceID}/dbuser`, {
    data: {
      username,
    },
  });
  return response.data;
}

export async function fetchDBRoles(config: CommonRequestConfig): Promise<DBRoles> {
  const response = await getRoles(undefined, config);
  return (
    (response.data.results as DBRoles) || {
      builtIn: {
        global: [],
        local: [],
      },
      userDefinedRoles: {
        1: [],
      },
    }
  );
}

export async function fetchDBUserRoles(user: string, config: CommonRequestConfig): Promise<UserRoles> {
  // will fix this type
  const response = await getUserList({ user }, config);
  const result = response.data.results;
  return (
    (Array.isArray(result) ? result[0] : result) || {
      privileges: {},
      roles: {},
    }
  );
}

export async function assignDBRoles(
  roles: string[],
  username: string,
  graphName: string,
  config: CommonRequestConfig
): Promise<void> {
  const response = await grantRoles(
    {
      // @ts-ignore
      graph: graphName === '1' ? undefined : graphName,
      usernames: [username],
      roles,
    },
    config
  );
  return response.data;
}

export async function revokeDBRoles(
  roles: string[],
  username: string,
  graphName: string,
  config: CommonRequestConfig
): Promise<void> {
  const response = await revokeRoles(
    // @ts-ignore
    { graph: graphName === '1' ? undefined : graphName, usernames: [username], roles: roles },
    config
  );
  return response.data;
}
